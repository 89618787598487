import React, { Component } from 'react';
import { Popup,Icon } from 'semantic-ui-react'

export default class CoinFinancials extends Component {
  render() {
    let theCoin = this?.props?.theCoin?.[0];
    let dbData = this.props.dbData;
    return (
        <div style={{paddingLeft:'20px'}}>
        <strong><span className='homefinancialslabel'>PRICE</span></strong> <span className='homefinancials'>
          ${(theCoin?.price < 1 ? theCoin?.price?.toLocaleString(undefined, {maximumFractionDigits:3}): theCoin?.price < 10 ? theCoin?.price?.toLocaleString(undefined, {maximumFractionDigits:2}): theCoin?.price < 1000 ? theCoin?.price?.toLocaleString(undefined, {maximumFractionDigits:1}): theCoin?.price > 1000 ? theCoin?.price?.toLocaleString(undefined, {maximumFractionDigits:0}):'N/A')}
          </span>
        <br></br>
        <hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
        <strong><span className='homefinancialslabel'>PRICE Δ</span><span className='homefinancialslabeldelta'> (1H/24H/7D)</span></strong> <span className='homefinancials'>
        {theCoin?.pricechange1h > 0 ? <span style={{color:'#20604F'}}>{'+'}{Number(theCoin?.pricechange1h).toFixed(1)}{'%'}</span>: ''}
        {theCoin?.pricechange1h < 0 ? <span style={{color:'#D0104C'}}>{''}{Number(theCoin?.pricechange1h).toFixed(1)}{'%'}</span>: ''}/
        {theCoin?.pricechange24h > 0 ? <span style={{color:'#20604F'}}>{'+'}{Number(theCoin?.pricechange24h).toFixed(1)}{'%'}</span>: ''}
        {theCoin?.pricechange24h < 0 ? <span style={{color:'#D0104C'}}>{''}{Number(theCoin?.pricechange24h).toFixed(1)}{'%'}</span>: ''}/
        {theCoin?.pricechange7d > 0 ? <span style={{color:'#20604F'}}>{'+'}{Number(theCoin?.pricechange7d).toFixed(1)}{'%'}</span>: ''}
        {theCoin?.pricechange7d < 0 ? <span style={{color:'#D0104C'}}>{''}{Number(theCoin?.pricechange7d).toFixed(1)}{'%'}</span>: ''}
        </span>
        <br></br>
        <hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
        <strong><span className='homefinancialslabel'>MARKET CAP</span></strong> <span className='homefinancials'>
        ${theCoin?.marketcap?.toLocaleString(undefined, {maximumFractionDigits: 0})}
            <Popup inverted content={theCoin?.totalsupply ? `Market cap based on ${(theCoin?.circulatingsupply/theCoin?.totalsupply*100)?.toFixed(1)}% of total supply being in circulation (${theCoin?.circulatingsupply?.toLocaleString(undefined, {maximumFractionDigits: 0})}/${theCoin?.totalsupply?.toLocaleString(undefined, {maximumFractionDigits: 0})} ${theCoin?.coinSymbol}).`:`Market cap based on ${theCoin?.circulatingsupply?.toLocaleString(undefined, {maximumFractionDigits: 0})} ${theCoin?.coinSymbol} circulating supply. Total supply is uncapped.`}  trigger={
      <Icon style={{marginLeft:'1px',verticalAlign: 'text-top'}} name='info circle' color='white' size='tiny' />}/>
      </span>
        <br></br>
        <hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
        <strong><span className='homefinancialslabel'>VOLUME</span></strong> <span className='homefinancials'>
        ${theCoin?.volume >= 1.0e+9 ? (theCoin?.volume/1.0e+9)?.toFixed(1) + "B":theCoin?.volume >= 1.0e+6 ? (theCoin?.volume/1.0e+6)?.toFixed(1) + "M": theCoin?.volume >= 1.0e+3 ? (theCoin?.volume/1.0e+3).toFixed(1) + "K":'N/A'}
        </span>
        <br></br>
        <hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
        <strong><span className='homefinancialslabel'>ALL TIME HIGH</span></strong> <span className='homefinancials'>
        ${(theCoin?.ath < 1 ? theCoin?.ath?.toLocaleString(undefined, {maximumFractionDigits:3}): theCoin?.ath < 10 ? theCoin?.ath?.toLocaleString(undefined, {maximumFractionDigits:2}): theCoin?.ath < 1000 ? theCoin?.ath?.toLocaleString(undefined, {maximumFractionDigits:1}): theCoin?.ath > 1000 ? theCoin?.ath?.toLocaleString(undefined, {maximumFractionDigits:0}):'N/A')}
        <span className='homefinancialslabelath'>{'('}{(100/(((1-(Math.abs(theCoin?.athpercentage)/100))*100)))?.toFixed(1)}{'x)'}</span>
        </span>
        {/* <hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
        <strong><span className='homefinancialslabel'>ATH DATE</span></strong> <span className='homefinancials'>
        {new Date(theCoin?.athdate).toLocaleString('en-us', {month: 'long',day: 'numeric',year: 'numeric'}).split('/').join('-')}
        </span>
        <br></br> */}
        <br></br>
        <hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
        <strong><span className='homefinancialslabel'>ALL TIME HIGH DATE</span></strong> <span className='homefinancials'> 
        {theCoin?.athdate ? new Date(theCoin?.athdate)?.toLocaleString('en-us', {month: 'short',day: 'numeric',year: 'numeric'}).split('/').join('-') :'N/A'}

        </span>

        {/* <strong><span className='homefinancialslabel'>EXCHANGES</span></strong> <span className='homefinancials'> 
        
        {dbData?.exchanges ? <a href={`${dbData?.exchanges?.exchange1.link}`}><img style={{width:'22px',marginLeft:'2px'}} src={`/assets/${dbData?.exchanges?.exchange1.name}.png`} alt="exchange" /></a> :<div style={{marginBottom:'40px'}}>---</div>}
        {dbData?.exchanges ?<a href={`${dbData?.exchanges?.exchange2.link}`}><img style={{width:'22px',marginLeft:'2px'}} src={`/assets/${dbData?.exchanges?.exchange2.name}.png`} alt="exchange" /></a>:''} 
        </span> */}
        <br></br>

              </div>   
    );
  }
}
