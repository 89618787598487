import React, { Component } from 'react'
import { Loader,Grid } from 'semantic-ui-react'
import ReactEcharts from 'echarts-for-react';
import CoinFinancials from './CoinFinancials';

export default class CoinHexagon extends Component {

    getOption(){  
        let financialsScore = this.props.coin.score.financials || 0;
        let teamScore = this.props.coin.score.team || 0;
        let marketScore = this.props.coin.score.market || 0;
        let techScore = this.props.coin.score.tech  || 0;
        let adoptionScore = this.props.coin.score.adoption || 0;
        let socialScore = this.props.coin.score.social || 0;
        let coinName = this.props?.coin?.name;
    
        var option = {
            // title: {
            //     text: '雷达图'
            // },
            tooltip: {
                show: true,
                backgroundColor: '#fff',
                borderColor: '#ddd',
                borderWidth: 1,
                textStyle: {
                    color: '#3c3c3c',
                    fontSize: 16
                },
                formatter: function(p) {
                    // var _arr = p.seriesName.split('/'),
                    // idx = p.seriesIndex;//1，2，3
                    var total = p.value[0] + p.value[1] + p.value[2] + p.value[3] + p.value[4] + p.value[5];
                    return '<strong>' + coinName + '</strong><br>Adoption: ' + p.value[0] +'/20<br> Team: ' + p.value[1] + '/15<br> Social: ' + p.value[2] + '/15<br> Financials: '+ p.value[3] + '/20<br> Tech: '+ p.value[4] + '/20<br> Market: '+ p.value[5] + '/10<br><p style="color: #fff; padding: 2px; background-color: #C5426E "> TFscore™: ' + total + '</p>';
                },
                extraCssText: 'box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)'
            },
            radar: {
                name: {
                    fontSize: 11
                },
                radius: '60%',
                splitNumber: 8,
                axisLine: {
                    lineStyle: {
                        color: '#000',
                        opacity: .2
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: '#fff',
                        opacity: .2
                    }
                },
                splitArea: {
                    areaStyle: {
                        color: 'rgba(127,95,132,.3)',
                        opacity: 1,
                        shadowBlur: 45,
                        shadowColor: 'rgba(0,0,0,.5)',
                        shadowOffsetX: 0,
                        shadowOffsetY: 15,
                    }
                },
                indicator: [{
                    name: 'Financials',
                    max: 20
                }, {
                    name: 'Social',
                    max: 15
                }, {
                    name: 'Adoption',
                    max: 20
                }, {
                    name: 'Tech',
                    max: 20
                }, {
                    name: 'Market',
                    max: 10
                }, {
                    name: 'Team',
                    max: 15
                }]
            },
            series: [{
                name: '预算 vs 开销（Budget vs spending）',
                type: 'radar',
                symbolSize: 0,
                areaStyle: {
                    normal: {
                        shadowBlur: 13,
                        shadowColor: 'rgba(0,0,0,.2)',
                        shadowOffsetX: 0,
                        shadowOffsetY: 10,
                        opacity: 1
                    }
                },
                data: [{
                    value: [financialsScore, socialScore, adoptionScore, techScore, marketScore, teamScore],
                    name: coinName,
                }
                // , {
                //     value: [2500, 12000, 8000, 8500, 12000, 12000],
                //     name: 'EOS',
                // }
            ]
            }],
            color: ['#800080', '#b1eadb'],
            // backgroundColor: {
            //     type: 'radial',
            //     x: 0.4,
            //     y: 0.4,
            //     r: 0.35,
            //     colorStops: [{
            //         offset: 0,
            //         color: '#895355' // 0% 处的颜色
            //     }, {
            //         offset: .4,
            //         color: '#593640' // 100% 处的颜色
            //     }, {
            //         offset: 1,
            //         color: '#39273d' // 100% 处的颜色
            //     }],
            //     globalCoord: false // 缺省为 false
            // }
        };
      
        
        return option
      }

      getOptionBlank(){  
        let financialsScore = 0;
        let teamScore = 0;
        let marketScore = 0;
        let techScore = 0;
        let adoptionScore = 0;
        let socialScore = 0;
        let coinName = this.props.apiData.coinName;
    
        var option = {
            // title: {
            //     text: '雷达图'
            // },
            tooltip: {},
            radar: {
                name: {
                    fontSize: 11
                },
                radius: '60%',
                splitNumber: 8,
                axisLine: {
                    lineStyle: {
                        color: '#000',
                        opacity: .2
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: '#fff',
                        opacity: .2
                    }
                },
                splitArea: {
                    areaStyle: {
                        color: 'rgba(127,95,132,.3)',
                        opacity: 1,
                        shadowBlur: 45,
                        shadowColor: 'rgba(0,0,0,.5)',
                        shadowOffsetX: 0,
                        shadowOffsetY: 15,
                    }
                },
                indicator: [{
                    name: 'Financials',
                    max: 20
                }, {
                    name: 'Social',
                    max: 15
                }, {
                    name: 'Adoption',
                    max: 20
                }, {
                    name: 'Tech',
                    max: 20
                }, {
                    name: 'Market',
                    max: 10
                }, {
                    name: 'Team',
                    max: 15
                }]
            },
            series: [{
                name: '预算 vs 开销（Budget vs spending）',
                type: 'radar',
                symbolSize: 0,
                areaStyle: {
                    normal: {
                        shadowBlur: 13,
                        shadowColor: 'rgba(0,0,0,.2)',
                        shadowOffsetX: 0,
                        shadowOffsetY: 10,
                        opacity: 1
                    }
                },
                data: [{
                    value: [financialsScore, socialScore, adoptionScore, techScore, marketScore, teamScore],
                    name: coinName,
                }
                // , {
                //     value: [2500, 12000, 8000, 8500, 12000, 12000],
                //     name: 'EOS',
                // }
            ]
            }],
            color: ['#800080', '#b1eadb'],
            // backgroundColor: {
            //     type: 'radial',
            //     x: 0.4,
            //     y: 0.4,
            //     r: 0.35,
            //     colorStops: [{
            //         offset: 0,
            //         color: '#895355' // 0% 处的颜色
            //     }, {
            //         offset: .4,
            //         color: '#593640' // 100% 处的颜色
            //     }, {
            //         offset: 1,
            //         color: '#39273d' // 100% 处的颜色
            //     }],
            //     globalCoord: false // 缺省为 false
            // }
        };
      
        
        return option
      }
    
    
  render() {

    return (
        <Grid stackable>
        <Grid.Column centered width={8}>

        <center>
    {this.props.coin ? <CoinFinancials  theSymbol={this.props.theSymbol} dbData={this.props.coin} theCoin={this.props.theCoin}/>: <Loader /> }
            </center>

        {/* <Grid.Column><center>
          <span className='coinpriceboxlabel'>PRICE:</span> <span className='coinpriceboxfigureblack'>
        ${Number(theState[theSymbol].price).toFixed(3)} {theState[theSymbol].pricechange24h > 0 ? <span style={{color:'green',fontSize:'12px'}}>{'('}{Number(theState[theSymbol].pricechange24h).toFixed(2)}{'%)'}</span> 
        : <span style={{color:'#DC143C',fontSize:'12px'}}>{'('}{Number(theState[theSymbol].pricechange24h).toFixed(2)}{'%)'}</span>}</span>
        
        </center></Grid.Column>



        <Grid.Column><center><span className='coinpriceboxlabel'>VOL (24H): </span><span className='coinpriceboxfigureblack'>
          ${theState[theSymbol].volume.toLocaleString(undefined, {maximumFractionDigits: 0})}</span></center></Grid.Column>


        <Grid.Column><center><span className='coinpriceboxlabel'>M.CAP: </span><span className='coinpriceboxfigureblack'>
          ${theState[theSymbol].marketcap.toLocaleString(undefined, {maximumFractionDigits: 0})}</span></center></Grid.Column>

          <Grid.Column><center><span className='coinpriceboxlabel'>SUPPLY: </span><span className='coinpriceboxfigureblack'>
          {theState[theSymbol].totalsupply}</span></center></Grid.Column> */}

    




        {/* <Divider style={{ marginTop: '5px' }} hidden />
        <Grid className='crazybackground' textAlign='center' columns={4} centered>
        <Grid.Column width={4}><center><span className='coinpriceboxlabelfrontpage'>PRICE</span> <p className='coinpriceboxfigurefrontpage'>
        ${(theState[theSymbol] && theState[theSymbol].price < 1 ? theState[theSymbol].price.toLocaleString(undefined, {maximumFractionDigits:3}): theState[theSymbol].price < 10 ? theState[theSymbol].price.toLocaleString(undefined, {maximumFractionDigits:2}): theState[theSymbol].price < 1000 ? theState[theSymbol].price.toLocaleString(undefined, {maximumFractionDigits:1}): theState[theSymbol].price > 1000 ? theState[theSymbol].price.toLocaleString(undefined, {maximumFractionDigits:0}):'N/A')} {theState[theSymbol] && theState[theSymbol].pricechange24h > 0 ? <span style={{color:'green',fontSize:'12px'}}>{'('}{Number(theState[theSymbol].pricechange24h).toFixed(2)}{'%)'}</span>: ''}
        {theState[theSymbol] && theState[theSymbol].pricechange24h < 0 ? <span style={{color:'red',fontSize:'12px'}}>{'('}{Number(theState[theSymbol].pricechange24h).toFixed(2)}{'%)'}</span>: ''}
        
        </p></center></Grid.Column>
        <Grid.Column width={4}><center><span className='coinpriceboxlabelfrontpage'>ATH</span><p className='coinpriceboxfigurefrontpage'>${theState[theSymbol] ? theState[theSymbol].ath.toLocaleString(undefined, {maximumFractionDigits: 2}):'N/A'} 
        <Popup content={`All time high price attained on ${new Date(theState[theSymbol].athdate).toLocaleString('en-us', {month: 'long',day: 'numeric',year: 'numeric'}).split('/').join('-')} which is ${(100/(((1-(Math.abs(theState[theSymbol].athpercentage)/100))*100))).toFixed(1)}x from current price.`}  trigger={
      <Icon style={{marginLeft:'1px',verticalAlign: 'text-top'}} name='question circle' color='white' size='tiny' />}/></p></center></Grid.Column>
        <Grid.Column width={4}><center><span className='coinpriceboxlabelfrontpage'>VOLUME (24H)</span><p className='coinpriceboxfigurefrontpage'>
        ${theState[theSymbol] && theState[theSymbol].volume >= 1.0e+9 ? (theState[theSymbol].volume/1.0e+9).toFixed(1) + "B":theState[theSymbol].volume >= 1.0e+6 ? (theState[theSymbol].volume/1.0e+6).toFixed(1) + "M": theState[theSymbol].volume >= 1.0e+3 ? (theState[theSymbol].volume/1.0e+3).toFixed(1) + "K":'N/A'}
        </p></center></Grid.Column>
        <Grid.Column width={4}><center><span className='coinpriceboxlabelfrontpage'>EXCHANGES</span><br></br>
        
        </center></Grid.Column>
      </Grid> */}
          </Grid.Column>
 
         <Grid.Column centered className='ratinghexagon' width={8}>
        {this.props.coin ? <div className='ratinghexagonshellhome'>
                <ReactEcharts className='echartshome'
                option={this.getOption()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"} />
        </div>:
    
    <div className='ratinghexagonshellhome'>
                <ReactEcharts className='echartshome'
                option={this.getOptionBlank()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"} />
        </div>
    
    }
          </Grid.Column>
          </Grid>
    )
  }
}
