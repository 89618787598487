import React, { Component } from 'react'

import ReactEcharts from 'echarts-for-react';


class MarketMatrix extends Component {

  getOption(){  

      let currentCoin = this.props.theState.coinMatrixCoin;
      let currentCoinName = this.props.theState.coinMatrixCoin[0]?.[2];
      let otherCoins = this.props.theState.coinMatrixOthers;

    // let twitter = this.props.theState.twitterfans || 0;
    // let reddit = this.props.theState.redditsub || 0;
    // let github = this.props.theState.githubstars || 0;
    // let facebook = this.props.theState.facebookfans || 0;
    // let telegram = this.props.theState.telegramfans || 0;


    // let data = [
    //     {
    //         value: telegram,
    //         name: 'Telegram'
    //     },
    //     {
    //         value: facebook,
    //         name: 'Facebook'
    //     },
    //     {
    //         value: twitter,
    //         name: 'Twitter'
    //     },
    //     {
    //         value: reddit,
    //         name: 'Reddit'
    //     },
    //     {
    //         value: github,
    //         name: 'Github'
    //     }
    
     
 
    // ];
    // let color = [ '#ececec','#4867AA','#1DA1F2','#FF4500','black' ];
    // let data1 = (data[0].value + data[1].value + data[2].value + data[3].value + data[4].value).toLocaleString();
    // let baseData = [];
    // for (var i = 0; i < data.length; i++) {
    //     baseData.push({
    //         value: data[i].value,
    //         name: data[i].name,
    //         itemStyle: {
    //             normal: {
    //                 borderWidth: 22,
    //                 shadowBlur: 10,
    //                 borderColor: color[i],
    //                 shadowColor: 'rgba(0, 0, 0, 0.8)'
    //             }
    //         }
    //     });
    // }
    const cryptoTypes = ['red','yellow','blue'];

    const option = {
        title : {
        text: 'Market Position Matrix',
        left: 'center',
        top: '0%'
    },
    grid: {
        left: '0%',
        right: '7%',
        top: '19%',
        bottom: '3%',
        containLabel: true
    },
    tooltip : {
        // trigger: 'axis',
        showDelay : 0,
        formatter : function (params) {
            if (params?.value?.length > 1) {
                return params.value[2]
            }
            else {
                return null
                // return params.seriesName + ' :<br/>'
                // + params.name + ' : Params Name'
                // + params.value + 'Params Value';
            }
        },
        axisPointer:{
            show: true,
            label: {
            precision: '0'
            },
            type : 'cross',
            lineStyle: {
                type : 'dashed',
                width : 1
            }
        }
    },
    legend: {
        left: 'left',
        bottom: 20,
        selectedMode: false,
        orient: 'horizontal'
    },
    xAxis: {
        scale: true,
        show:false
    },
    yAxis: {
        name: 'MC Rank',
        scale: true,
        show: false,
        inverse: true,
        axisLabel: {
            fontSize: 9
          },
    },
    series: [{
        name: currentCoinName,
        type: 'effectScatter',
        rippleEffect: {
scale: 3,
        },
        symbolSize: 10,
        itemStyle: {
        color: '#800080',
        },
        data: currentCoin
    },
    {
        name: 'Others',
        z:100,
        itemStyle: {
            color: '#00B5AD'
                    },
        type: 'scatter',
        data: otherCoins,
            markPoint : {
                
            },
            markArea: {
                // silent: true,
                itemStyle: {
                    color: 'transparent',
                    emphasis:{
                        color: '#ececec'
                    }
                },
                label: {
                        show: true,
                        position: 'inside',
                        color: '#fff',
                        textBorderColor: 'black',
                        textBorderWidth: 2,
                        fontSize: '14',
                        fontWeight:'bolder',
                        emphasis: {
                            position:'inside',
                            textBorderColor:'#800080'
                        }
                },
                data: [

                    [
                        {
                            name: '?',
                            coord: [2014.5, 9]
                        },
                        {
                            coord: [2020,18]
                        }
                    ],
                    [
                        {
                            name: 'Rising Stars',
                            coord: [2014.5, 1]
                        },
                        {
                            coord: [2020,9]
                        }
                    ],
                    [
                        {
                            name: 'Blue Chips',
                            coord: [2009, 1]
                        },
                        {
                            coord: [2014.5,9]
                        }
                    ],
                    [
                        {
                            name: 'Dogs',
                            coord: [2009, 9]
                        },
                        {
                            coord: [2014.5,18]
                        }
                    ],
        ]},
            markLine : {
                width: '100%',
                symbol:'none',
                label: {
                    silent: true,
                    fontSize: '9',
                    show: true,
                    position: 'start',
                    formatter : function (params) {

                        if (params.dataIndex !== 1) {
                            return 'Launch'
                        }
                        else {
                         return 'Market Cap Rank'
                        }
                    },
                },
                lineStyle: {
                    normal: {
                        type: 'solid',
                        color: 'black'
                    }
                },
                data : [
                    // {type : 'average', name: 'Average'},
                    [{coord: [2009, 9]},{coord:[2020,9]}],
                    [{coord: [2014.5, 1]},{coord:[2014.5,18]}],
                    // { xAxis: 2015}
             
             
                ],
            }
    }]
};

    return option
  }

  render() {
 
      
    return (
       <div>
           <center>
           {this.props.theState ? 
        <ReactEcharts
  option={this.getOption()}
  notMerge={true}
  style={{width:'100%',height:'260px',marginTop:'-10px'}}
  lazyUpdate={true}
  theme={"theme_name"} />
       : ''} 
       </center></div>
    )
  }
}

export default MarketMatrix;


