import React, { Component } from 'react'

import ReactEcharts from 'echarts-for-react';


class GithubCommits extends Component {

  getOption(){
    let data1 = this.props.theState.githubFirstHalfData || 0;
    let data2 = this.props.theState.githubSecondHalfData || 0;
    let range1 = this.props.theState.githubFirstHalfRange || ['2019-01-01','2019-06-30'];
    let range2 = this.props.theState.githubSecondHalfRange || ['2019-07-01','2019-12-31'];
    let max = this.props.theState.githubMaxCommits || 0;

    // var data1 = [["2015-01-03",1],["2015-03-03",5]];
    // var data2 = [["2015-05-03",1],["2015-07-03",5]];
    // var data3 = [["2015-11-03",1],["2015-12-03",3]];


    const option = {
        title: {
            top: 5,
            left: 'center',//'center',
            text: 'Dev Activity (1Y)'
        },
        tooltip: {
            position: 'top',
            formatter: '{c} commits',
        },
        visualMap: {
            min: 0,
            max: max,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            top: '165',
            inRange: {
                color: ['#F6F6F6','#800080']
            }
        },
    
        calendar: [
        {
            range: range1,
            // range: ['2015-01-15', '2015-06-30'],
            cellSize: ['auto', 8],
            top:40,
            left: 21,
            right: 5,
              dayLabel: {
            firstDay: 1,
            fontSize: 9
              },
            yearLabel: {
                show: false,
            },
            monthLabel: {
                fontSize:11,
                padding: [0, 0, -5, 0],
            },
        },
        {
            top: 110,
            range: range2,
            // range: ['2015-07-27', '2015-12-31'],
            cellSize: ['auto',8],
            left: 21,
            right: 5,
            dayLabel: {
            firstDay: 1,
            fontSize:9
        },
        monthLabel: {
            fontSize:11,
            padding: [0, 0, -5, 0],
        },
        yearLabel: {
            show: false,
        }
        },
        // {
        //     top: 235,
        //     range: ['2015-09-01', '2015-12-31'],
        //     cellSize: ['auto', 10],
        //     left: 17,
        //     right: 5,
        //              dayLabel: {
        //     firstDay: 1
        // },
        //     yearLabel: {
        //         show: false,
        //     }
        // }
    ],
    
        series: [{
            type: 'heatmap',
            coordinateSystem: 'calendar',
            calendarIndex: 0,
            data: data1
        }, {
            type: 'heatmap',
            coordinateSystem: 'calendar',
            calendarIndex: 1,
            data: data2
        }
        //  {
        //     type: 'heatmap',
        //     coordinateSystem: 'calendar',
        //     calendarIndex: 2,
        //     data: data3
        // }
    ]
    
    };
  
    
    return option
  }

  render() {
 
      
    return (
       <div>
           <center>
           {this.props ? 
        <ReactEcharts
  option={this.getOption()}
  notMerge={true}
  style={{marginTop:'-10px',height:'185px'}}
  lazyUpdate={true}
  theme={"theme_name"} />
       : ''} 
       </center></div>
    )
  }
}

export default GithubCommits;


