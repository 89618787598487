import { toastr } from 'react-redux-toastr';
import { FETCH_TACTICS } from './newsConstants';
import { asyncActionStart,asyncActionFinish,asyncActionError} from '../async/asyncActions';
import { createNewNews } from '../../app/common/util/helpers';
import moment from 'moment';
import firebase from '../../app/config/firebase'

export const fetchTactics = tactics => {
  return {
    type: FETCH_TACTICS,
    payload: tactics
  };
};


export const createNews = (tactic, state) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const user = firestore.auth().currentUser;
    // const photoURL = getState().firebase.profile.photoURL;
    let newNews = createNewNews(user,tactic, state);
    try {
      await firestore.add(`news`, newNews);
      // await firestore.set(`event_attendee/${createdEvent.id}_${user.uid}`, {
      //   eventId: createdEvent.id,
      //   userUid: user.uid,
      //   eventDate: tactic.date,
      //   host: true
      // });
      toastr.success('Success', 'News submitted, pending approval');
    } catch (error) {
      toastr.error('Oops', 'Something went wrong');
    }
  };
};

export const updateNews = event => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    if (event.date !== getState().firestore.ordered.events[0].date) {
      event.date = moment(event.date).toDate();
    }
    try {
      await firestore.update(`events/${event.id}`, event);
      toastr.success('Success', 'Event has been updated');
    } catch (error) {
      console.log(error);
      toastr.error('Oops', 'Something went wrong');
    }
  };
};

export const getNewsForDashboard = (lastTactic, cat,coin) => async (dispatch,getState) => {
    
    const firestore = firebase.firestore();
    let tacticsRef = firestore.collection('news').orderBy("newsDate", "desc");
    if(cat && cat !== '') {
      if(coin && coin !== '') {
        tacticsRef = firestore.collection('news').where('category', '==',cat).where('coinName','==',coin)
      } else {
        tacticsRef = firestore.collection('news').where('category', '==',cat);
      }
     
    } else if (coin && coin !=='') {
        tacticsRef = firestore.collection('news').where('coinName', '==',coin);
      } 
      else {
        tacticsRef = firestore.collection('news').orderBy("newsDate", "desc");
      }
      
   
    
   try {
     dispatch(asyncActionStart());
    console.log('tacticsRef',tacticsRef)
     let startAfter = lastTactic && (await firestore.collection('news').doc(lastTactic.id).get());
     let query;

     lastTactic ? (query = tacticsRef.startAfter(startAfter).limit(2)): (query = tacticsRef.limit(2));
   
    let querySnap = await query.get();
    if (querySnap.docs.length === 0) {
      dispatch(asyncActionFinish());
      return querySnap;
    }

    let news = [];

     for (let i=0; i < querySnap.docs.length; i++) {
       let tct = {...querySnap.docs[i].data(), id: querySnap.docs[i].id};
       news.push(tct);
     }
     dispatch({type:FETCH_TACTICS, payload: {news}})
     dispatch(asyncActionFinish());
     return querySnap;

   } catch (error) {
     console.log(error)
     dispatch(asyncActionError())
   }
  }


