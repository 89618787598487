

export const createNewNews = (user, tactic, state) => {
    // tactic.date = moment(tactic.date).toDate();
    // tactic.newsDate = moment(tactic.newsDate).toDate();
    return {
      headline: tactic.headline,
      coinPic: state.coinPic,
      coinName: state.coinName,
      category: tactic.category,
      coinSymbol: tactic.coinSymbol,
      sourceURL: tactic.sourceURL,
      // newsDate: tactic.newsDate,
      newsDate: state.newsDate,
      // authorUID: user.uid,
      // authorName: user.displayName,
      created: Date.now(),
      favoritedUID: []
    }
  }