import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Modal, Header, Segment,Checkbox} from 'semantic-ui-react';
import {closeModal} from "./modalActions";

const actions = {closeModal};

class StrategyDetailModalPreview extends Component {
   
    render() {
    
        const item = this.props
        return (
            <Modal
            className='scrolling'
            centered={false}
            scrolling="true"
            size='tiny' 
            closeIcon
                open={true}
                onClose={this.props.closeModal}
            >
                <Modal.Header>
                {this.props.headline} 
                </Modal.Header>
                
                <Modal.Content>
                    <Segment>
                    <Header>Source</Header>
                    <Modal.Description> 
                     {item.sourceURL}
                    </Modal.Description>
                    </Segment>

                    <Segment>
                    <Header>Image Proof</Header>
                    <Modal.Description>
                        
                    {item.steps && item.steps.map((item,index) => (
            
           <li key={index} style={{listStyle: 'none'}}><Checkbox style={{paddingTop:'3px', marginRight:'4px'}} />{' '}{item.step}</li>
          )
            )}
            
                    </Modal.Description>
                    </Segment>
                   
                </Modal.Content>
            </Modal>
        );
    }
}

export default connect(null,actions)(StrategyDetailModalPreview);