import React from 'react'
import {Container,Segment, Button, Grid, Divider, Popup, Icon} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import CoinHexagon from './CoinHexagon';
import ScrollUpButton from '../../app/layout/ScrollUpButton';
import {isMobileOnly} from "react-device-detect";

const CoinListCard = (props) => {
    let dbData = props?.dbData;
    let apiData = props?.apiData;
    return (
 

        <Container>
         <div className='coinlistgroup'>
            {apiData && props?.dbData?.map((coin, index) => {

         let theCoin = apiData?.filter(function(data) {return data.coinSymbol === coin.coinSymbol});
   
         let theSymbol = coin.coinSymbol
          return(
            <div key={index} style={{ marginBottom:'20px',justifyContent: 'center', width:'100%'}}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Segment className="coindetailbox" style={{ justifyContent: 'center !important' }}>
            <div className='topleftcorner'></div>
            <Divider hidden style={{ marginBottom: '2px' }} />
            <Grid.Row className="cointitledescription">
            <div className='toplefttag'>
            <span className='topleftranktext'>#{coin.rank} <span style={{verticalAlign:'top',fontSize:'9px'}}></span></span>
            <span className='toplefttagtext'>
           TFscore™: {coin.score.total}%<Popup inverted content={'The total TFScore™ taking into account Adoption, Financials, Social, Tech, Team and Market scores.'}  trigger={
      <Icon style={{marginLeft:'1px',verticalAlign: 'text-top'}} name='info circle' color='white' size='tiny' />}/>
            </span>
           </div>
            <div className='toprighttag'>
           <span className='toprighttagtext'>{coin.type}</span>
           </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start'
        }}
      >
 
       <div style={{width: '64px', minWidth: '64px',height: '64px',minHeight: '64px',overflow: 'hidden',marginRight: '12px',position: 'relative'}}>
          <img alt="coinlogo" src={`/assets/logos/${coin.coinSymbol.toLowerCase()}.png`}/>
        </div>
 
        <div style={{width:'100%'}}>
          <span style={{fontWeight: '700',color: '#000',fontSize: '32px'}}>
            {theCoin[0].coinName}
          </span>
          <span style={{marginLeft: '5px',fontWeight: '700',color: 'grey',fontSize: '16px'}}>
            {'('}{theCoin[0].coinSymbol}{')'}
          </span>

            {/* {isMobileOnly? <span style={{fontWeight: '400',fontSize: '15px',marginTop: '10px'}}><br></br><em><p style={{marginTop:'5px'}}>"{coin.brief}"</p></em></span>: <span style={{fontWeight: '400',fontSize: '15px',marginTop: '10px',marginLeft:'10px'}}><em>"{coin.brief}"</em></span>} */}
            {isMobileOnly? <span style={{fontWeight: '400',fontSize: '15px',marginTop: '10px'}}><br></br><em><p style={{marginTop:'5px'}}>"{coin.brief}"</p></em></span>: <span style={{fontWeight: '400',fontSize: '15px',marginTop: '10px'}}><br></br><em><p style={{marginTop:'5px'}}>{coin.description}</p></em></span>}

        </div>
        </div>
        <Divider hidden style={{ marginTop: '3px' }} />
            <CoinHexagon theCoin={theCoin} coin={coin}/>

          <Grid>
          <Grid.Row centered style={{margin:'0px', padding:'4px', marginTop:'-40px'}}>
            {coin.moreInfo ? <Button as={Link} to={`/coin/${theCoin?.[0]?.coinSymbol?.toLowerCase()}`} size="small" style={{ backgroundColor:'#00B5AD',color: "#FFFFFF", marginTop: "0px" }}>
              Learn More
            </Button> : 
            <Button disabled>Currently Not Covered</Button>
          }
          </Grid.Row>{" "}
        </Grid>



    </Grid.Row>
            </Segment>
          </div>
          <ScrollUpButton />
          </div>
        )}
        )}
         </div>
       </Container>
    )
}

export default CoinListCard
