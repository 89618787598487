/* eslint-disable no-redeclare */
import React, { Component } from 'react';
import { withFirestore } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Container,Segment, Divider, Dropdown, Loader} from 'semantic-ui-react';
import axios from 'axios';
import CoinProgress from './CoinProgress';
import CoinTeam from './CoinTeam';
import CoinFinancials from './CoinFinancials';
import CoinSocial from './CoinSocial';
import CoinSummary from './CoinSummary';
import CoinDescription from './CoinDescription';
import Timeline from './Timeline';
import CoinChart from './CoinChart';
import CoinAdoption from './CoinAdoption';
import ScrollUpButton from '../../app/layout/ScrollUpButton'
import CoinMarket from './CoinMarket';

const mapStateToProps = (state, ownProps) => {
  // eslint-disable-next-line no-unused-vars
  let coin = {}
  // eslint-disable-next-line no-unused-vars
  let hist = {};

  let news ={};

  if (state.firestore.ordered.coins && state.firestore.ordered.coins[0]) {
   
    coin = state.firestore.ordered.coins[0];
    hist = state.firestore.ordered.historical;
    news = state.firestore.ordered.coinNews;

  }

  return {
    coin,hist,news
  }

}

class CoinPage extends Component {

  state = {
    githubFirstHalfData: '',
    githubSecondHalfData: '',
    githubMaxCommits:'',
    githubFirstHalfRange:'',
    githubSecondHalfRange: '',
    price: '',
    volume: '',
    marketcap: '',
    code: '',
    pricechange: '',
    marketcaprank: '',
    redditsub: '',
    redditactive: '',
    twitterfans:'',
    facebookfans:'',
    telegramfans:'',
    githubstars:'',
    alexarank:'',
    hist: '',
    histDataFormatted:'',
    facebookLikes:'',
    twitterFollowers:'',
    redditPosts:'',
    redditComments:'',
    redditSubscribers:'',
    redditActive:'',
    currency:'',
    currencySymbol:'$',
    currencyName: 'USD',
    secondary:'',
    secondaryName:'',
    chosenData:'',
    coinScores:[],
    coinMatrixCoin: [],
    coinMatrixOthers: [],
    chosenComparison: false,
    isLoaded: false
  };

  handleDropdownChange = (e, secondthing) => {
    let theValue = e.value;
    let passedData = [];
    let passedName = "";
    if(theValue === 1) {
      passedData = this.state.facebookLikes;
      passedName = "Facebook Likes"
    }
    if(theValue === 2){
      passedData = this.state.twitterFollowers;
      passedName = "Twitter Followers";
    }
    if(theValue === 3){
      passedData = this.state.redditPosts;
      passedName = "Reddit Posts";
    }
    if(theValue === 4){
      passedData = this.state.redditComments;
      passedName = "Reddit Comments";
    }
    if(theValue === 5){
      passedData = this.state.redditSubscribers;
      passedName = "Reddit Subscribers";
    }
    if(theValue === 6){
      passedData = this.state.redditActive;
      passedName = "Reddit Active Accounts";
          }
    if(theValue === 7){
      passedData = this.state.githubStars;
      passedName = "Github Stars";
          }
    
    this.setState({[secondthing]: passedData,
                secondaryName: passedName
    });
  }

  handleCurrencyChange = (e, secondthing) => {
    let theValue = e.value;
    let passedData = [];
    let passedName = "";
    let passedSymbol = '';
    if(theValue === 1) {
      passedData = this.state.histDataFormattedUSD;
      passedName = "USD";
      passedSymbol = '$'
    }
    if(theValue === 2){
      passedData = this.state.histDataFormatted;
      passedName = "BTC";
      passedSymbol = '₿';
    }
    
    this.setState({chosenData: passedData,
                currencyName: passedName,
                currencySymbol: passedSymbol
    });
  }

  handleComparisonChange = (e, secondthing) => {
    let chosenSymbol = e.value;
    this.setState({
                chosenComparison: chosenSymbol
    });
  }

   async componentDidMount() {
    const {firestore, match, history} = this.props;
    let coinName = match.params.id;
    let coinNameCapitalized = coinName.toUpperCase();
    let coin = await firestore.get(`coins/${coinName}`);
    if (coin.exists){
      let coinData = coin.data()
      let coinApiName = coinData.coinApiName;
      let githubPath = coinData.info.githubPath;
      this.fetchApiData(coinApiName);
      this.fetchGithubData(githubPath);
    }    
    let milestoneSnap = await firestore.get({ collection: 'coins', doc: coinName, subcollections: [{ collection: 'milestones' }],storeAs:'milestones' });
    let historicalSnap = await firestore.get({ collection: 'coins', doc: coinName, subcollections: [{ collection: 'historical' }],storeAs:'historical' });
    let otherCoinsSnap = await firestore.get(`homepage/coinlist`);
    let otherCoins = otherCoinsSnap.data();

    let btcPriceData = [];
    let usdPriceData = [];
    let facebookLikes = [];
    let redditActive = [];
    let newsData = [];
    let redditSubscribers = [];
    let redditPosts = [];
    let twitterFollowers = [];
    let redditComments = [];
    let githubStars = [];
    let coinScores = [];
    let coinMatrixOthers = [];
    let coinMatrixCoin = [];

    for (var key in otherCoins) {
      let currentCoin = otherCoins[key];
      if(currentCoin.score) {
          coinScores.push({'text':key,'value':key, 'score': currentCoin.score});
      }
      if (key === coinNameCapitalized) {
        let year = parseInt(currentCoin.launch.substring(6, 11));
        let month = parseInt(currentCoin.launch.substring(0, 2));
        let day = parseInt(currentCoin.launch.substring(3, 5));
        let yearCalc = year + ((((month-1)*30) + (day))/365);
        coinMatrixCoin.push([yearCalc,currentCoin.marketcaprank,key]);
      }else{
      let year = parseInt(currentCoin.launch.substring(6, 11));
      let month = parseInt(currentCoin.launch.substring(0, 2));
      let day = parseInt(currentCoin.launch.substring(3, 5));
      let yearCalc = year + ((((month-1)*30) + (day))/365);
      coinMatrixOthers.push([yearCalc,currentCoin.marketcaprank,key]);
    }
    }

    coinScores.sort(function(a, b){
      var nameA=a.value.toLowerCase(), nameB=b.value.toLowerCase()
      if (nameA < nameB) //sort string ascending
          return -1 
      if (nameA > nameB)
          return 1
      return 0 
  })

    // let coinRank = coinRankRaw.filter((coin =>{
    //   return coin.type === "Cryptocurrency" 
    // }));

    for (let i=0; i < milestoneSnap.docs.length; i++) {
      for (var key in milestoneSnap.docs[i].data()) {
        let thisDoc = milestoneSnap.docs[i].data()[key];

        thisDoc.news.forEach(newsItem => {
          newsData.push(newsItem)
        });
      }
    }

    
    for (let i=0; i < historicalSnap.docs.length; i++) {
      for (var key in historicalSnap.docs[i].data()) {
        let thisDoc = historicalSnap.docs[i].data()[key];
        let facebookData = thisDoc.facebook_likes;
        let twitterData = thisDoc.twitter_followers;
        let redditSubData = thisDoc.reddit_subscribers;
        let redditActiveData =thisDoc.reddit_accounts_active_48h;
        let githubData = thisDoc.github_stars;

        if(thisDoc.reddit_average_comments_48h === 0) {
                redditComments.push(null);
          } else {
                redditComments.push(thisDoc.reddit_average_comments_48h);
                }

        if(thisDoc.reddit_average_posts_48h === 0) {
                redditPosts.push(null);
          } else {
                redditPosts.push(thisDoc.reddit_average_posts_48h);
                }

        let priceDataBTC = [thisDoc.date,thisDoc.openBTC, thisDoc.closeBTC,thisDoc.lowBTC,thisDoc.highBTC,thisDoc.volumeBTC];
        let priceDataUSD = [thisDoc.date,thisDoc.openUSD, thisDoc.closeUSD,thisDoc.lowUSD,thisDoc.highUSD,thisDoc.volumeUSD];
        if(thisDoc.news) {
          priceDataBTC.push(thisDoc.news);
          priceDataUSD.push(thisDoc.news);

          thisDoc.news.forEach(newsItem => {
            newsData.push(newsItem)
          });
          // for (let n=0; n < historicalSnap.docs[i].data()[key].news.length; n++) {
          //   data.push(historicalSnap.docs[i].data()[key].news[n])
          // }
          }
        btcPriceData.push(priceDataBTC);
        usdPriceData.push(priceDataUSD);
        facebookLikes.push(facebookData);
        twitterFollowers.push(twitterData);
        redditSubscribers.push(redditSubData);
        redditActive.push(redditActiveData);
        githubStars.push(githubData);
        

      }
      // old way of getting data that had separate doc for each day, instead of making year based docs
      // let data = [historicalSnap.docs[i].data().date, historicalSnap.docs[i].data().open, historicalSnap.docs[i].data().close,historicalSnap.docs[i].data().low,historicalSnap.docs[i].data().high,historicalSnap.docs[i].data().volume];
      // if(historicalSnap.docs[i].data().news) {
      //   data.push(historicalSnap.docs[i].data().news)
      // }
      // priceNews.push(data);
    }

    // let newsSnap = await firestore.get({ collection: 'news', where: ['coinName','==', coinNameCapitalized] ,  orderBy: ['newsDate','desc'],storeAs:'coinNews' });
    // let newsData = [];
    
    // for (let i=0; i < newsSnap.docs.length; i++) {
    //   let data = {'date': newsSnap.docs[i].data().newsDate.toDate().toLocaleString('eu', {
    //     month: '2-digit',
    //     day: '2-digit',
    //     year: 'numeric',
    //   }), 'headline': newsSnap.docs[i].data().headline, 'category': newsSnap.docs[i].data().category, 'source': newsSnap.docs[i].data().sourceURL};
    //   newsData.push(data);
    // }
 
   
    this.setState({
      histDataFormatted: btcPriceData,
      histDataFormattedUSD: usdPriceData,
      chosenData: usdPriceData,
      facebookLikes,
      twitterFollowers,
      redditPosts,
      githubStars,
      coinScores,
      redditComments,
      redditSubscribers,
      redditActive,
      newsData,
      isLoaded: true,
      coinMatrixCoin,
      coinMatrixOthers
    });


    if(!coin.exists) {
      history.push('/');
      toastr.error('Sorry','Coin not found');
    }
  }
  async fetchApiData(coinApiName) {
    const res = await axios.get(
      `https://api.coingecko.com/api/v3/coins/${coinApiName}`
    );
    try {
      this.setState({
        marketcap: res.data.market_data.market_cap.usd,
        marketcaprank: res.data.market_cap_rank,
        volume: res.data.market_data.total_volume.usd,
        price: res.data.market_data.current_price.usd,
        pricechange1d: res.data.market_data.price_change_percentage_24h,
        pricechange30d: res.data.market_data.price_change_percentage_30d,
        pricechange1y: res.data.market_data.price_change_percentage_1y,
        twitterfans: res.data.community_data.twitter_followers,
        facebookfans: res.data.community_data.facebook_likes,
        telegramfans: res.data.community_data.telegram_channel_user_count,
        redditsub: res.data.community_data.reddit_subscribers,
        redditactive: res.data.community_data.reddit_accounts_active_48h,
        githubstars: res.data.developer_data.stars,
        alexarank:res.data.public_interest_stats.alexa_rank,
        circulatingsupply: res.data.market_data.circulating_supply,
        totalsupply:res.data.market_data.total_supply,
        ath:res.data.market_data.ath.usd,
        athpercentage: res.data.market_data.ath_change_percentage.usd,
        athdate: res.data.market_data.ath_date.usd,
        price24hlow:res.data.market_data.low_24h.usd,
        price24hhigh:res.data.market_data.high_24h.usd,


      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchGithubData(githubPath) {
    const res = await axios.get(
      `https://api.github.com/repos/${githubPath}/stats/commit_activity`
    );
    try {
      let rawData = res.data;
      let firstDayRaw = new Date(rawData[0].week * 1000).toLocaleDateString();
      function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        return [year, month, day].join('-');
    }
      let firstDay =  formatDate(firstDayRaw);
      let commitData = await res.data.map(array => {
        return array.days
      })

      let flatCommitData = commitData.flat();

      let maxCommits = Math.max(...flatCommitData);
      let halfway = Math.floor(flatCommitData.length / 2);

      let copyForSecondHalf = new Date(firstDay);
      let secondHalfFirstDay  = formatDate(copyForSecondHalf.setDate(copyForSecondHalf.getDate() + halfway));
      let copyForFirstHalfEnd = new Date(firstDay);
      let firstHalfEnd = formatDate(copyForFirstHalfEnd.setDate(copyForFirstHalfEnd.getDate() + (halfway-1)));
      let copyForSecondHalfEnd = new Date(firstDay);
      let secondHalfEnd = formatDate(copyForSecondHalfEnd.setDate(copyForSecondHalfEnd.getDate() + (flatCommitData.length-1)));

      let firstHalfRange = [firstDay,firstHalfEnd];
      let secondHalfRange = [secondHalfFirstDay, secondHalfEnd];
      
      let firstHalfRaw = flatCommitData.slice(0, halfway);
      let secondHalfRaw = flatCommitData.slice(halfway, flatCommitData.length);

      let firstHalf = [];
      let secondHalf = [];
      
      for (let i=0; i < firstHalfRaw.length; i++) {
        let copiedDate = new Date(firstDay);
        firstHalf.push([formatDate(copiedDate.setDate(copiedDate.getDate() + i)),firstHalfRaw[i]])
      }

      for (let i=0; i < secondHalfRaw.length; i++) {
        let copiedDate = new Date(secondHalfFirstDay);
        secondHalf.push([formatDate(copiedDate.setDate(copiedDate.getDate() + i)),secondHalfRaw[i]])
      }

      this.setState({
        githubFirstHalfData: firstHalf,
        githubSecondHalfData: secondHalf,
        githubMaxCommits:maxCommits,
        githubFirstHalfRange:firstHalfRange,
        githubSecondHalfRange: secondHalfRange
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {

    const options = [
      { key: 1, text: 'Facebook Likes', value: 1 },
      { key: 2, text: 'Twitter Followers', value: 2 },
      { key: 3, text: 'Reddit Posts', value: 3 },
      { key: 4, text: 'Reddit Comments', value: 4 },
      { key: 5, text: 'Reddit Subscribers', value: 5 },
      { key: 6, text: 'Reddit Active Accounts', value: 6},
      { key: 7, text: 'Github Stars', value: 7}
    ]

    const currencyOptions = [
      { key: 1, text: 'USD', value: 1 },
      { key: 2, text: 'BTC', value: 2 },
    ]

    const comparisonOptions =  this.state.coinScores;

    return (
      <React.Fragment>
         {/* <div className='homehero'></div> */}
     
      <Container className="main">
      <div style={{ justifyContent: 'center' }}>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Segment className="coindetailbox" style={{ justifyContent: 'center !important' }}>
          <div className='topleftcorner'></div>
          <div className='toplefttag' style={{marginTop:'10px'}}> 
            <span className='topleftranktext'>{this.state.marketcaprank ? '#' + this.state.marketcaprank : <Loader active inline size='mini'/>}</span>
            <span className='toplefttagtext'>
             <span style={{display:'inline-block',verticalAlign:'middle',marginTop:'-6px',marginRight:'4px'}}>Buy </span><span style={{verticalAlign:'top',marginTop:'-5px',fontSize:'12px'}}>@ </span>
        {this?.props?.coin?.info && this?.props?.coin?.info?.exchange1?.link === '' ? '-': <a href={`${this?.props?.coin?.info?.exchange1?.link}`}><img className='exchangelogos' src={`/assets/${this?.props?.coin?.info?.exchange1?.name}.png`} alt="exchange" /></a>} 
        {this?.props?.coin?.info && this?.props?.coin?.info?.exchange2?.link === '' ? '': <a href={`${this?.props?.coin?.info?.exchange2?.link}`}><img className='exchangelogos' src={`/assets/${this?.props?.coin?.info?.exchange2?.name}.png`} alt="exchange" /></a>} 
        </span>
           </div>
            <div className='toprighttag'>
            {this.props.coin.info ? <span className='toprighttagtext'>{this.props.coin.info.coinType}</span>: ''}
           </div>
           <Divider hidden style={{ marginTop: '5px' }} />
          <CoinDescription/>
          <CoinSummary chosenComparison={this.state.chosenComparison} coinScores={this.state.coinScores} coinInfo={this.props.coin}/> 
          {/* <Divider hidden style={{ marginTop: '15px' }} /> */}
          <center>
          <div style={{textAlign:'center',marginBottom:'20px',marginTop:'2px'}}><Dropdown clearable compact style={{width:'10px !important',marginRight:'5px', marginBottom:'1px', color:'#800080', borderColor:'#800080', zIndex: '10'}} onChange={(e, value)=>this.handleComparisonChange(value, 'secondary')} name="secondary" type="text" placeholder="Compare" options={comparisonOptions} selection/></div>
         </center>
          </Segment>
        </div>


        <Divider style={{ marginTop: '15px' }} hidden />
        
        <CoinFinancials coinInfo={this.props.coin} theState={this.state}/> <Divider style={{ marginTop: '15px' }} hidden />
          
        <CoinAdoption coinInfo={this.props.coin}/><Divider style={{ marginTop: '15px' }} hidden /> 
        
        <CoinTeam theState={this.state} coinInfo={this.props.coin}/> <Divider style={{ marginTop: '15px' }} hidden />

        <CoinProgress coinInfo={this.props.coin} /> <Divider style={{ marginTop: '15px' }} hidden />

        <CoinSocial coinInfo={this.props.coin} theState={this.state}/> <Divider style={{ marginTop: '15px' }} hidden />

        <CoinMarket coinInfo={this.props.coin} theState={this.state}/> <Divider style={{ marginTop: '15px' }} hidden />


        <div style={{ display: 'flex',justifyContent: 'center' }}>
      <Segment className="coinchartbox">
      <div className='ribbon'>Chart</div>     
       {this.state.isLoaded ? 
       <React.Fragment>
         <div className='toprighttag'>
          <span>
        <Dropdown compact style={{width:'10px !important',marginRight:'5px', marginBottom:'1px', color:'#800080', borderColor:'#800080', zIndex: '10'}} onChange={(e, value)=>this.handleCurrencyChange(value, 'secondary')} name="secondary" type="text" placeholder="$ / ₿" options={currencyOptions} defaultValue={1} selection/>
          </span>
  
          <span>
        <Dropdown compact style={{marginRight:'3px', marginBottom:'1px', color:'#800080', borderColor:'#800080', zIndex: '10'}} onChange={(e, value)=>this.handleDropdownChange(value, 'secondary')} name="secondary" clearable type="text" placeholder="Secondary" options={options} selection/>
          </span>
</div>
       {this.state.isLoaded ? <CoinChart secondary={this.state.secondary} currencySymbol={this.state.currencySymbol} currencyName={this.state.currencyName} secondaryName={this.state.secondaryName} chosenData={this.state.chosenData}/>:<Loader active inline size='mini'/>}
       </React.Fragment> : <Loader inverted>Loading</Loader>} 

      </Segment>
    </div>

    <Divider style={{ marginTop: '15px' }} hidden />



        {this.state.isLoaded ? <Timeline newsData={this.state.newsData}/> : <center> <Loader inverted>Loading</Loader></center>}
      </div>

      <ScrollUpButton />
      </Container>
      </React.Fragment>
    );
  }
}

export default withFirestore(connect(mapStateToProps)(CoinPage));
