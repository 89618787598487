import React, { Component } from 'react'
import { Grid, Divider,Loader,Icon, Popup, Progress} from 'semantic-ui-react'
import ReactEcharts from 'echarts-for-react'

export default class CoinSummary extends Component {

    getAdoptionScoreOption(){ 
        var categoryPoints = this.props.coinInfo.info.adoption;
        var totalPoints = 20;
        var scoreColor = '#B7DA62';
        if(categoryPoints/totalPoints < .34){
            scoreColor = '#D95C5C'
        }else if(categoryPoints/totalPoints < .67){
            scoreColor = '#E6BC4C'
        }
        var spNum = 15;
        
        var theScore = [`${categoryPoints}/${totalPoints}`];
        
        var y_data = ['Adoption'];
        var _datamax = [[totalPoints,categoryPoints]];
        var _data1 = [[categoryPoints,null,totalPoints]];
        
        var _label = {
            normal: {
                show: true,
                position: 'inside',
                formatter: function(v) {
            // return (v.value / _max * 100).toFixed(0) + ' ' + v.seriesName
            if (v.value[0] > 0){
            return  v.value[0]
            }else{
                return ''
            }
        },
                textStyle: {
                    color: '#fff',
                    fontSize: 9
                }
            }
        };
        var option = {
            backgroundColor: '#fff',
            grid: {
                containLabel: true,
                left: 8,
                right: 5,
                top: 5,
                width: '100%'
            },
            tooltip: {
                show: true,
                backgroundColor: '#fff',
                borderColor: '#ddd',
                borderWidth: 1,
                textStyle: {
                    color: '#3c3c3c',
                    fontSize: 16
                },
                formatter: function(p) {
                    // var _arr = p.seriesName.split('/'),
                    // idx = p.seriesIndex;//1，2，3
                    return '<strong>' + p.seriesName + '</strong><br> Points：' + p.value[0] + '/' + p.value[2];
                },
                extraCssText: 'box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)'
            },
            xAxis: {
                splitNumber: spNum,
                interval: totalPoints / spNum,
                // max: _max,
                axisLabel: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                }
        
            },
            yAxis: [{
                data: y_data,
                axisLabel: {
                    fontSize: 10,
                    padding: [3,3,3,3],
                    backgroundColor: '#800080',
                    fontWeight: 500,
                    color: 'white',
                     formatter: function(v) {
                        // return (v + '\n '+ _datamax[0][1] +'/'+ _datamax[0][0])
                        return v + ''
                    }
        
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            }, {
                axisLabel: {
                    fontSize: 10,
                    width: '12',
                    padding: [4,16,3,3],
                    backgroundColor: scoreColor,
                    fontWeight: 500,
                    color: 'white'
        
                },
                show: true,
                data: theScore,
                axisLine: {
                    show: false
                }
            }],
            series: [{
                type: 'bar',
                silent: true,
                yAxisIndex: 1,
                barWidth: 16,
                itemStyle: {
                    normal: {
                
                        color: 'rgba(93,93,93,0.3)'
                    },
        
                },
                data: _datamax
            }, {
                type: 'bar',
                name: 'Adoption Total',
                stack: '2',
                label: _label,
                legendHoverLink: false,
                barWidth: 16,
                itemStyle: {
                    normal: {
                        color: '#c5426e'
                    },
                    emphasis: {
                 
                    }
                },
                data: _data1
            }]
        };
        return option
            }
    getTeamScoreOption(){ 
        var categoryPoints = this.props.coinInfo.info.team.devactivity + this.props.coinInfo.info.team.developers + this.props.coinInfo.info.team.foundation + this.props.coinInfo.info.team.industrybacking + this.props.coinInfo.info.team.leader;
        var totalPoints = 15;
        var scoreColor = '#B7DA62';
        if(categoryPoints/totalPoints < .34){
            scoreColor = '#D95C5C'
        }else if(categoryPoints/totalPoints < .67){
            scoreColor = '#E6BC4C'
        }
        var spNum = 15;
        
        var theScore = [`${categoryPoints}/${totalPoints}`];
        
        var y_data = ['Team'];
        var _datamax = [[totalPoints,categoryPoints]];
        var _leader = [[this.props.coinInfo.info.team.leader,null,5]];
        var _developers = [[this.props.coinInfo.info.team.developers,null,5]];
        var _industrybacking = [[this.props.coinInfo.info.team.industrybacking,null,3]];
        var _devactivity = [[this.props.coinInfo.info.team.devactivity,null,1]];
        var _foundation = [[this.props.coinInfo.info.team.foundation,null,1]];

        var _label = {
            normal: {
                show: true,
                position: 'inside',
                formatter: function(v) {
            // return (v.value / _max * 100).toFixed(0) + ' ' + v.seriesName
            if (v.value[0] > 0){
            return  v.value[0]
            }else{
                return ''
            }
        },
                textStyle: {
                    color: '#fff',
                    fontSize: 9
                }
            }
        };
        var option = {
            backgroundColor: '#fff',
            grid: {
                containLabel: true,
                left: 8,
                right: 5,
                top: 5,
                width: '100%'
            },
            tooltip: {
                show: true,
                backgroundColor: '#fff',
                borderColor: '#ddd',
                borderWidth: 1,
                textStyle: {
                    color: '#3c3c3c',
                    fontSize: 16
                },
                formatter: function(p) {
                    // var _arr = p.seriesName.split('/'),
                    // idx = p.seriesIndex;//1，2，3
                    return '<strong>' + p.seriesName + '</strong><br> Points：' + p.value[0] + '/' + p.value[2];
                },
                extraCssText: 'box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)'
            },
            xAxis: {
                splitNumber: spNum,
                interval: totalPoints / spNum,
                // max: _max,
                axisLabel: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                }
        
            },
            yAxis: [{
                data: y_data,
                axisLabel: {
                    fontSize: 10,
                    padding: [3,10,3,10],
                    backgroundColor: '#800080',
                    fontWeight: 500,
                    color: 'white',
                     formatter: function(v) {
                        // return (v + '\n '+ _datamax[0][1] +'/'+ _datamax[0][0])
                        return v + ''
                    }
        
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            }, {
                axisLabel: {
                    fontSize: 10,
                    width: '12',
                    padding: [4,16,3,3],
                    backgroundColor: scoreColor,
                    fontWeight: 500,
                    color: 'white'
        
                },
                show: true,
                data: theScore,
                axisLine: {
                    show: false
                }
            }],
            series: [{
                type: 'bar',
                silent: true,
                yAxisIndex: 1,
                barWidth: 16,
                itemStyle: {
                    normal: {
                
                        color: 'rgba(93,93,93,0.3)'
                    },
        
                },
                data: _datamax
            }, {
                type: 'bar',
                name: 'Leadership',
                stack: '2',
                label: _label,
                legendHoverLink: false,
                barWidth: 16,
                itemStyle: {
                    normal: {
                        color: '#c5426e'
                    },
                    emphasis: {
                 
                    }
                },
                data: _leader
            }, {
                type: 'bar',
                name: 'Dev Ecosystem',
                stack: '2',
                legendHoverLink: false,
                barWidth: 16,
                label: _label,
                itemStyle: {
                    normal: {
                        color: '#ba3771'
                    },
                    emphasis: {
              
                    }
                },
                data: _developers
            }, {
                type: 'bar',
                stack: '2',
                name: 'Industry Backing',
                legendHoverLink: false,
                barWidth: 16,
                label: _label,
                itemStyle: {
                    normal: {
                        color: '#a82775'
                    }
                },
                data: _industrybacking
            },
             {
                type: 'bar',
                stack: '2',
                name: 'Dev Activity',
                legendHoverLink: false,
                barWidth: 16,
                label: _label,
                itemStyle: {
                    normal: {
                        color: '#97167a'
                    }
                },
                data: _devactivity
            },
            {
               type: 'bar',
               stack: '2',
               name: 'Foundation',
               legendHoverLink: false,
               barWidth: 16,
               label: _label,
               itemStyle: {
                   normal: {
                       color: '#800080'
                   }
               },
               data: _foundation
           }]
        };
        return option
            }
    getSocialScoreOption(){ 
                var categoryPoints = this.props.coinInfo.info.social.activity + this.props.coinInfo.info.social.age + this.props.coinInfo.info.social.coverage + this.props.coinInfo.info.social.cult + this.props.coinInfo.info.social.launch + this.props.coinInfo.info.social.reputation;
                var totalPoints = 15;
                var scoreColor = '#B7DA62';
                if(categoryPoints/totalPoints < .34){
                    scoreColor = '#D95C5C'
                }else if(categoryPoints/totalPoints < .67){
                    scoreColor = '#E6BC4C'
                }
                var spNum = 15;
                
                var theScore = [`${categoryPoints}/${totalPoints}`];
                
                var y_data = ['Social'];
                var _datamax = [[totalPoints,categoryPoints]];
                var _activity = [[this.props.coinInfo.info.social.activity,null,3]];
                var _age = [[this.props.coinInfo.info.social.age,null,3]];
                var _coverage = [[this.props.coinInfo.info.social.coverage,null,3]];
                var _reputation = [[this.props.coinInfo.info.social.reputation,null,3]];
                var _launch = [[this.props.coinInfo.info.social.launch,null,2]];
                var _cult = [[this.props.coinInfo.info.social.cult,null,1]];
             
                
                var _label = {
                    normal: {
                        show: true,
                        position: 'inside',
                        formatter: function(v) {
                    // return (v.value / _max * 100).toFixed(0) + ' ' + v.seriesName
                    if (v.value[0] > 0){
                    return  v.value[0]
                    }else{
                        return ''
                    }
                },
                        textStyle: {
                            color: '#fff',
                            fontSize: 9
                        }
                    }
                };
                var option = {
                    backgroundColor: '#fff',
                    grid: {
                        containLabel: true,
                        left: 8,
                        right: 5,
                        top: 5,
                        width: '100%'
                    },
                    tooltip: {
                        show: true,
                        backgroundColor: '#fff',
                        borderColor: '#ddd',
                        borderWidth: 1,
                        textStyle: {
                            color: '#3c3c3c',
                            fontSize: 16
                        },
                        formatter: function(p) {
                            // var _arr = p.seriesName.split('/'),
                            // idx = p.seriesIndex;//1，2，3
                            return '<strong>' + p.seriesName + '</strong><br> Points：' + p.value[0] + '/' + p.value[2];
                        },
                        extraCssText: 'box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)'
                    },
                    xAxis: {
                        splitNumber: spNum,
                        interval: totalPoints / spNum,
                        // max: _max,
                        axisLabel: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        }
                
                    },
                    yAxis: [{
                        data: y_data,
                        axisLabel: {
                            fontSize: 10,
                            padding: [3,9,3,9],
                            backgroundColor: '#800080',
                            fontWeight: 500,
                            color: 'white',
                             formatter: function(v) {
                                // return (v + '\n '+ _datamax[0][1] +'/'+ _datamax[0][0])
                                return v + ''
                            }
                
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        }
                    }, {
                        axisLabel: {
                            fontSize: 10,
                            width: '12',
                            padding: [4,16,3,3],
                            backgroundColor: scoreColor,
                            fontWeight: 500,
                            color: 'white'
                
                        },
                        show: true,
                        data: theScore,
                        axisLine: {
                            show: false
                        }
                    }],
                    series: [{
                        type: 'bar',
                        silent: true,
                        yAxisIndex: 1,
                        barWidth: 16,
                        itemStyle: {
                            normal: {
                        
                                color: 'rgba(93,93,93,0.3)'
                            },
                
                        },
                        data: _datamax
                    }, {
                        type: 'bar',
                        name: 'Community Activity',
                        stack: '2',
                        label: _label,
                        legendHoverLink: false,
                        barWidth: 16,
                        itemStyle: {
                            normal: {
                                color: '#c5426e'
                            },
                            emphasis: {
                         
                            }
                        },
                        data: _activity
                    }, {
                        type: 'bar',
                        name: 'Project Age',
                        stack: '2',
                        legendHoverLink: false,
                        barWidth: 16,
                        label: _label,
                        itemStyle: {
                            normal: {
                                color: '#ba3771'
                            },
                            emphasis: {
                      
                            }
                        },
                        data: _age
                    }, {
                        type: 'bar',
                        stack: '2',
                        name: 'Press Coverage',
                        legendHoverLink: false,
                        barWidth: 16,
                        label: _label,
                        itemStyle: {
                            normal: {
                                color: '#ae2c74'
                            }
                        },
                        data: _coverage
                    },
                     {
                        type: 'bar',
                        stack: '2',
                        name: 'Reputation',
                        legendHoverLink: false,
                        barWidth: 16,
                        label: _label,
                        itemStyle: {
                            normal: {
                                color: '#a22177'
                            }
                        },
                        data: _reputation
                    },
                    {
                       type: 'bar',
                       stack: '2',
                       name: 'Launch Fairness',
                       legendHoverLink: false,
                       barWidth: 16,
                       label: _label,
                       itemStyle: {
                           normal: {
                               color: '#91117b'
                           }
                       },
                       data: _launch
                   },
                   {
                      type: 'bar',
                      stack: '2',
                      name: 'Cult Following',
                      legendHoverLink: false,
                      barWidth: 16,
                      label: _label,
                      itemStyle: {
                          normal: {
                              color: '#800080'
                          }
                      },
                      data: _cult
                  }]
                };
                return option
                    }
    getFinancialsScoreOption(){ 
                        var categoryPoints = this.props.coinInfo.info.financials.distribution + this.props.coinInfo.info.financials.funding + this.props.coinInfo.info.financials.inflation + this.props.coinInfo.info.financials.liquidity + this.props.coinInfo.info.financials.listings + this.props.coinInfo.info.financials.security;
                        var totalPoints = 20;
                        var scoreColor = '#B7DA62';
                        if(categoryPoints/totalPoints < .34){
                            scoreColor = '#D95C5C'
                        }else if(categoryPoints/totalPoints < .67){
                            scoreColor = '#E6BC4C'
                        }
                        var spNum = 15;
                        
                        var theScore = [`${categoryPoints}/${totalPoints}`];
                        
                        var y_data = ['Financials'];
                        var _datamax = [[totalPoints,categoryPoints]];
                        var _inflation = [[this.props.coinInfo.info.financials.inflation,null,5]];
                        var _liquidity = [[this.props.coinInfo.info.financials.liquidity,null,5]];
                        var _distribution = [[this.props.coinInfo.info.financials.distribution,null,4]];
                        var _security = [[this.props.coinInfo.info.financials.security,null,3]];
                        var _listings= [[this.props.coinInfo.info.financials.listings,null,2]];
                        var _funding = [[this.props.coinInfo.info.financials.funding,null,1]];
             
                        
                        var _label = {
                            normal: {
                                show: true,
                                position: 'inside',
                                formatter: function(v) {
                            // return (v.value / _max * 100).toFixed(0) + ' ' + v.seriesName
                            if (v.value[0] > 0){
                            return  v.value[0]
                            }else{
                                return ''
                            }
                        },
                                textStyle: {
                                    color: '#fff',
                                    fontSize: 9
                                }
                            }
                        };
                        var option = {
                            backgroundColor: '#fff',
                            grid: {
                                containLabel: true,
                                left: 8,
                                right: 5,
                                top: 5,
                                width: '100%'
                            },
                            tooltip: {
                                show: true,
                                backgroundColor: '#fff',
                                borderColor: '#ddd',
                                borderWidth: 1,
                                textStyle: {
                                    color: '#3c3c3c',
                                    fontSize: 16
                                },
                                formatter: function(p) {
                                    // var _arr = p.seriesName.split('/'),
                                    // idx = p.seriesIndex;//1，2，3
                                    return '<strong>' + p.seriesName + '</strong><br> Points：' + p.value[0] + '/' + p.value[2];
                                },
                                extraCssText: 'box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)'
                            },
                            xAxis: {
                                splitNumber: spNum,
                                interval: totalPoints / spNum,
                                // max: _max,
                                axisLabel: {
                                    show: false
                                },
                                axisLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                splitLine: {
                                    show: false
                                }
                        
                            },
                            yAxis: [{
                                data: y_data,
                                axisLabel: {
                                    fontSize: 10,
                                    padding: [3,3,3,3],
                                    backgroundColor: '#800080',
                                    fontWeight: 500,
                                    color: 'white',
                                     formatter: function(v) {
                                        // return (v + '\n '+ _datamax[0][1] +'/'+ _datamax[0][0])
                                        return v + ''
                                    }
                        
                                },
                                axisLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                splitLine: {
                                    show: false
                                }
                            }, {
                                axisLabel: {
                                    fontSize: 10,
                                    width: '12',
                                    padding: [4,16,3,3],
                                    backgroundColor: scoreColor,
                                    fontWeight: 500,
                                    color: 'white'
                        
                                },
                                show: true,
                                data: theScore,
                                axisLine: {
                                    show: false
                                }
                            }],
                            series: [{
                                type: 'bar',
                                silent: true,
                                yAxisIndex: 1,
                                barWidth: 16,
                                itemStyle: {
                                    normal: {
                                
                                        color: 'rgba(93,93,93,0.3)'
                                    },
                        
                                },
                                data: _datamax
                            }, {
                                type: 'bar',
                                name: 'Inflation',
                                stack: '2',
                                label: _label,
                                legendHoverLink: false,
                                barWidth: 16,
                                itemStyle: {
                                    normal: {
                                        color: '#c5426e'
                                    },
                                    emphasis: {
                                 
                                    }
                                },
                                data: _inflation
                            }, {
                                type: 'bar',
                                name: 'Liquidity',
                                stack: '2',
                                legendHoverLink: false,
                                barWidth: 16,
                                label: _label,
                                itemStyle: {
                                    normal: {
                                        color: '#ba3771'
                                    },
                                    emphasis: {
                              
                                    }
                                },
                                data: _liquidity
                            }, {
                                type: 'bar',
                                stack: '2',
                                name: 'Distribution',
                                legendHoverLink: false,
                                barWidth: 16,
                                label: _label,
                                itemStyle: {
                                    normal: {
                                        color: '#ae2c74'
                                    }
                                },
                                data: _distribution
                            },
                             {
                                type: 'bar',
                                stack: '2',
                                name: 'Security?',
                                legendHoverLink: false,
                                barWidth: 16,
                                label: _label,
                                itemStyle: {
                                    normal: {
                                        color: '#a22177'
                                    }
                                },
                                data: _security
                            },
                            {
                               type: 'bar',
                               stack: '2',
                               name: 'Exchange Listings',
                               legendHoverLink: false,
                               barWidth: 16,
                               label: _label,
                               itemStyle: {
                                   normal: {
                                       color: '#91117b'
                                   }
                               },
                               data: _listings
                           },
                           {
                              type: 'bar',
                              stack: '2',
                              name: 'Funding',
                              legendHoverLink: false,
                              barWidth: 16,
                              label: _label,
                              itemStyle: {
                                  normal: {
                                      color: '#800080'
                                  }
                              },
                              data: _funding
                          }]
                        };
                        return option
                            }

    getTechScoreOption(){ 
                                var categoryPoints = this.props.coinInfo.info.tech.bughack + this.props.coinInfo.info.tech.decentralized + this.props.coinInfo.info.tech.functional + this.props.coinInfo.info.tech.mainnet + this.props.coinInfo.info.tech.scaling + this.props.coinInfo.info.tech.secure + this.props.coinInfo.info.tech.testnet + this.props.coinInfo.info.tech.unique + this.props.coinInfo.info.tech.wallets + this.props.coinInfo.info.tech.whitepaper;
                                var totalPoints = 20;
                                var scoreColor = '#B7DA62';
                                if(categoryPoints/totalPoints < .34){
                                    scoreColor = '#D95C5C'
                                }else if(categoryPoints/totalPoints < .67){
                                    scoreColor = '#E6BC4C'
                                }
                                var spNum = 15;
                                
                                var theScore = [`${categoryPoints}/${totalPoints}`];
                                
                                var y_data = ['Tech'];
                                var _datamax = [[totalPoints,categoryPoints]];
                                var _bughack = [[this.props.coinInfo.info.tech.bughack,null,3]];
                                var _functional = [[this.props.coinInfo.info.tech.functional,null,3]];
                                var _decentralized = [[this.props.coinInfo.info.tech.decentralized,null,2]];
                                var _mainnet = [[this.props.coinInfo.info.tech.mainnet,null,2]];
                                var _scaling = [[this.props.coinInfo.info.tech.scaling,null,2]];
                                var _secure = [[this.props.coinInfo.info.tech.secure,null,2]];
                                var _unique = [[this.props.coinInfo.info.tech.unique,null,2]];
                                var _wallets = [[this.props.coinInfo.info.tech.wallets,null,2]];
                                var _testnet = [[this.props.coinInfo.info.tech.testnet,null,1]];
                                var _whitepaper = [[this.props.coinInfo.info.tech.whitepaper,null,1]];
                                
                                var _label = {
                                    normal: {
                                        show: true,
                                        position: 'inside',
                                        formatter: function(v) {
                                    // return (v.value / _max * 100).toFixed(0) + ' ' + v.seriesName
                                    if (v.value[0] > 0){
                                    return  v.value[0]
                                    }else{
                                        return ''
                                    }
                                },
                                        textStyle: {
                                            color: '#fff',
                                            fontSize: 9
                                        }
                                    }
                                };
                                var option = {
                                    backgroundColor: '#fff',
                                    grid: {
                                        containLabel: true,
                                        left: 8,
                                        right: 5,
                                        top: 5,
                                        width: '100%'
                                    },
                                    tooltip: {
                                        show: true,
                                        backgroundColor: '#fff',
                                        borderColor: '#ddd',
                                        borderWidth: 1,
                                        textStyle: {
                                            color: '#3c3c3c',
                                            fontSize: 16
                                        },
                                        formatter: function(p) {
                                            // var _arr = p.seriesName.split('/'),
                                            // idx = p.seriesIndex;//1，2，3
                                            return '<strong>' + p.seriesName + '</strong><br> Points：' + p.value[0] + '/' + p.value[2];
                                        },
                                        extraCssText: 'box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)'
                                    },
                                    xAxis: {
                                        splitNumber: spNum,
                                        interval: totalPoints / spNum,
                                        // max: _max,
                                        axisLabel: {
                                            show: false
                                        },
                                        axisLine: {
                                            show: false
                                        },
                                        axisTick: {
                                            show: false
                                        },
                                        splitLine: {
                                            show: false
                                        }
                                
                                    },
                                    yAxis: [{
                                        data: y_data,
                                        axisLabel: {
                                            fontSize: 10,
                                            padding: [3,13,3,13],
                                            backgroundColor: '#800080',
                                            fontWeight: 500,
                                            color: 'white',
                                             formatter: function(v) {
                                                // return (v + '\n '+ _datamax[0][1] +'/'+ _datamax[0][0])
                                                return v
                                            }
                                
                                        },
                                        axisLine: {
                                            show: false
                                        },
                                        axisTick: {
                                            show: false
                                        },
                                        splitLine: {
                                            show: false
                                        }
                                    }, {
                                        axisLabel: {
                                            fontSize: 10,
                                            width: '12',
                                            padding: [4,16,3,3],
                                            backgroundColor: scoreColor,
                                            fontWeight: 500,
                                            color: 'white'
                                
                                        },
                                        show: true,
                                        data: theScore,
                                        axisLine: {
                                            show: false
                                        }
                                    }],
                                    series: [{
                                        type: 'bar',
                                        silent: true,
                                        yAxisIndex: 1,
                                        barWidth: 16,
                                        itemStyle: {
                                            normal: {
                                        
                                                color: 'rgba(93,93,93,0.3)'
                                            },
                                
                                        },
                                        data: _datamax
                                    }, {
                                        type: 'bar',
                                        name: 'Whitepaper',
                                        stack: '2',
                                        label: _label,
                                        legendHoverLink: false,
                                        barWidth: 16,
                                        itemStyle: {
                                            normal: {
                                                color: '#c5426e'
                                            },
                                            emphasis: {
                                         
                                            }
                                        },
                                        data: _whitepaper
                                    }, {
                                        type: 'bar',
                                        name: 'Testnet',
                                        stack: '2',
                                        legendHoverLink: false,
                                        barWidth: 16,
                                        label: _label,
                                        itemStyle: {
                                            normal: {
                                                color: '#bf3c6f'
                                            },
                                            emphasis: {
                                      
                                            }
                                        },
                                        data: _testnet
                                    }, {
                                        type: 'bar',
                                        stack: '2',
                                        name: 'Mainnet',
                                        legendHoverLink: false,
                                        barWidth: 16,
                                        label: _label,
                                        itemStyle: {
                                            normal: {
                                                color: '#ba3771'
                                            }
                                        },
                                        data: _mainnet
                                    },
                                     {
                                        type: 'bar',
                                        stack: '2',
                                        name: 'Wallet Support',
                                        legendHoverLink: false,
                                        barWidth: 16,
                                        label: _label,
                                        itemStyle: {
                                            normal: {
                                                color: '#ae2c74'
                                            }
                                        },
                                        data: _wallets
                                    },
                                    {
                                        type: 'bar',
                                        name: 'Bugs / Hacks',
                                        stack: '2',
                                        label: _label,
                                        legendHoverLink: false,
                                        barWidth: 16,
                                        itemStyle: {
                                            normal: {
                                                color: '#a82775'
                                            },
                                            emphasis: {
                                         
                                            }
                                        },
                                        data: _bughack
                                    }, {
                                        type: 'bar',
                                        name: 'Unique Tech',
                                        stack: '2',
                                        legendHoverLink: false,
                                        barWidth: 16,
                                        label: _label,
                                        itemStyle: {
                                            normal: {
                                                color: '#9d1b78'
                                            },
                                            emphasis: {
                                      
                                            }
                                        },
                                        data: _unique
                                    }, {
                                        type: 'bar',
                                        stack: '2',
                                        name: 'Decentralized',
                                        legendHoverLink: false,
                                        barWidth: 16,
                                        label: _label,
                                        itemStyle: {
                                            normal: {
                                                color: '#91117b'
                                            }
                                        },
                                        data: _decentralized
                                    },
                                     {
                                        type: 'bar',
                                        stack: '2',
                                        name: 'Secure',
                                        legendHoverLink: false,
                                        barWidth: 16,
                                        label: _label,
                                        itemStyle: {
                                            normal: {
                                                color: '#8b0b7d'
                                            }
                                        },
                                        data: _secure
                                    },
                                    {
                                       type: 'bar',
                                       stack: '2',
                                       name: 'Scaling',
                                       legendHoverLink: false,
                                       barWidth: 16,
                                       label: _label,
                                       itemStyle: {
                                           normal: {
                                               color: '#86057e'
                                           }
                                       },
                                       data: _scaling
                                   },
                                   {
                                      type: 'bar',
                                      stack: '2',
                                      name: 'Fully Functional',
                                      legendHoverLink: false,
                                      barWidth: 16,
                                      label: _label,
                                      itemStyle: {
                                          normal: {
                                              color: '#800080'
                                          }
                                      },
                                      data: _functional
                                  }]
                                };
                                return option
                                    }
    
    getMarketScoreOption(){ 
                                        var categoryPoints = this.props.coinInfo.info.market.marketleader + this.props.coinInfo.info.market.scope + this.props.coinInfo.info.market.utility + this.props.coinInfo.info.market.blockchain + this.props.coinInfo.info.market.staking + this.props.coinInfo.info.market.interoperability;
                                        var totalPoints = 10;
                                        var scoreColor = '#B7DA62';
                                        if(categoryPoints/totalPoints < .34){
                                            scoreColor = '#D95C5C'
                                        }else if(categoryPoints/totalPoints < .67){
                                            scoreColor = '#E6BC4C'
                                        }
                                        var spNum = 15;
                                        
                                        var theScore = [`${categoryPoints}/${totalPoints}`];
                                        
                                        var y_data = ['Market'];
                                        var _datamax = [[totalPoints,categoryPoints]];
                                        var _marketleader = [[this.props.coinInfo.info.market.marketleader,null,3]];
                                        var _scope = [[this.props.coinInfo.info.market.scope,null,2]];
                                        var _utility = [[this.props.coinInfo.info.market.utility,null,2]];
                                        var _blockchain = [[this.props.coinInfo.info.market.blockchain,null,1]];
                                        var _staking = [[this.props.coinInfo.info.market.staking,null,1]];
                                        var _interoperability = [[this.props.coinInfo.info.market.interoperability,null,1]];
                                        
                                        var _label = {
                                            normal: {
                                                show: true,
                                                position: 'inside',
                                                formatter: function(v) {
                                            // return (v.value / _max * 100).toFixed(0) + ' ' + v.seriesName
                                            if (v.value[0] > 0){
                                            return  v.value[0]
                                            }else{
                                                return ''
                                            }
                                        },
                                                textStyle: {
                                                    color: '#fff',
                                                    fontSize: 9
                                                }
                                            }
                                        };
                                        var option = {
                                            backgroundColor: '#fff',
                                            grid: {
                                                containLabel: true,
                                                left: 8,
                                                right: 5,
                                                top: 5,
                                                width: '100%'
                                            },
                                            tooltip: {
                                                show: true,
                                                backgroundColor: '#fff',
                                                borderColor: '#ddd',
                                                borderWidth: 1,
                                                textStyle: {
                                                    color: '#3c3c3c',
                                                    fontSize: 16
                                                },
                                                formatter: function(p) {
                                                    // var _arr = p.seriesName.split('/'),
                                                    // idx = p.seriesIndex;//1，2，3
                                                
                                                  return  '<strong>' + p.seriesName + '</strong><br> Points：' + p.value[0] + '/' + p.value[2];
                                                },
                                                extraCssText: 'box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)'
                                            },
                                            xAxis: {
                                                splitNumber: spNum,
                                                interval: totalPoints / spNum,
                                                // max: _max,
                                                axisLabel: {
                                                    show: false
                                                },
                                                axisLine: {
                                                    show: false
                                                },
                                                axisTick: {
                                                    show: false
                                                },
                                                splitLine: {
                                                    show: false
                                                }
                                        
                                            },
                                            yAxis: [{
                                                data: y_data,
                                                axisLabel: {
                                                    fontSize: 10,
                                                    padding: [3,8,3,8],
                                                    backgroundColor: '#800080',
                                                    fontWeight: 500,
                                                    color: 'white',
                                                     formatter: function(v) {
                                                        // return (v + '\n '+ _datamax[0][1] +'/'+ _datamax[0][0])
                                                        return v + ''
                                                    }
                                        
                                                },
                                                axisLine: {
                                                    show: false
                                                },
                                                axisTick: {
                                                    show: false
                                                },
                                                splitLine: {
                                                    show: false
                                                }
                                            }, {
                                                axisLabel: {
                                                    fontSize: 10,
                                                    width: '12',
                                                    padding: [4,16,3,3],
                                                    backgroundColor: scoreColor,
                                                    fontWeight: 500,
                                                    color: 'white'
                                        
                                                },
                                                show: true,
                                                data: theScore,
                                                axisLine: {
                                                    show: false
                                                }
                                            }],
                                            series: [{
                                                type: 'bar',
                                                silent: true,
                                                yAxisIndex: 1,
                                                barWidth: 16,
                                                itemStyle: {
                                                    normal: {
                                                
                                                        color: 'rgba(93,93,93,0.3)'
                                                    },
                                        
                                                },
                                                data: _datamax
                                            }, {
                                                type: 'bar',
                                                name: 'Sector Position',
                                                stack: '2',
                                                label: _label,
                                                legendHoverLink: false,
                                                barWidth: 16,
                                                itemStyle: {
                                                    normal: {
                                                        color: '#c5426e'
                                                    },
                                                    emphasis: {
                                                 
                                                    }
                                                },
                                                data: _marketleader
                                            }, {
                                                type: 'bar',
                                                name: 'Project Scope',
                                                stack: '2',
                                                legendHoverLink: false,
                                                barWidth: 16,
                                                label: _label,
                                                itemStyle: {
                                                    normal: {
                                                        color: '#ba3771'
                                                    },
                                                    emphasis: {
                                              
                                                    }
                                                },
                                                data: _scope
                                            }, {
                                                type: 'bar',
                                                stack: '2',
                                                name: 'Token Utility',
                                                legendHoverLink: false,
                                                barWidth: 16,
                                                label: _label,
                                                itemStyle: {
                                                    normal: {
                                                        color: '#ae2c74'
                                                    }
                                                },
                                                data: _utility
                                            },
                                             {
                                                type: 'bar',
                                                stack: '2',
                                                name: 'Need Blockchain?',
                                                legendHoverLink: false,
                                                barWidth: 16,
                                                label: _label,
                                                itemStyle: {
                                                    normal: {
                                                        color: '#a22177'
                                                    }
                                                },
                                                data: _blockchain
                                            },
                                            {
                                               type: 'bar',
                                               stack: '2',
                                               name: 'Staking?',
                                               legendHoverLink: false,
                                               barWidth: 16,
                                               label: _label,
                                               itemStyle: {
                                                   normal: {
                                                       color: '#91117b'
                                                   }
                                               },
                                               data: _staking
                                           },
                                           {
                                              type: 'bar',
                                              stack: '2',
                                              name: 'Interoperability?',
                                              legendHoverLink: false,
                                              barWidth: 16,
                                              label: _label,
                                              itemStyle: {
                                                  normal: {
                                                      color: '#800080'
                                                  }
                                              },
                                              data: _interoperability
                                          }]
                                        };
                                        return option
                                            }
                                        

                                    
    getScoreOption(){ 
                                var spNum = 3;
                                var _max=15;
                                
                                // var legendData = ['Factor A', 'Factor B', 'Factor C','Factor D'];
                                var y_data = ['Adoption'];
                                var _datamax = [[15,10]];
                                var _data1 = [[5,5]];
                                var _data2 = [[1,5]];
                                var _data3 = [[3,5]];
                                var _data4 = [[2,5]];
                                
                                var _label = {
                                    normal: {
                                        show: true,
                                        position: 'inside',
                                        formatter: function(v) {
                                    // return (v.value / _max * 100).toFixed(0) + ' ' + v.seriesName
                                    if (v.value[0] > 0){
                                    return  v.value[0]
                                    }else{
                                        return ''
                                    }
                                },
                                        textStyle: {
                                            color: '#fff',
                                            fontSize: 9
                                        }
                                    }
                                };
                                var option = {
                                    backgroundColor: '#fff',
                                    grid: {
                                        containLabel: true,
                                        left: 8,
                                        right: 5,
                                        top: 5
                                    },
                                    tooltip: {
                                        show: true,
                                        backgroundColor: '#fff',
                                        borderColor: '#ddd',
                                        borderWidth: 1,
                                        textStyle: {
                                            color: '#3c3c3c',
                                            fontSize: 16
                                        },
                                        formatter: function(p) {
                                            // var _arr = p.seriesName.split('/'),
                                            // idx = p.seriesIndex;//1，2，3
                                            return p.seriesName + '<br> Points：' + p.value[0] + '<br> Max Points：' + p.value[1];
                                        },
                                        extraCssText: 'box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)'
                                    },
                                    xAxis: {
                                        splitNumber: spNum,
                                        interval: _max / spNum,
                                        max: _max,
                                        axisLabel: {
                                            show: false
                                        },
                                        axisLine: {
                                            show: false
                                        },
                                        axisTick: {
                                            show: false
                                        },
                                        splitLine: {
                                            show: false
                                        }
                                
                                    },
                                    yAxis: [{
                                        data: y_data,
                                        axisLabel: {
                                            interval: 0,
                                            fontSize: 10,
                                            padding: [3,3,3,3],
                                            backgroundColor: '#800080',
                                            fontWeight: 500,
                                            color: 'white',
                                             formatter: function(v) {
                                                // return (v + '\n '+ _datamax[0][1] +'/'+ _datamax[0][0])
                                                return v + ' ' + _datamax[0][1] +'/'+ _datamax[0][0]
                                            }
                                
                                        },
                                        axisLine: {
                                            show: false
                                        },
                                        axisTick: {
                                            show: false
                                        },
                                        splitLine: {
                                            show: false
                                        }
                                    }, {
                                        show: false,
                                        data: y_data,
                                        axisLine: {
                                            show: false
                                        }
                                    }],
                                    series: [{
                                        type: 'bar',
                                        silent: true,
                                        yAxisIndex: 1,
                                        barWidth: 16,
                                        itemStyle: {
                                            normal: {
                                        
                                                color: 'rgba(93,93,93,0.3)'
                                            },
                                
                                        },
                                        data: _datamax
                                    }, {
                                        type: 'bar',
                                        name: 'Factor A',
                                        stack: '2',
                                        label: _label,
                                        legendHoverLink: false,
                                        barWidth: 16,
                                        itemStyle: {
                                            normal: {
                                                color: '#c5426e'
                                            },
                                            emphasis: {
                                         
                                            }
                                        },
                                        data: _data1
                                    }, {
                                        type: 'bar',
                                        name: 'Factor B',
                                        stack: '2',
                                        legendHoverLink: false,
                                        barWidth: 16,
                                        label: _label,
                                        itemStyle: {
                                            normal: {
                                                color: '#b43172'
                                            },
                                            emphasis: {
                                      
                                            }
                                        },
                                        data: _data2
                                    }, {
                                        type: 'bar',
                                        stack: '2',
                                        name: 'Factor C',
                                        legendHoverLink: false,
                                        barWidth: 16,
                                        label: _label,
                                        itemStyle: {
                                            normal: {
                                                color: '#a22177'
                                            }
                                        },
                                        data: _data3
                                    },
                                     {
                                        type: 'bar',
                                        stack: '2',
                                        name: 'Factor D',
                                        legendHoverLink: false,
                                        barWidth: 16,
                                        label: _label,
                                        itemStyle: {
                                            normal: {
                                                color: '#800080'
                                            }
                                        },
                                        data: _data4
                                    }]
                                };
                                return option
                                    }
                                
    getOption(){ 
        let financialsScore = this.props.coinInfo.info.financials.distribution + this.props.coinInfo.info.financials.funding + this.props.coinInfo.info.financials.inflation + this.props.coinInfo.info.financials.liquidity + this.props.coinInfo.info.financials.listings + this.props.coinInfo.info.financials.security;
        let teamScore = this.props.coinInfo.info.team.devactivity + this.props.coinInfo.info.team.developers + this.props.coinInfo.info.team.foundation + this.props.coinInfo.info.team.industrybacking + this.props.coinInfo.info.team.leader;
        let marketScore = this.props.coinInfo.info.market.marketleader + this.props.coinInfo.info.market.scope + this.props.coinInfo.info.market.utility + this.props.coinInfo.info.market.blockchain + this.props.coinInfo.info.market.staking + this.props.coinInfo.info.market.interoperability;
        let techScore = this.props.coinInfo.info.tech.bughack + this.props.coinInfo.info.tech.decentralized + this.props.coinInfo.info.tech.functional + this.props.coinInfo.info.tech.mainnet + this.props.coinInfo.info.tech.scaling + this.props.coinInfo.info.tech.secure + this.props.coinInfo.info.tech.testnet + this.props.coinInfo.info.tech.unique + this.props.coinInfo.info.tech.wallets + this.props.coinInfo.info.tech.whitepaper;
        let adoptionScore = this.props.coinInfo.info.adoption;
        let socialScore = this.props.coinInfo.info.social.activity + this.props.coinInfo.info.social.age + this.props.coinInfo.info.social.coverage + this.props.coinInfo.info.social.cult + this.props.coinInfo.info.social.launch + this.props.coinInfo.info.social.reputation;
        let coinName = this.props.coinInfo.coinName;
    
    
        var option = {
            // title: {
            //     text: '雷达图'
            // },
            tooltip: {
                show: true,
                backgroundColor: '#fff',
                borderColor: '#ddd',
                borderWidth: 1,
                textStyle: {
                    color: '#3c3c3c',
                    fontSize: 16
                },
                formatter: function(p) {
                    var total = p.value[0] + p.value[1] + p.value[2] + p.value[3] + p.value[4] + p.value[5];
                    return '<strong>' + p.name + '</strong><br>Adoption: ' + p.value[0] +'/20<br> Team: ' + p.value[1] + '/15<br> Social: ' + p.value[2] + '/15<br> Financials: '+ p.value[3] + '/20<br> Tech: '+ p.value[4] + '/20<br> Market: '+ p.value[5] + '/10<br><p style="color: #fff; padding: 2px; background-color: #C5426E "> TFscore™: ' + total + '</p>';
                },
                extraCssText: 'box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)'
            },
            legend: {
                bottom: 5,
                itemWidth: 12,
                itemHeight: 12,
                data: [coinName],
                // data: ['Ethereum', 'EOS'],
                textStyle: {
                    color: '#000'
                }
            },
            radar: {
                radius: '60%',
                splitNumber: 8,
                axisLine: {
                    lineStyle: {
                        color: '#000',
                        opacity: .2
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: '#fff',
                        opacity: .2
                    }
                },
                splitArea: {
                    areaStyle: {
                        color: 'rgba(127,95,132,.3)',
                        opacity: 1,
                        shadowBlur: 45,
                        shadowColor: 'rgba(0,0,0,.5)',
                        shadowOffsetX: 0,
                        shadowOffsetY: 15,
                    }
                },
                indicator: [{
                    name: 'Adoption',
                    max: 20
                }, {
                    name: 'Team',
                    max: 15
                }, {
                    name: 'Social',
                    max: 15
                }, {
                    name: 'Financials',
                    max: 20
                }, {
                    name: 'Tech',
                    max: 20
                }, {
                    name: 'Market',
                    max: 10
                }]
            },
            series: [{
                name: 'TFscore™',
                type: 'radar',
                symbolSize: 0,
                areaStyle: {
                    normal: {
                        shadowBlur: 13,
                        shadowColor: 'rgba(0,0,0,.2)',
                        shadowOffsetX: 0,
                        shadowOffsetY: 10,
                        opacity: 1
                    }
                },
                data: [{
                    value: [adoptionScore, teamScore, socialScore, financialsScore, techScore, marketScore ],
                    name: coinName,
                }
                // , {
                //     value: [2500, 12000, 8000, 8500, 12000, 12000],
                //     name: 'EOS',
                // }
            ]
            }],
            color: ['#800080', '#b1eadb'],
            // backgroundColor: {
            //     type: 'radial',
            //     x: 0.4,
            //     y: 0.4,
            //     r: 0.35,
            //     colorStops: [{
            //         offset: 0,
            //         color: '#895355' // 0% 处的颜色
            //     }, {
            //         offset: .4,
            //         color: '#593640' // 100% 处的颜色
            //     }, {
            //         offset: 1,
            //         color: '#39273d' // 100% 处的颜色
            //     }],
            //     globalCoord: false // 缺省为 false
            // }
        };
      
        
        return option
      }
      getOptionComparison(){ 
        // let chosenComparisonData = [];
        // let vsliquidityScore = 1;
        // let vsteamScore = 1;
        // let vsvaluationScore = 1;
        // let vsprogressScore = 1;
        // let vsadoptionScore = 1;
        // let vssocialScore = 1;
        // let vscoinName = 1;

        // if (this.props.coinScores && this.props.chosenComparison !== false){ }
    
           let theSymbol = this.props.chosenComparison;
            let chosenComparisonData = this.props.coinScores.filter(function(data) {return data.value === theSymbol});
            let vsfinancialsScore = chosenComparisonData[0].score.financials;
            let vsteamScore = chosenComparisonData[0].score.team;
            let vsmarketScore = chosenComparisonData[0].score.market;
            let vstechScore = chosenComparisonData[0].score.tech;
            let vsadoptionScore = chosenComparisonData[0].score.adoption;
            let vssocialScore = chosenComparisonData[0].score.social;
            let vscoinName = chosenComparisonData[0].value;
        

        let financialsScore = this.props.coinInfo.info.financials.distribution + this.props.coinInfo.info.financials.funding + this.props.coinInfo.info.financials.inflation + this.props.coinInfo.info.financials.liquidity + this.props.coinInfo.info.financials.listings + this.props.coinInfo.info.financials.security;
        let teamScore = this.props.coinInfo.info.team.devactivity + this.props.coinInfo.info.team.developers + this.props.coinInfo.info.team.foundation + this.props.coinInfo.info.team.industrybacking + this.props.coinInfo.info.team.leader;
        let marketScore = this.props.coinInfo.info.market.marketleader + this.props.coinInfo.info.market.scope + this.props.coinInfo.info.market.utility + this.props.coinInfo.info.market.blockchain + this.props.coinInfo.info.market.staking + this.props.coinInfo.info.market.interoperability;
        let techScore = this.props.coinInfo.info.tech.bughack + this.props.coinInfo.info.tech.decentralized + this.props.coinInfo.info.tech.functional + this.props.coinInfo.info.tech.mainnet + this.props.coinInfo.info.tech.scaling + this.props.coinInfo.info.tech.secure + this.props.coinInfo.info.tech.testnet + this.props.coinInfo.info.tech.unique + this.props.coinInfo.info.tech.wallets + this.props.coinInfo.info.tech.whitepaper;
        let adoptionScore = this.props.coinInfo.info.adoption;
        let socialScore = this.props.coinInfo.info.social.activity + this.props.coinInfo.info.social.age + this.props.coinInfo.info.social.coverage + this.props.coinInfo.info.social.cult + this.props.coinInfo.info.social.launch + this.props.coinInfo.info.social.reputation;
        let coinName = this.props.coinInfo.coinName;

    
    
        var option = {
            // title: {
            //     text: '雷达图'
            // },
            tooltip: {
                show: true,
                backgroundColor: '#fff',
                borderColor: '#ddd',
                borderWidth: 1,
                textStyle: {
                    color: '#3c3c3c',
                    fontSize: 16
                },
                formatter: function(p) {
                    var total = p.value[0] + p.value[1] + p.value[2] + p.value[3] + p.value[4] + p.value[5];
                    return '<strong>' + p.name + '</strong><br>Adoption: ' + p.value[0] +'/20<br> Team: ' + p.value[1] + '/15<br> Social: ' + p.value[2] + '/15<br> Financials: '+ p.value[3] + '/20<br> Tech: '+ p.value[4] + '/20<br> Market: '+ p.value[5] + '/10<br><p style="color: #fff; padding: 2px; background-color: #C5426E "> TFscore™: ' + total + '</p>';
                },
                extraCssText: 'box-shadow: 0 0 5px rgba(0, 0, 0, 0.1)'
            },
            legend: {
                bottom: 5,
                itemWidth: 12,
                itemHeight: 12,
                data: [coinName,vscoinName],
                // data: ['Ethereum', 'EOS'],
                textStyle: {
                    color: '#000'
                }
            },
            radar: {
                radius: '60%',
                splitNumber: 8,
                axisLine: {
                    lineStyle: {
                        color: '#000',
                        opacity: .2
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: '#fff',
                        opacity: .2
                    }
                },
                splitArea: {
                    areaStyle: {
                        color: 'rgba(127,95,132,.3)',
                        opacity: 1,
                        shadowBlur: 45,
                        shadowColor: 'rgba(0,0,0,.5)',
                        shadowOffsetX: 0,
                        shadowOffsetY: 15,
                    }
                },
                indicator: [{
                    name: 'Adoption',
                    max: 20
                }, {
                    name: 'Team',
                    max: 15
                }, {
                    name: 'Social',
                    max: 15
                }, {
                    name: 'Financials',
                    max: 20
                }, {
                    name: 'Tech',
                    max: 20
                }, {
                    name: 'Market',
                    max: 10
                }]
            },
            series: [{
                name: 'TFscore™ comparison',
                type: 'radar',
                symbolSize: 0,
                areaStyle: {
                    normal: {
                        shadowBlur: 13,
                        shadowColor: 'rgba(0,0,0,.2)',
                        shadowOffsetX: 0,
                        shadowOffsetY: 10,
                        opacity: .7
                    }
                },
                data: [{
                    value: [adoptionScore, teamScore, socialScore, financialsScore, techScore, marketScore],
                    name: coinName,
                }
                , {
                    value: [vsadoptionScore,vsteamScore, vssocialScore, vsfinancialsScore, vstechScore, vsmarketScore],
                    name: vscoinName,
                }
            ]
            }],
            color: ['#800080', '#b1eadb'],
            // backgroundColor: {
            //     type: 'radial',
            //     x: 0.4,
            //     y: 0.4,
            //     r: 0.35,
            //     colorStops: [{
            //         offset: 0,
            //         color: '#895355' // 0% 处的颜色
            //     }, {
            //         offset: .4,
            //         color: '#593640' // 100% 处的颜色
            //     }, {
            //         offset: 1,
            //         color: '#39273d' // 100% 处的颜色
            //     }],
            //     globalCoord: false // 缺省为 false
            // }
        };
      
        
        return option
      }
  render() {
    // const comparisonOptions = [
    //     // { key: 1, text: 'BTC', value: 'BTC' },
    //     // { key: 2, text: 'ETH', value: 'ETH' },
    //   ]



    return (
        <Grid stackable>
            {this.props.coinInfo && this.props.coinInfo.info ? 
        <Grid.Column style={{paddingLeft:'80px',paddingTop:'20px'}}  width={8}>
        <Divider style={{ marginTop: '-10px' }} hidden />
                <div style={{display:'flex', marginBottom:'-15px'}} className='toprighttagmainscore'> 
          <span className='toprighttagtextscoring' style={{width:'40%',display:'inline',marginRight:'5px',marginTop:'0px'}}>TFscore™
          <Popup content={'The overall TFscore™ from combining the Adoption, Team, Social, Financials, Tech, and Market scores.'}  trigger={<Icon style={{marginLeft:'1px',marginRight:'-4px',verticalAlign: 'text-top'}} name='question circle outline' size='small'  />}/>
          </span>
          
          <span style={{width:'60%',display:'inline'}}>
            <Progress indicating style={{fontSize:'15px'}} 
            value={this.props.coinInfo.info.adoption +this.props.coinInfo.info.team.devactivity + this.props.coinInfo.info.team.developers + this.props.coinInfo.info.team.foundation + this.props.coinInfo.info.team.industrybacking + this.props.coinInfo.info.team.leader + this.props.coinInfo.info.social.activity + this.props.coinInfo.info.social.age + this.props.coinInfo.info.social.coverage + this.props.coinInfo.info.social.cult + this.props.coinInfo.info.social.launch + this.props.coinInfo.info.social.reputation + this.props.coinInfo.info.financials.distribution + this.props.coinInfo.info.financials.funding + this.props.coinInfo.info.financials.inflation + this.props.coinInfo.info.financials.liquidity + this.props.coinInfo.info.financials.listings + this.props.coinInfo.info.financials.security + this.props.coinInfo.info.tech.bughack + this.props.coinInfo.info.tech.decentralized + this.props.coinInfo.info.tech.functional + this.props.coinInfo.info.tech.mainnet + this.props.coinInfo.info.tech.scaling + this.props.coinInfo.info.tech.secure + this.props.coinInfo.info.tech.testnet + this.props.coinInfo.info.tech.unique + this.props.coinInfo.info.tech.wallets + this.props.coinInfo.info.tech.whitepaper + this.props.coinInfo.info.market.marketleader + this.props.coinInfo.info.market.scope + this.props.coinInfo.info.market.utility + this.props.coinInfo.info.market.blockchain + this.props.coinInfo.info.market.staking + this.props.coinInfo.info.market.interoperability} 
            total='100' size='medium' progress='ratio' /></span>
           </div>
         
           <ReactEcharts style={{height:'80px',width:'100%'}}
                option={this.getAdoptionScoreOption()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"} />
             <ReactEcharts style={{marginTop:'-50px',height:'80px',width:'100%'}}
                option={this.getTeamScoreOption()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"} />
                 <ReactEcharts style={{marginTop:'-50px',height:'80px',width:'100%'}}
                option={this.getSocialScoreOption()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"} />
                     <ReactEcharts style={{marginTop:'-50px',height:'80px',width:'100%'}}
                option={this.getFinancialsScoreOption()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"} />
                     <ReactEcharts style={{marginTop:'-50px',height:'80px',width:'100%'}}
                option={this.getTechScoreOption()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"} />
                     <ReactEcharts style={{marginTop:'-50px',height:'80px',width:'100%'}}
                option={this.getMarketScoreOption()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"} />

          </Grid.Column>
          :''}
 
         <Grid.Column className='ratinghexagon' width={8}>
        {this.props.coinInfo.info && this.props.chosenComparison ? 
        <div className='ratinghexagonshell'>
                <ReactEcharts className='echartspage'
                option={this.getOptionComparison()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"} />
            </div> 
:this.props.coinInfo.info ? 
<div className='ratinghexagonshell'>
<ReactEcharts className='echartspage'
option={this.getOption()}
notMerge={true}
lazyUpdate={true}
theme={"theme_name"} />
</div> 
:  <Loader />}
          </Grid.Column>
          </Grid>
    )
  }
}
