import React, { Component } from 'react'
import { Grid} from 'semantic-ui-react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  coin: state.firestore.ordered.coins
})

class CoinDescription extends Component {
  render() {
     
    return (
      <Grid.Row className="cointitledescription">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start'
        }}
      >
        <div
          style={{
            width: '60px',
            minWidth: '64px',
            height: '64px',
            minHeight: '64px',
            borderRadius: '30px',
            overflow: 'hidden',
            marginRight: '12px',
            position: 'relative'
          }}
        >
          {this.props.coin && this.props.coin[0] && this.props.coin[0].info ?<img alt="coinlogo" src={`/assets/logos/${this.props.coin ? this.props.coin[0].coinSymbol.toLowerCase() : 'empty'}.png`}/> :''}

          
        </div>

        <div>
          <span style={{fontWeight: '700',color: '#000',fontSize: '32px'}}>
            {this.props.coin && this.props.coin[0] ? this.props.coin[0].coinName : ''}
          </span>
          <span style={{marginLeft: '5px',fontWeight: '700',color: 'grey',fontSize: '16px'}}>
            {this.props.coin && this.props.coin[0] ? '(' + this.props.coin[0].coinSymbol.toUpperCase() + ')' : ''}
          </span>
          

          {/* <span>
            {' '}
            {coinDetail.coinBadge &&
              coinDetail.coinBadge.map((badge, index) => (
                <Popup
                key={index}
                  inverted
                  trigger={
                    <img
                      className="coinbadges"
                      alt="coinbadge"
                      src={badge}
                    />
                  }
                  content="Innovation Badge"
                />
              ))}{' '}
          </span> */}

          <p
            style={{
              fontWeight: '400',
              fontSize: '15px',
              marginTop: '10px'
            }}
          >
            {this.props.coin && this.props.coin[0] && this.props.coin[0].info ? this.props.coin[0].info.description : ''}
          </p>
        </div>
      </div>
    </Grid.Row>
        
    )
  
}
}
export default connect(mapStateToProps)(CoinDescription);
