import React from 'react'
import {Divider,List} from 'semantic-ui-react'

const Footer = () => (
        <div style={{ marginBottom:'10px' }}>
        <Divider style={{ marginTop: '1px',marginBottom:'15px' }}inverted section />
        {/* <Image centered size='mini' src='/logo.png' /> */}
        <center>
        {/* <List horizontal inverted divided link size='small'>
          <List.Item as='a' href='#'>
            <span style={{color:'grey'}}>Site Map</span>
          </List.Item>
          <List.Item as='a' href='#'>
          <span style={{color:'grey'}}>Contact Us</span>
          </List.Item>
          <List.Item as='a' href='#'>
          <span style={{color:'grey'}}>Terms and Conditions</span>
          </List.Item>
          <List.Item as='a' href='#'>
          <span style={{color:'grey'}}>Privacy Policy</span>
          </List.Item>
        </List> */}
        </center>
        <div style={{lineHeight:1.2,fontSize:9.5,marginTop:10,marginLeft:'10%',marginRight:'10%'}}>
        All content provided is for your general information only, many of which is procured from third party sources. We make no warranties of any kind in relation to our content, including but not limited to accuracy and updatedness. No part of the content that we provide constitutes financial advice, legal advice or any other form of advice meant for your specific reliance for any purpose. Any use or reliance on our content is solely at your own risk and discretion. You should conduct your own research, review, analyse and verify our content before relying on them. Trading is a highly risky activity that can lead to major losses, please therefore consult your financial advisor before making any decision.
        </div>
        </div>
)

export default Footer