import React, { Component } from 'react';
import {Grid,Container,Card } from 'semantic-ui-react'

class GlobalData extends Component {
    render() {
        return (
          
            <Container style={{marginTop:'20px'}}>
        
        <div style={{display:'flex',justifyContent:'space-between'}}>
  <div style={{background:'purple',color:'white',padding:'5px'}}><strong>asff</strong></div>
  <div style={{background:'purple',color:'white',padding:'5px'}}><strong></strong>This little piggy stayed home.</div>
  <div style={{background:'purple',color:'white',padding:'5px'}}>This little piggy had roast beef.</div>
</div>
         
            </Container>
        );
    }
}

export default GlobalData;