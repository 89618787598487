import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import NewsBrowser from '../../features/newsBrowser/NewsBrowser'
import NavBar from "../../features/nav/NavBar/NavBar";
import ModalManager from '../../features/modals/ModalManager';
import NewsCreator from '../../features/newsCreator/NewsCreator';
import CoinPage from '../../features/coinPage/CoinPage';
import About from '../pages/About';
import Footer from './Footer';
import CoinListTable from '../../features/coinList/CoinListTable';
import CoinList from '../../features/coinList/CoinList';
import Admin from '../../features/admin/Admin';


class App extends Component {
  render() {
    return (
      <div>
        <ModalManager/>
         <NavBar />
      
        <Switch>
          <Route exact path="/" component={CoinList} />
        </Switch>


        <Route path="/(.+)" render={() => (
            <div>
           <div className='homehero'></div> 
              <Switch>
                <Route path="/about" component={About} />
                <Route path="/news" component={NewsBrowser} />
                <Route path="/coin/:id" component={CoinPage} />
                <Route path="/submit-news" component={NewsCreator} />
                <Route path='/blog' component={() => { window.location = 'http://www.tokenflipper.com'; return null;} }/>

                 
                <Route path="/admin" component={Admin} />
              </Switch>
          </div>
    )}/>
      <Footer />
      
      </div>
    );
  }
}

export default App;
