import React, { Component } from 'react'
import firebase from '../../app/config/firebase'
import axios from 'axios'

//db, paprika 2nd, coingecko, name proper
let theCoin = ['bnb','Binance Coin','binance-coin','Binance Coin'];
let theYear = '2020';

export default class Admin extends Component {


  async componentDidMount() {

// this.recordPriceDataUSD();
 // this.recordPriceDataBTC();
  // this.recordSocialData();

    // this.recordInfoData();
  // this.recordPreNewsData();
   // this.recordNewsData();
   //  this.recordPartnershipData();
    // this.recordHomepageData();
  }

  async recordSocialData() {
      const db = firebase.firestore();
    //mm dd yyyy format
      let start = new Date(`01-01-${theYear}`);
      let end = new Date(`02-12-${theYear}`);
      
      
      let loop = new Date(start);
  
      let dataAfterLoop = {};
  
      
      while(loop <= end){
         //thedateformat needs to be in dd-mm-yyyy format
        let dateForApi = loop.toLocaleString('en-gb', {day: '2-digit',month: '2-digit',year: 'numeric'}).split('/').join('-');
        let resRef = await axios.get(
          `https://api.coingecko.com/api/v3/coins/${theCoin[2]}/history?date=${dateForApi}`
        );  

        let res = await resRef;
  
        function formatDate(date) {
          var d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();
      
          if (month.length < 2) month = '0' + month;
          if (day.length < 2) day = '0' + day;
      
          return [year, month, day].join('-');
      }
  
      let dateForDatabase = formatDate(loop);
      // let year = dateForDatabase.substring(0,4);

      let facebook_likes = null;
      let twitter_followers = null;
      let reddit_average_posts_48h = null;
      let reddit_average_comments_48h = null;
      let reddit_subscribers = null;
      let reddit_accounts_active_48h = null;
      let github_stars = null;



      if(res.data && res.data.community_data && res.data.community_data.facebook_likes){
        facebook_likes = res.data.community_data.facebook_likes;
      }
      if(res.data && res.data.community_data && res.data.community_data.twitter_followers){
        twitter_followers = res.data.community_data.twitter_followers
      }
      if(res.data && res.data.community_data && res.data.community_data.reddit_average_posts_48h){
        reddit_average_posts_48h = res.data.community_data.reddit_average_posts_48h
      }
      if(res.data && res.data.community_data && res.data.community_data.reddit_average_comments_48h){
        reddit_average_comments_48h = res.data.community_data.reddit_average_comments_48h
      }
      if(res.data && res.data.community_data && res.data.community_data.reddit_subscribers){
        reddit_subscribers = res.data.community_data.reddit_subscribers
      }
      if(res.data && res.data.community_data && res.data.community_data.reddit_accounts_active_48h){
        reddit_accounts_active_48h = res.data.community_data.reddit_accounts_active_48h
      }
      if(res.data && res.data.developer_data && res.data.developer_data.stars){
        github_stars = res.data.developer_data.stars
      }

        let apiData = {
            facebook_likes,
            twitter_followers,
            reddit_average_posts_48h,
            reddit_average_comments_48h,
            reddit_subscribers,
            reddit_accounts_active_48h,
            github_stars
          }
      // db.collection('coins').doc('ethereum').collection('historical').doc(year).set({[dateForDatabase]: apiData},{merge:true});
  
      dataAfterLoop[dateForDatabase] = apiData;
  
       var newDate = loop.setDate(loop.getDate() + 1);
       loop = new Date(newDate);
  
    
      }
      
      // return db.collection("coins").doc('ethereum').collection("historical").doc(year).update(apiData);
      db.collection('coins').doc(theCoin[0]).collection('historical').doc(theYear).set(dataAfterLoop,{merge:true});
      }

  async recordPriceDataBTC() {
    
    const db = firebase.firestore();
    const res = await axios.get(
      `https://api.coinpaprika.com/v1/coins/${theCoin[0]}-${theCoin[1]}/ohlcv/historical?start=${theYear}-01-01&end=${theYear}-03-08&quote=btc`
    );
      let histPrice = res.data;
      let dataAfterLoop = {};
      histPrice.map(item => {
        let adjustedDate = item.time_open.substring(0,10);
        // let year = item.time_open.substring(0,4);

        dataAfterLoop[adjustedDate] = {
          date:adjustedDate,
          openBTC: item.open,
          closeBTC: item.close,
          lowBTC: item.low || item.open,
          highBTC: item.high || item.open,
          volumeBTC: item.volume || 0
          }
          return null
      })

   db.collection('coins').doc(theCoin[0]).collection('historical').doc(theYear).set(dataAfterLoop,{merge:true});
      }


  async recordPriceDataUSD() {
        const db = firebase.firestore();
        //yyy mm  dd
        const res = await axios.get(
          `https://api.coinpaprika.com/v1/coins/${theCoin[0]}-${theCoin[1]}/ohlcv/historical?start=${theYear}-01-01&end=${theYear}-03-08&quote=usd`
        );
          let histPrice = res.data;
          let dataAfterLoop = {};
          histPrice.map(item => {
            let adjustedDate = item.time_open.substring(0,10);
            // let year = item.time_open.substring(0,4);
    
            dataAfterLoop[adjustedDate] = {
              date:adjustedDate,
              openUSD: item.open,
              closeUSD: item.close,
              lowUSD: item.low || item.open,
              highUSD: item.high || item.open,
              volumeUSD: item.volume || 0
              }
              return null
          })
    
       db.collection('coins').doc(theCoin[0]).collection('historical').doc(theYear).set(dataAfterLoop,{merge:true});
          }

  async recordInfoData() {
    
    const db = firebase.firestore();
    let data = {
      'coinName': theCoin[3].charAt(0).toUpperCase() + theCoin[3].slice(1),
      'coinApiName':theCoin[2],
      'coinSymbol':theCoin[0],
      'info':{
        'adoption': 0,
        'financials': {
          'distribution':1,
          'funding':1,
          'inflation':3,
          'liquidity': 5,
          'listings': 1,
          'security': 1
        },
        'market': {
          'blockchain':1,
          'interoperability':0,
          'marketleader': 2,
          'scope': 2,
          'staking': 1,
          'utility': 2
        },
        'social': {
          'activity': 1,
          'age': 2,
          'agetext': '2017-09-26',
          'coverage': 3,
          'cult': 0,
          'launch': 1,
          'reputation':0
        },
        'team': {
          'devactivity': 1,
          'developers': 4,
          'foundation': 1,
          'industrybacking': 2,
          'leader': 3
        },
        'tech':{
          'bughack': 0,
          'decentralized': 0,
          'functional': 2,
          'mainnet':2,
          'scaling': 2,
          'secure': 2,
          'testnet': 1,
          'unique': 1,
          'wallets': 2,
          'whitepaper': 1
        },
        'githubPath': 'tronprotocol/java-tron',
        'coinType':'Smart Contract Platform',
        'facebookURL': 'https://www.facebook.com/158118474590162',
        'githubURL':'https://github.com/tronprotocol',
        'redditURL':'https://reddit.com/r/Tronix',
        'twitterURL':'https://twitter.com/tronfoundation',
        'websiteURL':'https://tron.network/',
        'exchange1': {'link':'http://www.binance.com', 'name':'Binance'},
        'exchange2': {'link':'', 'name':''},
        'description':"Tron was formed originally as a fork of Ethereum with the main technical difference being a different consensus mechanism (DPoS) that prioritized speed over decentralization but has since been evolving with its own governance model & (TRX) token utility. The acquisition of BitTorrent reinforces Tron’s goal to be to be a decentralized platform that aims to build a global digital content entertainment system with distributed storage technology.",
        'ico': {
          'date':'Sep 7, 2017',
          'jurisdiction':'Singapore',
          'supply': 40000000000,
          'usdPrice': 0.00186,
          'usdRaise':70000000
        },
        'leader':{
          'name':'Justin Sun',
          'title':'Founder & CEO @ Tron',
          'twitter':'https://twitter.com/justinsuntron',
          'job1':" CEO @ Bittorent Inc (2018-)",
          'job2':"Founder & CEO @ Peiwo (2013-)"
        },
      },
      // 'businessPartnerships':[
      //   {'name':'Samsung','date':'March 13, 2019', 'info':"Samsung releases its SDK for building apps on Ethereum.",'link':'http://www.samsung.com'},
      //   {'name':'Cloudflare','date':'June 19, 2019', 'info':"Cloudflare launches service to make it easier to interact with the Ethereum network.",'link':'https://new.blog.cloudflare.com/cloudflare-ethereum-gateway/'}
      // ]
  }
  db.collection("coins").doc(theCoin[0]).set(data,{merge:true});
  console.log('infodata record initiated...')
  }

  async recordPreNewsData() {
    const db = firebase.firestore();
    let data = {'headline':"Brock Pierce Announces Dollar-backed Cryptocurrency ‘Realcoin’",'category':'Tech','date':'July 9, 2014','source':'https://www.coindesk.com/brock-pierce-announces-dollar-backed-cryptocurrency-realcoin'};    db.collection('coins').doc('usdt').collection('milestones').doc('1').set({ '2014-07-09':{      'news': firebase.firestore.FieldValue.arrayUnion(data)    }    },{merge:true});

  }

  async recordNewsData() {
    const db = firebase.firestore();
    let data = {'headline':"Tether Lawyer Admits Stablecoin Now 74% Backed by Cash and Equivalents",'category':'Red Flag','date':'April 30, 2019','source':'https://www.coindesk.com/tether-lawyer-confirms-stablecoin-74-percent-backed-by-cash-and-equivalents'};    db.collection('coins').doc('usdt').collection('historical').doc('2019').set({ '2019-04-30':{      'news': firebase.firestore.FieldValue.arrayUnion(data)    }    },{merge:true});

  }

  async recordPartnershipData() {
    const db = firebase.firestore();
    let data = {'info':"Samsung Integrates Tron and Mobile-Compatible DApp Building Tools",'strength':1,'size':3,'date':'October 29, 2019','link':'https://cointelegraph.com/news/samsung-integrates-tron-and-mobile-compatible-dapp-building-tools','name':'Samsung'};    db.collection('coins').doc('trx').set({      'businessPartnerships': firebase.firestore.FieldValue.arrayUnion(data)    },{merge:true});

  }

  async recordHomepageData() {
    const db = firebase.firestore();
    let data = {
            'adoption': 15,
            'team': 15,
            'social': 15,
            'financials': 20,
            'market': 10,
            'tech': 14,
            'total': 93
          }

  db.collection('homepage').doc('coinlist').set({
    'ETH':{
      'score':data
    }
    },{merge:true});
  }

  render() {
    return (
      <div>
        hi
      </div>
    )
  }
}



