import React, { Component } from 'react';
import { Grid,Segment, Icon,Popup, Progress,Rating } from 'semantic-ui-react';
import MarketMatrix from './MarketMatrix';




export default class CoinMarket extends Component {

  render() {
    const {twitterfans,facebookfans,telegramfans,redditsub,redditactive,githubstars,alexarank} = this.props.theState;
    let pointIcon = <Icon style={{verticalAlign: 'middle',marginTop:'-3px',marginRight:'-1px'}} name='star' color='yellow' size='small' />;
  
    return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Segment className="coincheckbox">
                <div className='ribbon'><p style={{marginLeft:'-16px'}}>Market</p></div>
                {this.props.coinInfo && this.props.coinInfo.info ? 
                  <div className='toprighttagratings'> 
                 
                      <span className='toprighttagtextscoring' style={{borderRadius: '.28571429rem',display:'inline',marginRight:'5px',marginTop:'0px'}}>Score
                        <Popup position='bottom left' trigger={<Icon style={{marginLeft:'1px',marginRight:'-4px',verticalAlign: 'text-top'}} name='question circle outline' size='large' />}>
                        <Popup.Header><p className='toprighttagtext'>Score Breakdown</p></Popup.Header>
    <Popup.Content>
      <span> <strong>Sector Position</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.market.marketleader} maxRating={3} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon} Clear leader in their sector. <br />{pointIcon}{pointIcon} Considered among the top in the sector.<br />  {pointIcon} Up and comer in the sector with potential.
        </em></p>
      <span> <strong>Project Scope</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.market.scope} maxRating={2} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon} A blockchain platform that others can build on top of or a cryptocurrency targeting global widespread usage. <br /> {pointIcon} A blockchain agnostic middleware / protocol.
        </em></p>
      <span> <strong>Token Utility</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.market.utility} maxRating={2} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}Token has clear utility and is designed to appreciate proportionally to growth in usage/adoption.<br />{pointIcon} Token has utility but might not appreciate in-line with the network usage/adoption.
        </em></p>
      <span> <strong>Blockchain/Crypto Necessity</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.market.blockchain} maxRating={1} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon} The use of blockchain or crypto technology is essential for the desired usecase.
        </em></p>
      <span> <strong>Staking?</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.market.staking} maxRating={1} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon} There is some form of staking, proof-of-stake reward, or passive income generating ability.
        </em></p> 
      <span> <strong>Interoperability</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.market.interoperability} maxRating={1} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon} Has interoperability with other chains.
        </em></p>         
    </Popup.Content>
                         </Popup>
                       </span>
          
                  <span style={{display:'inline'}}>
                   <Progress indicating className='sectionscorebar' value={this.props.coinInfo.info.market.marketleader + this.props.coinInfo.info.market.scope + this.props.coinInfo.info.market.utility + this.props.coinInfo.info.market.blockchain + this.props.coinInfo.info.market.staking + this.props.coinInfo.info.market.interoperability} total={10} color='purple' size='medium' progress='ratio' />
                  </span>
                 
                  </div>
                  : ''}
        
                  <Grid columns={2} centered stackable>

<Grid.Column width={10}>


<MarketMatrix theState={this.props.theState}/>



</Grid.Column>

<Grid.Column width={5}>
 {this.props.coinInfo.info ? 
<div style={{paddingLeft:'0px'}}>

<strong><span style={{fontSize:'12px'}}className='homefinancialslabel'>SECTOR POSITION</span></strong> <span className='homefinancialspricechange'>
{this.props.coinInfo.info.market.marketleader === 3 ? 'LEADER' : this.props.coinInfo.info.market.marketleader === 2 ? 'NEAR TOP' :this.props.coinInfo.info.market.marketleader === 1 ? 'UP-AND-COMER' :'NON-FACTOR'}
</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>

<strong><span className='homefinancialslabel'>SCOPE</span></strong> <span className='homefinancials'>{this.props.coinInfo.info.market.scope === 2 ? 'PLATFORM' : this.props.coinInfo.info.market.scope === 1 ? 'MIDDLEWARE' : 'TOKEN ON ANOTHER CHAIN'}</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>

<strong><span className='homefinancialslabel'>TOKEN UTILITY</span></strong> <span className='homefinancials'>
{this.props.coinInfo.info.market.utility === 2 ? 'STRONG' : this.props.coinInfo.info.market.utility === 1 ? 'OK' :'WEAK'}
</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>

<strong><span className='homefinancialslabel'>NEED BLOCKCHAIN?</span></strong> <span className='homefinancials'> 
{this.props.coinInfo.info.market.blockchain === 1 ? 'YES' :'NO'}
</span>


<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>STAKING?</span></strong> <span className='homefinancials'>
{this.props.coinInfo.info.market.staking === 1 ? 'YES' :'NO'}
</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>INTEROPERABILITY?</span></strong> <span className='homefinancials'> 
{this.props.coinInfo.info.market.interoperability === 1 ? 'YES' :'NO'}
</span>

</div>  
  : ''} 
  </Grid.Column>
</Grid>

              </Segment>
            </div>
    );
  }
}
