import React, { Component } from 'react'

import ReactEcharts from 'echarts-for-react';


class CoinChart extends Component {
 componentDidMount () {

 }

  getOption(){  
   let data0 = splitData(
  this.props.chosenData || []
);

function splitData(rawData) {
  let categoryData = [];
  let values = [];
  let volumes = [];
  let news = [];

 
  for (let i = 0; i < rawData.length; i++) {
      categoryData.push(rawData[i][0]);
      values.push([rawData[i][1],rawData[i][2],rawData[i][3],rawData[i][4],rawData[i][5],rawData[i][6] !== '' && rawData[i][6] ]);
      volumes.push([i, rawData[i][5], rawData[i][1] > rawData[i][2] ? 1 : -1]);
      if(rawData[i][6]){
        if (rawData[i][6].constructor === Array){
            let accumulatedNews = []
            for (let n=0; n < rawData[i][6].length; n++) {
                accumulatedNews.push(rawData[i][6][n].headline)
            }
         
            news.push([i ,rawData[i][4],accumulatedNews])

        
        } else {
          console.log(rawData[i][4])
      news.push([i ,rawData[i][4], rawData[i][6]]);
    }
    }
  }
  return {
      categoryData: categoryData,
      values: values,
      volumes: volumes,
      news:news
  };
}

let secondaryData = this.props.secondary;
let currencyName = this.props.currencyName;
let currencySymbol = this.props.currencySymbol;

    const option = {
      backgroundColor: '#fff',
        animation: false,
        legend: {
            top: 12,
            left: 'center',
            data: [{
              name: `Daily Price (${this.props.currencyName})`,
              icon: 'image://https://i.imgur.com/6Xxr0VS.png',
              textStyle: {
                fontSize: 11,
                }
            },
            {
              name: 'Volume',
              icon: 'image://https://i.imgur.com/DyKElRH.png',
              color:'#fefefe',
              textStyle: {
                fontSize: 11,
                }
            },
            {
              name: 'News',
              icon: 'pin',
              color:'#f300bd',
              textStyle: {
                fontSize: 11,
                }
            },
            {
                name: this.props.secondaryName,
                icon: 'line',
                textStyle: {
                    fontSize: 11,
                    }
              }
        ],
            textStyle: {
              fontWeight: '600'
            }
        },

      tooltip: {
          trigger: 'item',
          axisPointer: {
              type: 'cross'
          },
          backgroundColor: 'rgba(140, 0, 140, 0.9)',
          borderWidth: 1,
          borderColor: 'black',
          padding: 10,
          textStyle: {
              color: '#fff'
          },
          // position:['33%','7%'],
          // position: function (pos, params, el, elRect, size) {
          //     let obj = {top: 20};
          //     obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 250;
          //     return obj;
          // }
          // extraCssText: 'width: 170px'
      },
      axisPointer: {
          link: {xAxisIndex: 'all'},
          label: {
              backgroundColor: '#989898'
          }
      },
      toolbox: {
        show:false
      },
      brush: {
          xAxisIndex: 'all',
          brushLink: 'all',
          outOfBrush: {
              colorAlpha: 0.1
          }
      },
      visualMap: {
          show: false,
          seriesIndex: 1,
          dimension: 2,
          pieces: [{
              value: 1,
              color: '#EC0000'
          }, {
              value: -1,
              color: '#00DA3C'
          }]
      },
      grid: [
          {
              left: '10%',
              right: '8%',
              height: '49%'
          },
          {
              left: '10%',
              right: '8%',
              top: '67%',
              height: '12%'
          }
          
      ],
      xAxis: [
          {
              type: 'category',
              data: data0.categoryData,
              scale: true,
              boundaryGap : true,
              axisLine: {onZero: false},
              splitLine: {show: false},
              splitNumber: 20,
              gridIndex: 0,
              min: 'dataMin',
              max: 'dataMax',
              axisPointer: {
                  z: 100
              }
          },
          {
              type: 'category',
              gridIndex: 1,
              data: data0.categoryData,
              scale: true,
              boundaryGap : true,
              axisLine: {onZero: false},
              axisTick: {show: false},
              splitLine: {show: false},
              axisLabel: {show: false},
              splitNumber: 20,
              // min: 'dataMin',
              // max: 'dataMax'
              // axisPointer: {
              //     label: {
              //         formatter: function (params) {
              //             let seriesValue = (params.seriesData[0] || {}).value;
              //             return params.value
              //             + (seriesValue != null
              //                 ? '\n' + echarts.format.addCommas(seriesValue)
              //                 : ''
              //             );
              //         }
              //     }
              // }
          }
      ],
      yAxis: [
          {
              scale: true,
              axisLabel: {
                fontSize: 9
              },
              nameTextStyle: {
                fontSize: 10
              },
              name: `Price (${this.props.currencyName})`,
              splitArea: {
                  show: true,
                  color: '#800080',
                  areaStyle: {
                    color: ['rgba(250,250,250,0.3)','rgba(200,200,200,0.3)']
                  }
              },
          },
          {
              scale: true,
              gridIndex: 1,
              splitNumber: 2,
              axisLabel: {show: false},
              axisLine: {show: false},
              axisTick: {show: false},
              splitLine: {show: false}
          },
          {
            type: 'value',
            name: this.props.secondaryName,
            axisLabel: {
                fontSize: 9
              },
            nameTextStyle: {
                fontSize: 10
              },
           scale: true,
            splitLine: false,
            position: 'right',
            axisLine: {
                lineStyle: {
                    color: 'black',
                }
            },
            // axisLabel: {
            //     formatter: '{value} °C'
            // }
        }
      ],
      dataZoom: [
          {
              type: 'inside',
              xAxisIndex: [0, 1],
              start: 20,
              end: 100
          },
          {
              show: true,
              xAxisIndex: [0, 1],
              type: 'slider',
              bottom: '12%',
              start: 20,
              end: 100
          }
      ],
      series: [
          {
              name: `Daily Price (${this.props.currencyName})`,
              type: 'candlestick',
              data: data0.values,
              xAxisIndex: 0,
              yAxisIndex: 0,
              min: 'dataMin',
              max: 'dataMax',
              itemStyle: {
                  normal: {
                      color: '#00da3c',
                      color0: '#ec0000',
                      borderColor: null,
                      borderColor0: null
                  }
              }
              ,
              tooltip: {
                extraCssText: 'font-size:12px',
                  formatter: function (param) {
                    
                      return [
                          'Date: ' + param.name + '<hr size=1 style="margin: 3px 0">',
                          'Open: '+ currencySymbol  + param.data[1] +  '<br/>',
                          'Close: '+ currencySymbol  + param.data[2] + '<br/>',
                          'Lowest: '+ currencySymbol  + param.data[3] + '<br/>',
                          'Highest: '+ currencySymbol  + param.data[4] + '<br/>',
                          'Volume: ' + param?.data[5].toLocaleString(undefined, {maximumFractionDigits: 0}) + ' ' + currencyName + '<br/>'
                      ].join('');
                  }
              }
          },
         
          {
              name: 'Volume',
              type: 'bar',
              xAxisIndex: 1,
              yAxisIndex: 1,
              data: data0.volumes,
              tooltip: {
                extraCssText: 'font-size:12px',
                  formatter: function (param) {
                    
                      return [
                        'Volume: ' + param?.data[1].toLocaleString(undefined, {maximumFractionDigits: 0}) + ' ' + currencyName + '<br/>'
                      ].join('');
                  }
              }
          },
          {
            name:'News',
            symbolSize: 15,
            symbolOffset:['0%','-20%'],
            data: data0.news,
            type: 'effectScatter',
            hoverAnimation:true,
            symbol:'pin',
            itemStyle: {
              color: 'purple'
            },
            tooltip: {
              formatter: function (param) {
           
                    return ['<p class="newstooltip">' + param?.data[2].join('<hr size=1 style="margin: 1px 0 0 0" /><p class="newstooltip">') + '</p>']
                
                
              }
          }
        },
        {
            name: this.props.secondaryName,
            type:'line',
            yAxisIndex: 2,
            data: secondaryData,
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
                width: 2,
              },
            itemStyle: {
                color: '#87CEEB',
              }

        }

      ]
  }
  
    
    return option
  }

  render() {

    return (
   
        <ReactEcharts
  option={this.getOption()}
  notMerge={true}
  style={{height:'450px'}}
  lazyUpdate={true}
  theme={"theme_name"} />
        
    )
  }
}

export default CoinChart;


