import React, { Component } from 'react'
import { Segment, Tab, Icon, Checkbox} from 'semantic-ui-react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const panes = [
  {menuItem: 'All', pane: {key: 'allEvents'}},
  {menuItem: 'Adoption', pane: {key: 'adoption'}},
  {menuItem: 'Tech', pane: {key: 'tech'}},
  {menuItem: 'Biz Dev', pane: {key: 'bizdev'}},
  {menuItem: 'Red Flag', pane: {key: 'redflag'}}
]

export default class Timeline extends Component {
state = {
  tab: 0,
  radio: false
}

  handleTabChange = (e, data) => {
    this.setState({
      tab: data.activeIndex
});
}
handleRadioChange = async(e, data) => {
  this.setState({
    radio: data.checked
});
  }
  render() {
    let filteredNews = [];
    if(this.state.tab === 0 && this.state.radio === false) {
      filteredNews = this.props.newsData.slice().reverse();
    }else if
    (this.state.tab === 0 && this.state.radio === true) {
      filteredNews = this.props.newsData;
    }else if
    (this.state.tab === 1 && this.state.radio === false) {
      filteredNews = this.props.newsData.slice().reverse().filter(function(news) {return news.category === "Adoption"});
    }else if
    (this.state.tab === 1 && this.state.radio === true) {
      filteredNews = this.props.newsData.filter(function(news) {return news.category === "Adoption"});
    }else if 
    (this.state.tab === 2 && this.state.radio === false) {
      filteredNews = this.props.newsData.slice().reverse().filter(function(news) {return news.category === "Tech"});
    }else if
    (this.state.tab === 2 && this.state.radio === true) {
      filteredNews = this.props.newsData.filter(function(news) {return news.category === "Tech"});
    }else if 
    (this.state.tab === 3 && this.state.radio === false) {
      filteredNews = this.props.newsData.slice().reverse().filter(function(news) {return news.category === "Biz Dev"});
    }else if
    (this.state.tab === 3 && this.state.radio === true) {
      filteredNews = this.props.newsData.filter(function(news) {return news.category === "Biz Dev"});
    }else if
    (this.state.tab === 4 && this.state.radio === false) {
      filteredNews = this.props.newsData.slice().reverse().filter(function(news) {return news.category === "Red Flag"});
    }else if
    (this.state.tab === 4 && this.state.radio === true) {
      filteredNews = this.props.newsData.filter(function(news) {return news.category === "Red Flag"});
    } else{
      filteredNews = [];
    }
    return (
      <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Segment className='tabbox' style={{justifyContent: 'center !important'}} attached>
      <div className='ribbon'><p style={{marginLeft:'-15px'}}>Key Events</p></div>
      <Tab className='tabselector' panes={panes} onTabChange={this.handleTabChange} />
      </Segment>
      </div>
      <VerticalTimeline>
      <div className="chronological">
      <span style={{marginRight: '10px'}}>Chronological</span> <Checkbox onChange={this.handleRadioChange} toggle />
      </div>
        
      {filteredNews.map((news, index) => {
        let icon = 'star';
         if(news.category === 'Adoption') {
            icon = 'users';
         }else if(news.category === 'Tech') {
           icon = 'lightbulb';
         }else if(news.category === 'Biz Dev'){
           icon = 'money bill alternate outline';
         }else if(news.category === 'Red Flag'){
           icon = 'exclamation';
         }else{
           icon = 'star';
         }
        return(
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    key={index}
    date={new Date(news.date).toLocaleString('en-us', {month: 'long',day: 'numeric',year: 'numeric'}).split('/').join('-')}
    iconStyle={{ background: '#00B5AD', color: '#fff' }}
    icon={<Icon className='timelineicon' name={icon} size='big' />}
  >
    <div>
    <h3 className="vertical-timeline-element-title">{news.headline} {' '}
    <a href={news.source} target="_blank" rel='noopener noreferrer'><Icon style={{color:'grey',marginLeft:'2px'}} name='external' /></a>
    </h3>
    </div>
  </VerticalTimelineElement>    
      
      )}
      )}
      </VerticalTimeline>
      </React.Fragment>

//       <div>
//         <VerticalTimeline>
//   <VerticalTimelineElement
//     className="vertical-timeline-element--work"
//     date="2011 - present"
//     iconStyle={{ background: '#00B5AD', color: '#fff' }}
//     icon={<Icon className='timelineicon' name='github' size='big' />}
//   >
//     <h3 className="vertical-timeline-element-title">Creative Director</h3>
//     <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4>
//     <p>
//       Creative Direction, User Experience, Visual Design, Project Management, Team Leading
//     </p>
//   </VerticalTimelineElement>
//   <VerticalTimelineElement
//     className="vertical-timeline-element--work"
//     date="2010 - 2011"
//     iconStyle={{ background: '#00B5AD', color: '#fff' }}
//     icon={<Icon name='github' className='timelineicon' size='big' />}
//   >
//     <h3 className="vertical-timeline-element-title">Art Director</h3>
//     <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
//     <p>
//       Creative Direction, User Experience, Visual Design, SEO, Online Marketing
//     </p>
//   </VerticalTimelineElement>
//   <VerticalTimelineElement
//     className="vertical-timeline-element--work"
//     date="2008 - 2010"
//     iconStyle={{ background: '#00B5AD', color: '#fff' }}
//     icon={<Icon name='github' className='timelineicon' size='big' />}
//   >
//     <h3 className="vertical-timeline-element-title">Web Designer</h3>
//     <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
//     <p>
//       User Experience, Visual Design
//     </p>
//   </VerticalTimelineElement>

// </VerticalTimeline>
//       </div>
    )
  }
}
