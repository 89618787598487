import React, { Component } from 'react';
import {Icon,Segment,Loader,Popup,Progress, Grid, Rating} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Textfit } from 'react-textfit';
import GithubCommits from './GithubCommits';

const mapStateToProps = (state) => ({
  coin: state.firestore.ordered.coins
})


class CoinTeam extends Component {


  render() {
    let pointIcon = <Icon style={{verticalAlign: 'middle',marginTop:'-3px',marginRight:'-1px'}} name='star' color='yellow' size='small' />;
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {this.props.coin && this.props.coin[0] && this.props.coin[0].info ? 
      <Segment className="coincheckbox">
      <div style={{}} className='ribbon'><p style={{marginLeft:'-13px'}}>Team</p></div>
      <div className='toprighttagratings'> 
                      <span className='toprighttagtextscoring' style={{borderRadius: '.28571429rem',display:'inline',marginRight:'5px',marginTop:'0px'}}>Score
                        <Popup position='bottom left' trigger={<Icon style={{marginLeft:'1px',marginRight:'-4px',verticalAlign: 'text-top'}} name='question circle outline' size='large' />}>
                        <Popup.Header><p className='toprighttagtext'>Score Breakdown</p></Popup.Header>
    <Popup.Content>
      <span> <strong>Leader (The Face)</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.team.leader} maxRating={5} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon}{pointIcon}{pointIcon} Legendary leader that is widely respected in general tech/biz space.<br />{pointIcon}{pointIcon}{pointIcon}{pointIcon} Rockstar leader that is widely respected in blockchain space.<br />{pointIcon}{pointIcon}{pointIcon} Leader with proven talent or effectiveness in current position. <br />{pointIcon}{pointIcon}  Leader with proven talent or effectiveness in previous position(s). <br />  {pointIcon} Respectable leader but still unproven.
        </em></p>
      <span> <strong>Dev Ecosystem</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.team.developers} maxRating={5} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon}{pointIcon}{pointIcon} Abundant full-time developers as well as thriving developer ecosystem.<br />{pointIcon}{pointIcon}{pointIcon}{pointIcon} Decent full-time developers and decent developer ecosystem.<br />{pointIcon}{pointIcon}{pointIcon} Decent full-time developers or decent developer ecosystem.<br />{pointIcon}{pointIcon} Some full-time developers and some developer ecosystem.<br />  {pointIcon} Some full-time developers or some developer ecosystem.
        </em></p>
      <span> <strong>Dev Activity</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.team.devactivity} maxRating={1} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
       {pointIcon} Existent dev activity on project's Github repository.
        </em></p>
      <span> <strong>Industry Backing</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.team.industrybacking} maxRating={3} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon}  Strong backing from known/influential people in the industry. <br />{pointIcon}{pointIcon} Moderate backing from people with experience in industry. <br />  {pointIcon} Some support from entities with more general connections.
        </em></p>
      <span> <strong>Foundation</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.team.foundation} maxRating={1} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
     {pointIcon} Some foundation, board, or entity that assures a certain level of transparency.
        </em></p>       
    </Popup.Content>
                         </Popup>
                       </span>
          
                  <span style={{display:'inline'}}>
                   <Progress indicating className='sectionscorebar' value={this.props.coinInfo.info && this.props.coinInfo.info.team.leader + this.props.coinInfo.info.team.developers + this.props.coinInfo.info.team.devactivity + this.props.coinInfo.info.team.industrybacking + this.props.coinInfo.info.team.foundation} total='15' color='purple' size='medium' progress='ratio' />
                  </span>

                  </div>

           <Grid columns={3} centered stackable>

           <Grid.Column width={5}>
<GithubCommits theState={this.props.theState} />
</Grid.Column>

<Grid.Column width={5}>
<center>
  {/* <p style={{maringTop:'-20px',marginBottom:'-5px',fontSize:'22px',fontWeight:'700'}}>Leader</p> */}




  <div style={{marginBottom:'15px',display:'flex',alignItems:'center', justifyContent:'center',alignContent:'center', width:'100%'}}>
    
  <div style={{display:'flex',width:'20%',alignSelf:'center'}}>
  {this.props.coin && this.props.coin[0] ?
    <img style={{marginRight:'5px'}} className='circular' src={`/assets/${this.props.coin[0].coinSymbol.toLowerCase()}leader.jpg`} alt="leader" />
    :''}

  </div>
  <div style={{width:'80%',alignSelf:'center'}}>
<span>
<Textfit mode="single" max={22}>
  <p>{this.props.coin && this.props.coin[0].info ? this.props.coin[0].info.leader.name:''}</p>
  </Textfit>
  <Textfit mode="single" max={18}>
  <p>{this.props.coin ? this.props.coin[0].info.leader.title:''}</p>
 </Textfit>
</span>

    
  </div>

</div>






{/* 
<Grid style={{display:'flex',alignItems:'center', justifyContent:'center'}} columns={2}>
<Grid.Column style={{alignSelf:'center',padding:'0px !important'}} width={4}>
<center>
<span>
    {this.props.coin && this.props.coin[0] ?
    <img className='circular' src={`/assets/${this.props.coin[0].coinName.toLowerCase()}leader.jpg`} alt="leader" />
    :''}
</span></center>
</Grid.Column>

<Grid.Column  style={{alignSelf:'center',padding:'0px !important'}} width={12}>
  <center>
<span>
<Textfit mode="single">
  <h4><p>{this.props.coin && this.props.coin[0].info ? this.props.coin[0].info.leader.name:''}</p></h4> 
  </Textfit>
  <Textfit mode="single">
  <p>{this.props.coin ? this.props.coin[0].info.leader.title:''}</p>
 </Textfit>
</span>
</center>
</Grid.Column>


</Grid> */}





<div style={{marginTop:'-15px'}} className="profile-container">
<div>
<center>

        
<div>

{this.props.coin && this.props.coin[0].info && this.props.coin[0].info.leader.job1 ? <Textfit max={16} className='leaderjobstext' mode="single">{this.props.coin[0].info.leader.job1}</Textfit>:''}
{this.props.coin && this.props.coin[0].info && this.props.coin[0].info.leader.job2 ? <Textfit max={16} className='leaderjobstext' mode="single">{this.props.coin[0].info.leader.job2}</Textfit>:''}
</div>



  <div>
<a href={this.props.coin ? this.props.coin[0].info.leader.twitter:''} target="_blank" rel='noopener noreferrer'><Icon style={{color:'grey'}} name='twitter' size='large' /></a>
</div>
</center>     
  </div>

</div>

</center>
</Grid.Column>






<Grid.Column width={5}>
 {this.props.coinInfo.info ? 
<div style={{paddingLeft:'0px'}}>

<strong><span className='homefinancialslabel'>LEADER</span></strong> <span className='homefinancials'>
{this.props.coinInfo.info.team.leader === 5 ? 'LEGENDARY' : this.props.coinInfo.info.team.leader === 4 ? 'TOP-TIER' : this.props.coinInfo.info.team.leader === 3 ? 'GOOD' :this.props.coinInfo.info.team.leader === 2 ? 'OK' : this.props.coinInfo.info.team.leader === 1 ? 'UNPROVEN' :'BAD'}
  </span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>

<strong><span className='homefinancialslabel'>DEV ECOSYSTEM</span></strong> <span className='homefinancials'>
{this.props.coinInfo.info.team.developers === 5 ? 'TOP-TIER' : this.props.coinInfo.info.team.developers === 4 ? 'GREAT' : this.props.coinInfo.info.team.developers === 3 ? 'GOOD' :this.props.coinInfo.info.team.developers === 2 ? 'OK' : this.props.coinInfo.info.team.developers === 1 ? 'LACKING' :'NONE'}
  </span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>

<strong><span style={{fontSize:'12px'}} className='homefinancialslabel'>INDUSTRY BACKING</span></strong> <span className='homefinancialspricechange'>
{this.props.coinInfo.info.team.industrybacking === 3 ? 'STRONG' : this.props.coinInfo.info.team.industrybacking === 2 ? 'MODERATE' : this.props.coinInfo.info.team.industrybacking === 1 ? 'WEAK' :'NONE'}
</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>

<strong><span className='homefinancialslabel'>FOUNDATION</span></strong> <span className='homefinancials'> 
{this.props.coinInfo.info.team.foundation === 1 ? 'YES' :'NO'}
</span>


</div>  
  : ''} 
  </Grid.Column>
</Grid>




      
      {/* <div style={{position:'relative'}}>
      <div className='verticalseparator'></div>
      </div>
      <div style={{padding:'5px 15px 10px 15px',width:'100%'}} className="profile-container-bio">

   
      <p style={{paddingTop:'5px',fontSize:'15px'}}>{this.props.coin ? this.props.coin[0].info.leader.bio:''}</p>
      </div> */}
     

     {/* <img src={`/assets/${coin.exchange2.name}.png`} alt="leader" /> */}
     </Segment>
     :<Loader />}
    </div>
    );
  }
}

export default connect(mapStateToProps)(CoinTeam);




