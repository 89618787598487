import { toastr } from 'react-redux-toastr';

export const addToFavorites = (item) =>
    async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
   
    try {
        let UID = await firebase.auth().currentUser.uid;
      await firestore.update({collection:'tactics', doc: item.id},{
          favoritedUID: firestore.FieldValue.arrayUnion(UID)
      });

        toastr.success('Success', 'Added To Your Favorites')
    } catch (error) {
        console.log(error);
    }
}

export const removeFromFavorites = (item) =>
    async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        
   
    try {
        let UID = await firebase.auth().currentUser.uid;
      await firestore.update({collection:'tactics', doc: item.id},{
          favoritedUID: firestore.FieldValue.arrayRemove(UID)
      });

        toastr.success('Success', 'Removed From Your Favorites')
    } catch (error) {
        console.log(error);
    }
}