import React, { Component } from 'react';
import { Grid,Segment, Icon, Loader,Popup, Flag, Progress,Rating } from 'semantic-ui-react';
import PriceRangeGauge from './PriceRangeGauge';

export default class CoinFinancials extends Component {


  render() {
   
  let pointIcon = <Icon style={{verticalAlign: 'middle',marginTop:'-3px',marginRight:'-1px'}} name='star' color='yellow' size='small' />;

    return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Segment className="coincheckbox">
                <div className='ribbon'>Financials</div>
                {this.props.coinInfo && this.props.coinInfo.info ? 
                  <div className='toprighttagratings'> 
                      <span className='toprighttagtextscoring' style={{borderRadius: '.28571429rem',display:'inline',marginRight:'5px',marginTop:'0px'}}>Score
                        <Popup trigger={<Icon style={{marginLeft:'1px',marginRight:'-4px',verticalAlign: 'text-top'}} name='question circle outline' position='bottom left' size='large' />}>
                        <Popup.Header><p className='toprighttagtext'>Score Breakdown</p></Popup.Header>
    <Popup.Content>
      <span> <strong>Inflation</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.financials.inflation} maxRating={5} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon}{pointIcon}{pointIcon} Deflationary.<br />{pointIcon}{pointIcon}{pointIcon}{pointIcon} No inflation.<br />{pointIcon}{pointIcon}{pointIcon} 0.1-5% inflation. <br />{pointIcon}{pointIcon} 5-10% inflation.<br />  {pointIcon} 10-20% inflation.
        </em></p>
      <span> <strong>Liquidity</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.financials.liquidity} maxRating={5} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon}{pointIcon}{pointIcon} > 1B Volume.<br />{pointIcon}{pointIcon}{pointIcon}{pointIcon} 100M - 1B Volume.<br />{pointIcon}{pointIcon}{pointIcon} 10M - 100M Volume. <br />{pointIcon}{pointIcon} 1M - 10M Volume.<br />  {pointIcon} 100K - 1M Volume.
        </em></p>
      <span> <strong>Listings</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.financials.listings} maxRating={2} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon} On >3 top tier exchange.<br />  {pointIcon} On 1 top tier exchange.
        </em></p>
      <span> <strong>Funding</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.financials.funding} maxRating={1} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon} Has a dedicated source of funding for operations - like a profitable business with customers, ICO warchest, or sustainable source of donations. 
        </em></p>
      <span> <strong>Token Distribution</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.financials.distribution} maxRating={4} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon}{pointIcon} >95% of tokens outside founder/team control. <br />{pointIcon}{pointIcon}{pointIcon} 80-95% of tokens outside founders/team control. <br />{pointIcon}{pointIcon} 65-80% of tokens outside founders/team control. <br />  {pointIcon} 50-65% of tokens outside founders/team control.
        </em></p> 
      <span> <strong>Is It A Security?</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.financials.security} maxRating={3} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon} Cleared by SEC officially or unofficially. <br />{pointIcon}{pointIcon} Small chance. <br />  {pointIcon} Moderate chance.
        </em></p>         
    </Popup.Content>
                         </Popup>
                       </span>
          
                  <span style={{display:'inline'}}>
                   <Progress indicating className='sectionscorebar' value={this.props.coinInfo.info.financials.distribution + this.props.coinInfo.info.financials.funding + this.props.coinInfo.info.financials.inflation + this.props.coinInfo.info.financials.liquidity + this.props.coinInfo.info.financials.listings + this.props.coinInfo.info.financials.security} total='20' color='purple' size='medium' progress='ratio' />
                  </span>

                  </div> : ''}

                  <Grid columns={3} centered stackable>

<Grid.Column width={5}>
  <PriceRangeGauge theState={this.props.theState}/>
</Grid.Column>



<Grid.Column width={5}>
{this.props.coinInfo.info ? 
<div className='tradingcolumntwo' style={{paddingLeft:'0px'}}>


{/* <strong><span className='homefinancialslabel'>PRICE</span></strong> <span className='homefinancials'>
  ${(this.props.theState.price && this.props.theState.price < 1 ? this.props.theState.price.toLocaleString(undefined, {maximumFractionDigits:3}): this.props.theState.price < 10 ? this.props.theState.price.toLocaleString(undefined, {maximumFractionDigits:2}): this.props.theState.price < 1000 ? this.props.theState.price.toLocaleString(undefined, {maximumFractionDigits:1}): this.props.theState.price > 1000 ? this.props.theState.price.toLocaleString(undefined, {maximumFractionDigits:0}):<Loader active inline size='mini'/>)}
  </span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr> */}
<strong><span className='homefinancialslabel'>PRICE Δ</span><span className='homefinancialslabeldeltapage'> (1D/30D/1Y)</span>
<span className='pricedeltaicon'><Popup content={'(1D/30D/1Y)'}  trigger={
<Icon style={{marginLeft:'1px',verticalAlign: 'text-top'}} name='info circle' size='tiny' />}/></span>
</strong> <span className='homefinancialspricechange'>
{this.props.theState.pricechange1d > 0 ? <span style={{color:'#20604F'}}>{'+'}{Number(this.props.theState.pricechange1d).toFixed(1)}{'%'}</span>: ''}
{this.props.theState.pricechange1d < 0 ? <span style={{color:'#D0104C'}}>{''}{Number(this.props.theState.pricechange1d).toFixed(1)}{'%'}</span>: ''}
{this.props.theState.pricechange30d> 0 ? <span style={{color:'#20604F'}}>{'/+'}{Number(this.props.theState.pricechange30d).toFixed(1)}{'%'}</span>: ''}
{this.props.theState.pricechange30d < 0 ?  <span style={{color:'#D0104C'}}>{'/'}{Number(this.props.theState.pricechange30d).toFixed(1)}{'%'}</span>: ''}
{this.props.theState.pricechange1y  > 0 ? <span style={{color:'#20604F'}}>{'/+'}{Number(this.props.theState.pricechange1y).toFixed(1)}{'%'}</span>: ''}
{this.props.theState.pricechange1y < 0 ? <span style={{color:'#D0104C'}}>{'/'}{Number(this.props.theState.pricechange1y).toFixed(1)}{'%'}</span>: ''}
</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>VOLUME</span></strong> <span className='homefinancials'>
{this.props.theState.volume >= 1.0e+9 ? '$' + (this.props.theState.volume/1.0e+9).toFixed(1) + "B":this.props.theState.volume>= 1.0e+6 ? '$' + (this.props.theState.volume/1.0e+6).toFixed(1) + "M": this.props.theState.volume >= 1.0e+3 ? '$' + (this.props.theState.volume/1.0e+3).toFixed(1) + "K":<Loader active inline size='mini'/>}
</span>

<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>MARKET CAP</span></strong> <span className='homefinancials'>
{this.props.theState.marketcap >= 1.0e+9 ? '$' + (this.props.theState.marketcap/1.0e+9).toFixed(1) + "B": this.props.theState.marketcap >= 1.0e+6 ? '$' + (this.props.theState.marketcap/1.0e+6).toFixed(1) + "M": this.props.theState.marketcap >= 1.0e+3 ? '$' + (this.props.theState.marketcap/1.0e+3).toFixed(1) + "K":<Loader active inline size='mini'/>}
</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>ALL TIME HIGH</span></strong> <span className='homefinancials'>
{(this.props.theState.ath < 1 ? '$' + this.props.theState.ath.toLocaleString(undefined, {maximumFractionDigits:3}): this.props.theState.ath < 10 ? '$' + this.props.theState.ath.toLocaleString(undefined, {maximumFractionDigits:2}): this.props.theState.ath < 1000 ? '$' + this.props.theState.ath.toLocaleString(undefined, {maximumFractionDigits:1}): this.props.theState.ath > 1000 ? '$' + this.props.theState.ath.toLocaleString(undefined, {maximumFractionDigits:0}):<Loader active inline size='mini'/>)}
{this.props.theState.athpercentage ? <span className='homefinancialslabelath'>{'('}{(100/(((1-(Math.abs(this.props.theState.athpercentage)/100))*100))).toFixed(1)}{'x)'}</span>: <Loader active inline size='mini'/>}
</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>ATH DATE</span></strong> <span className='homefinancials'>
{this.props.theState.athdate ? new Date(this.props.theState.athdate).toLocaleString('en-us', {month: 'short',day: 'numeric',year: 'numeric'}).split('/').join('-') :<Loader active inline size='mini'/>}
</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>SUPPLY</span><span className='homefinancialslabeldelta'> (CIRC/TOT)</span></strong> <span className='homefinancialspricechange'>
{this.props.theState.circulatingsupply >= 1.0e+9 ? (this.props.theState.circulatingsupply/1.0e+9).toFixed(1) + "B":this.props.theState.circulatingsupply>= 1.0e+6 ? (this.props.theState.circulatingsupply/1.0e+6).toFixed(1) + "M": this.props.theState.circulatingsupply >= 1.0e+3 ? (this.props.theState.circulatingsupply/1.0e+3).toFixed(1) + "K":<Loader active inline size='mini'/>}
{this.props.theState.totalsupply == null ? '/ ∞' : this.props.theState.totalsupply >= 1.0e+9 ? '/' + (this.props.theState.totalsupply/1.0e+9).toFixed(1) + "B":this.props.theState.totalsupply>= 1.0e+6 ? '/' + (this.props.theState.totalsupply/1.0e+6).toFixed(1) + "M": this.props.theState.totalsupply >= 1.0e+3 ? '/' + (this.props.theState.totalsupply/1.0e+3).toFixed(1) + "K":<Loader active inline size='mini'/>}


</span>
<br></br>

<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>INFLATION</span></strong> <span className='homefinancialspricechange'>
{this.props.coinInfo.info.financials.inflation === 5 ? 'DEFLATIONARY' : this.props.coinInfo.info.financials.inflation === 4 ? 'NONE' : this.props.coinInfo.info.financials.inflation === 3 ? '0.1-5%' :this.props.coinInfo.info.financials.inflation === 2 ? '5-10%' : this.props.coinInfo.info.financials.inflation === 1 ? '10-20%' :'>20%'}
</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>DISTRIBUTION</span></strong>    <span className='pricedeltaicon'><Popup content={'Percent of tokens owned outside founders/team.'}  trigger={
<Icon style={{marginLeft:'1px',verticalAlign: 'text-top'}} name='info circle' size='tiny' />}/></span>

<span className='homefinancials'>
{this.props.coinInfo.info.financials.distribution === 4 ? '>95%' :this.props.coinInfo.info.financials.distribution === 3 ? '80-95%' : this.props.coinInfo.info.financials.distribution === 2 ? '65-80%': this.props.coinInfo.info.financials.distribution === 1 ? '50-65%':'< 50%'}


</span>
<br></br>
<br></br>

</div>  
:''}
</Grid.Column> 


<Grid.Column width={5}>
 {this.props.coinInfo.info ? 
<div style={{paddingLeft:'0px'}}>
 <strong><span className='homefinancialslabel'>AVG ICO PRICE</span></strong> <span className='homefinancials'> {this.props.coinInfo.info.ico.usdPrice === '-'? '':'$'}{(this.props.coinInfo.info.ico.usdPrice < 1 ? this.props.coinInfo.info.ico.usdPrice.toLocaleString(undefined, {maximumFractionDigits:3}): this.props.coinInfo.info.ico.usdPrice < 10 ? this.props.coinInfo.info.ico.usdPrice.toLocaleString(undefined, {maximumFractionDigits:2}): this.props.coinInfo.info.ico.usdPrice < 1000 ? this.props.coinInfo.info.ico.usdPrice.toLocaleString(undefined, {maximumFractionDigits:1}): this.props.coinInfo.info.ico.usdPrice > 1000 ? this.props.coinInfo.info.ico.usdPrice.toLocaleString(undefined, {maximumFractionDigits:0}): this.props.coinInfo.info.ico.usdPrice === '-' ? '-': <Loader active inline size='mini'/>)}</span>

<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>ICO ROI</span></strong> <span className='homefinancials'>
{((this.props.theState.price / this.props.coinInfo.info.ico.usdPrice) < 1 ? (this.props.theState.price / this.props.coinInfo.info.ico.usdPrice).toLocaleString(undefined, {maximumFractionDigits:3}): (this.props.theState.price / this.props.coinInfo.info.ico.usdPrice) < 10 ? (this.props.theState.price / this.props.coinInfo.info.ico.usdPrice).toLocaleString(undefined, {maximumFractionDigits:2}): (this.props.theState.price / this.props.coinInfo.info.ico.usdPrice) < 1000 ? (this.props.theState.price / this.props.coinInfo.info.ico.usdPrice).toLocaleString(undefined, {maximumFractionDigits:1}): (this.props.theState.price / this.props.coinInfo.info.ico.usdPrice) > 1000 ? (this.props.theState.price / this.props.coinInfo.info.ico.usdPrice).toLocaleString(undefined, {maximumFractionDigits:0}): this.props.coinInfo.info.ico.usdPrice === '-' ? '-': <Loader active inline size='mini'/>)}
{this.props.coinInfo.info.ico.usdPrice === '-'? '':'x'}</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>ICO RAISE</span></strong> <span className='homefinancials'>
{this.props.coinInfo.info.ico.usdRaise === '-'? '':'$'}{this.props.coinInfo.info.ico.usdRaise>= 1.0e+9 ? (this.props.coinInfo.info.ico.usdRaise/1.0e+9).toFixed(1) + "B":this.props.coinInfo.info.ico.usdRaise >= 1.0e+6 ? (this.props.coinInfo.info.ico.usdRaise/1.0e+6).toFixed(1) + "M": this.props.coinInfo.info.ico.usdRaise >= 1.0e+3 ? (this.props.coinInfo.info.ico.usdRaise/1.0e+3).toFixed(1) + "K": this.props.coinInfo.info.ico.usdRaise === '-'? '-': <Loader active inline size='mini'/>}
</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>ICO JURISDICTION</span></strong> <span className='homefinancials'> 
{this.props.coinInfo.info.ico.usdPrice === '-'? '-': <Popup inverted content={this.props.coinInfo.info.ico.jurisdiction} trigger={
<Flag name={this.props.coinInfo.info.ico.jurisdiction.toLowerCase()} />}/>}

</span>


<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>ICO DATE</span></strong> <span className='homefinancials'>{this.props.coinInfo.info.ico.date}</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>SUPPLY</span><span className='homefinancialslabeldelta'> (ICO)</span></strong> <span className='homefinancials'>
{this.props.coinInfo.info.ico.supply >= 1.0e+9 ? (this.props.coinInfo.info.ico.supply/1.0e+9).toFixed(1) + "B":this.props.coinInfo.info.ico.supply>= 1.0e+6 ? (this.props.coinInfo.info.ico.supply/1.0e+6).toFixed(1) + "M": this.props.coinInfo.info.ico.supply >= 1.0e+3 ? (this.props.coinInfo.info.ico.supply/1.0e+3).toFixed(1) + "K": this.props.coinInfo.info.ico.supply === '-'? '-':<Loader active inline size='mini'/>}
</span>

<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>FUNDING?</span></strong> <span className='homefinancials'>
{this.props.coinInfo.info.financials.funding === 1 ? 'YES' :'NO'}
  </span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>SECURITY?</span></strong> <span className='homefinancials'>
{this.props.coinInfo.info.financials.security === 3 ? 'NO' :this.props.coinInfo.info.financials.security === 2 ? 'UNLIKELY' : this.props.coinInfo.info.financials.security === 1 ? 'POSSIBLE' :'YES'}
</span>



      </div>  
  : ''} 
  </Grid.Column>
</Grid>

              </Segment>
            </div>
    );
  }
}
