import { FETCH_COINS } from './coinConstants';

 const initialState = [];

const coinReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COINS:
        return action.payload.coins;
      default:
        return state;
    }
  };
  
  export default coinReducer;
  