import React, { Component } from "react";
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { Menu, Container, Icon} from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
// import SignedOutMenu from "../Menus/SignedOutMenu";
// import SignedInMenu from "../Menus/SignedInMenu";
import { openModal } from '../../modals/modalActions';

const actions = {
  openModal
}

const mapState = (state) => ({
  auth: state.firebase.auth,
  // profile: state.firebase.profile
})

class NavBar extends Component {
  handleSignIn = () => {
    this.props.openModal('LoginModal')
  };

  handleRegister = () => {
    this.props.openModal('RegisterModal')
  }

  handleSignOut = () => {
    this.props.firebase.logout();
    this.props.history.push('/')
  };
  render() {
    // const { auth, profile } = this.props;
    // const authenticated = auth.isLoaded && !auth.isEmpty;

    return (
      <Menu>
        <Container>
          <Menu.Item as={Link} to="/"  header style={{marginLeft:'10px',borderLeft: 'none',padding:'0'}}><a href="https://www.tokenflipper.com"> <img src="/assets/tflogo4.png" height='30px' alt="logo" /></a>
           
          </Menu.Item>

          <Menu.Item as={Link} to="/" header style={{borderLeft: 'none',padding:'0px 0px 0px 0px'}}><a href="https://rank.tokenflipper.com"> <img src="/assets/TFrank.png" height='30px' alt="logo" /></a>
           
           </Menu.Item>

          {/* <Menu.Item style={{borderLeft: 'none'}} as={Link} color='red' to="/" header>
          <img src="/assets/TFrank.png" height='30px' width='119px' alt="logo" />
          </Menu.Item> */}
          {/* <Menu.Item as={NavLink} to="#" name="News" /> */}
          {/* <Menu.Item as={Link} to="#" name="Blog" />
          <Menu.Menu position='right'>
          <Menu.Item as={Link} to="#" floated="right" name="Submit News" />
          </Menu.Menu> */}
       {/* <Menu.Item>
          <Button as={Link} to="/submit-news" floated="right" style={{backgroundColor:'#800080','color':'#FFFFFF'}} content="Submit News"/>
          </Menu.Item> */}
              {/*
          {authenticated ? (
            
            <Fragment>

          <SignedInMenu profile={profile} signOut={this.handleSignOut}/> </Fragment>) : (
        <SignedOutMenu signIn={this.handleSignIn} register={this.handleRegister} />)} */}
          <Menu.Menu style={{marginTop:'-9px'}} position='right'><Menu.Item floated="right" as={Link} color='red' to="/about" header>
    <Icon className='rainbow_text_animated' size='large' name='question circle outline'></Icon> 
       </Menu.Item>
       </Menu.Menu>
        </Container>
      </Menu>
    );
  }
}

export default withRouter(withFirebase(connect(mapState, actions)(NavBar)));
