import React, { Component } from 'react'

import ReactEcharts from 'echarts-for-react';


class PriceRangeGauge extends Component {

  getOption(){  
    let minPrice = (this.props.theState.price24hlow < 1 ? this.props.theState.price24hlow.toLocaleString(undefined, {maximumFractionDigits:3}): this.props.theState.price24hlow < 10 ? this.props.theState.price24hlow.toLocaleString(undefined, {maximumFractionDigits:2}): this.props.theState.price24hlow < 1000 ? this.props.theState.price24hlow.toLocaleString(undefined, {maximumFractionDigits:1}): this.props.theState.price24hlow > 1000 ? this.props.theState.price24hlow:'N/A')
    let maxPrice = (this.props.theState.price24hhigh < 1 ? this.props.theState.price24hhigh.toLocaleString(undefined, {maximumFractionDigits:3}): this.props.theState.price24hhigh < 10 ? this.props.theState.price24hhigh.toLocaleString(undefined, {maximumFractionDigits:2}): this.props.theState.price24hhigh < 1000 ? this.props.theState.price24hhigh.toLocaleString(undefined, {maximumFractionDigits:1}): this.props.theState.price24hhigh > 1000 ? this.props.theState.price24hhigh:'N/A')
    let currentPrice = this.props.theState.price && this.props.theState.price < 1 ? this.props.theState.price.toLocaleString(undefined, {maximumFractionDigits:3}): this.props.theState.price < 10 ? this.props.theState.price.toLocaleString(undefined, {maximumFractionDigits:2}): this.props.theState.price < 1000 ? this.props.theState.price.toLocaleString(undefined, {maximumFractionDigits:1}): this.props.theState.price > 1000 ? this.props.theState.price:'N/A';
    let minPriceLabel = (this.props.theState.price24hlow < 1 ? this.props.theState.price24hlow.toLocaleString(undefined, {maximumFractionDigits:3}): this.props.theState.price24hlow < 10 ? this.props.theState.price24hlow.toLocaleString(undefined, {maximumFractionDigits:2}): this.props.theState.price24hlow < 1000 ? this.props.theState.price24hlow.toLocaleString(undefined, {maximumFractionDigits:1}): this.props.theState.price24hlow > 1000 ? this.props.theState.price24hlow.toLocaleString(undefined, {maximumFractionDigits:0}):'N/A')
    let maxPriceLabel = (this.props.theState.price24hhigh < 1 ? this.props.theState.price24hhigh.toLocaleString(undefined, {maximumFractionDigits:3}): this.props.theState.price24hhigh < 10 ? this.props.theState.price24hhigh.toLocaleString(undefined, {maximumFractionDigits:2}): this.props.theState.price24hhigh < 1000 ? this.props.theState.price24hhigh.toLocaleString(undefined, {maximumFractionDigits:1}): this.props.theState.price24hhigh > 1000 ? this.props.theState.price24hhigh.toLocaleString(undefined, {maximumFractionDigits:0}):'N/A')
    let currentPriceLabel = this.props.theState.price && this.props.theState.price < 1 ? this.props.theState.price.toLocaleString(undefined, {maximumFractionDigits:3}): this.props.theState.price < 10 ? this.props.theState.price.toLocaleString(undefined, {maximumFractionDigits:2}): this.props.theState.price < 1000 ? this.props.theState.price.toLocaleString(undefined, {maximumFractionDigits:1}): this.props.theState.price > 1000 ? this.props.theState.price.toLocaleString(undefined, {maximumFractionDigits:0}):'N/A';

    const option = {
        title: {
            text: '24H Price Range',
            left: 'center',
            padding: [0,0,0,30]
        },
        tooltip: {
            show: true,
            trigger: 'item',
            formatter: "{a}:{b}"
        },
        series: [{
            type: "gauge",
            name:'Price',
            startAngle: 190,
            endAngle: -10,
            min: minPrice,
            max: maxPrice,
            radius: "90%",
            center: ["50%", "53%"],
            axisLine: {

                show: true,
                lineStyle: {
                    width: 60,
                    shadowBlur: 5,
                    color: [
                        [0, '#B5495B'],
                        [0.5, '#D0104C'],
                        [1, '#20604F']
                    ]
                }
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: true,
                padding: [35, 0, 0, 0],
                fontSize: 12,
                distance: -30,
                formatter(value) {
                    // eslint-disable-next-line eqeqeq
                    if (value == minPrice) {
                        return '$' + minPriceLabel
                    }else if (value == maxPrice) {
                        return '$'+ maxPriceLabel;
                    }
                }
            },
            splitLine: {
                show: false
            },
            pointer: {
                width: "8%",
                length: '90%',
                color: "black"
            },
            itemStyle: {
                normal: {
                    color: "rgba(255, 255, 255, 0.8)",
                    shadowBlur: 10
                }
            },

            title: {
                show: true,
                offsetCenter: [0, '82%'],
                    fontSize: 11,
                    fontWeight: '',
                    color: 'black'

                // }

            },
            detail: {
                show: true,
                offsetCenter: [0, '60%'],
                textStyle: {
                    fontSize: 20,
                    fontWeight: '600',
                    color: '#333'
                },
                formatter: function(value) {
                    console.log(value)
                    return '$' + currentPriceLabel;
                }
            },
            data:[{value:currentPrice,name:'Current Price'}]


        }]}
  
    
    return option
  }

  render() {
 
      
    return (
       <div>
           <center>
           {this.props.theState.price ? 
        <ReactEcharts
  option={this.getOption()}
  notMerge={true}
  style={{width:'200px',height:'270px'}}
  lazyUpdate={true}
  theme={"theme_name"} />
       : ''} 
       </center></div>
    )
  }
}

export default PriceRangeGauge;


