import React, { Component } from 'react'
import { Form, Divider, Dropdown } from 'semantic-ui-react';
// import SelectInput from '../../app/common/form/SelectInput';



const category = [
    { key: 'partnerships', text: 'Partnerships', value: 'Partnerships' },
    { key: 'tech', text: 'Tech', value: 'Tech' }
  ];
  
  const cost = [
    { key: 'eth', text: 'Ethereum', value: 'Ethereum' },
    { key: 'bch', text: 'Bitcoin Cash', value: 'Bitcoin Cash' }
  ]

  
export default class CoinFilters extends Component {
    state = {
        category:'',
        cost:''
      };
    
    handleDropdownChange = (e, secondthing) => {
        let theValue = e.value 
        this.setState({[secondthing]: theValue});
        this.props.filterChange(theValue, secondthing);
      }


    render() {

    return (

        <Form style={{textAlign:'center',paddingTop:'29px',paddingBottom:'20px'}}>
       
        <Dropdown style={{marginRight:'10px', marginBottom:'10px', color:'#800080', borderColor:'#800080'}}
        onChange={(e, value)=>this.handleDropdownChange(value, 'category')}
          name="category"
          clearable
          type="text"
          placeholder="Category"
          options={category}
          selection
        />
          <Dropdown style={{marginRight:'10px', marginBottom:'10px', color:'#800080', borderColor:'#800080'}}
        onChange={(e, value)=>this.handleDropdownChange(value, 'coinName')}
          name="cost"
          clearable
          type="text"
          options={cost}
          placeholder="Coin Name"
          selection
        />
          <Dropdown style={{marginRight:'10px', marginBottom:'10px', color:'#800080', borderColor:'#800080'}}
        onChange={(e, value)=>this.handleDropdownChange(value, 'coin')}
          name="cost"
          clearable
          type="text"
          options={cost}
          placeholder="Date"
          selection
        />
      <Divider hidden/>
      </Form>
    )
  }
}
