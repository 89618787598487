import React, { Component } from 'react';
import { Segment, Icon,Popup,Progress, Rating} from 'semantic-ui-react';

export default class CoinProgress extends Component {
  render() {
    let pointIcon = <Icon style={{verticalAlign: 'middle',marginTop:'-3px',marginRight:'-1px'}} name='star' color='yellow' size='small' />;
    return (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {this.props.coinInfo && this.props.coinInfo.info ? 
                  <Segment className="coincheckbox">
          <div className='ribbon'><p style={{marginLeft:'-13px'}}>Tech Progress</p></div>
          <div className='toprighttagratings'> 
                      <span className='toprighttagtextscoring' style={{borderRadius: '.28571429rem',display:'inline',marginRight:'5px',marginTop:'0px'}}>Score
                        <Popup position='bottom left' trigger={<Icon style={{marginLeft:'1px',marginRight:'-4px',verticalAlign: 'text-top'}} name='question circle outline'  size='large' />}>
                        <Popup.Header><p className='toprighttagtext'>Score Breakdown</p></Popup.Header>
    <Popup.Content>
      <span> <strong>Whitepaper</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info.tech.whitepaper} maxRating={1} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon} Whitepaper or technical document describing project.
        </em></p>
      <span> <strong>Bug Protection</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info.tech.testnet} maxRating={1} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon} Had a testnet or audit or has a bug bounty program.
        </em></p>
      <span> <strong>Mainnet</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info.tech.mainnet} maxRating={2} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon} Mainnet is live and operational.<br /> 
        </em></p>
      <span> <strong>Wallet Support</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info.tech.wallets} maxRating={2} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon} Both software and hardware wallet support. <br />  {pointIcon} Only software or hardware support.
        </em></p>
      <span> <strong>Bugs / Hacks</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info.tech.bughack} maxRating={3} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon} No bugs or hacks. <br />{pointIcon}{pointIcon} Minor bug or hack. <br />  {pointIcon} Moderate bug or hack.
        </em></p> 
      <span> <strong>Unique Tech</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info.tech.unique} maxRating={2} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon} Complete new architecture/technology. <br />  {pointIcon} Fork with considerable changes.
        </em></p>
      <span> <strong>Decentralized</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info.tech.decentralized} maxRating={2} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon} Consensus achieved with >200 independent nodes. <br />  {pointIcon} Consensus achieved with >50 independent nodes.
        </em></p> 
      <span> <strong>Secure</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info.tech.secure} maxRating={2} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon} Consensus method not under threat. <br />  {pointIcon} Possible but unlikely attack vector exists.
        </em></p>
      <span> <strong>Scaling</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info.tech.scaling} maxRating={2} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon} Currently scalable to meet usecase. <br />  {pointIcon} Scaling solutions in the near term pipeline.
        </em></p>
        <span> <strong>Fully Functional</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info.tech.functional} maxRating={3} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon} WP promises, roadmap, and necessary development for usecase is finished. <br />{pointIcon}{pointIcon} Very functional but still missing pieces. <br />  {pointIcon} Basic functionality.
        </em></p>
    </Popup.Content>
                         </Popup>
                       </span>
          
                  <span style={{display:'inline'}}>
                   <Progress indicating className='sectionscorebar' value={this.props.coinInfo.info.tech.whitepaper + this.props.coinInfo.info.tech.testnet + this.props.coinInfo.info.tech.mainnet + this.props.coinInfo.info.tech.wallets + this.props.coinInfo.info.tech.bughack + this.props.coinInfo.info.tech.unique + this.props.coinInfo.info.tech.decentralized + this.props.coinInfo.info.tech.secure + this.props.coinInfo.info.tech.scaling + this.props.coinInfo.info.tech.functional} total='20' color='purple' size='medium' progress='ratio' />
                  </span>

                  </div>
         
           
        <div className='centeredflexbox'>
        
            <Segment.Group className='coinchecksbox'>
             <Segment className='coinchecksboxtop' inverted color='teal'><center>Whitepaper</center></Segment>
            {this.props.coinInfo.info.tech.whitepaper && this.props.coinInfo.info.tech.whitepaper === 1 ? <Segment className='coinchecksboxbottom'><center><Icon color='green' name='checkmark' size='large' /></center></Segment>: this.props.coinInfo.info.tech.whitepaper === 0 ?
             <Segment className='coinchecksboxbottom'><center><Icon color='red' name='times' size='large' /></center></Segment> : <Segment className='coinchecksboxbottom'><center><Icon color='yellow' name='adjust' size='large' /></center></Segment>
            }
            </Segment.Group>

            <Segment.Group className='coinchecksbox'>
             <Segment className='coinchecksboxtop' inverted color='teal'><center>Bug Protection</center></Segment>
            {this.props.coinInfo.info.tech.testnet && this.props.coinInfo.info.tech.testnet === 1 ? <Segment className='coinchecksboxbottom'><center><Icon color='green' name='checkmark' size='large' /></center></Segment>: this.props.coinInfo.info.tech.testnet === 0 ?
             <Segment className='coinchecksboxbottom'><center><Icon color='red' name='times' size='large' /></center></Segment> : <Segment className='coinchecksboxbottom'><center><Icon color='yellow' name='adjust' size='large' /></center></Segment>
            }
            </Segment.Group>

            <Segment.Group className='coinchecksbox'>
             <Segment className='coinchecksboxtop' inverted color='teal'><center>Mainnet</center></Segment>
            {this.props.coinInfo.info.tech.mainnet && this.props.coinInfo.info.tech.mainnet === 2 ? <Segment className='coinchecksboxbottom'><center><Icon color='green' name='checkmark' size='large' /></center></Segment>: this.props.coinInfo.info.tech.mainnet === 0 ?
             <Segment className='coinchecksboxbottom'><center><Icon color='red' name='times' size='large' /></center></Segment> : <Segment className='coinchecksboxbottom'><center><Icon color='yellow' name='adjust' size='large' /></center></Segment>
            }
            </Segment.Group>
       
            <Segment.Group className='coinchecksbox'>
             <Segment className='coinchecksboxtop' inverted color='teal'><center>Wallets</center></Segment>
            {this.props.coinInfo.info.tech.wallets && this.props.coinInfo.info.tech.wallets === 2 ? <Segment className='coinchecksboxbottom'><center><Icon color='green' name='checkmark' size='large' /></center></Segment>: this.props.coinInfo.info.tech.wallets === 0 ?
             <Segment className='coinchecksboxbottom'><center><Icon color='red' name='times' size='large' /></center></Segment> : <Segment className='coinchecksboxbottom'><center><Icon color='yellow' name='adjust' size='large' /></center></Segment>
            }
            </Segment.Group>

            <Segment.Group className='coinchecksbox'>
             <Segment className='coinchecksboxtop' inverted color='teal'><center>Bug/Hack Free</center></Segment>
            {this.props.coinInfo.info.tech.bughack && this.props.coinInfo.info.tech.bughack === 3 ? <Segment className='coinchecksboxbottom'><center><Icon color='green' name='checkmark' size='large' /></center></Segment>: this.props.coinInfo.info.tech.bughack === 0 ?
             <Segment className='coinchecksboxbottom'><center><Icon color='red' name='times' size='large' /></center></Segment> : <Segment className='coinchecksboxbottom'><center><Icon color='yellow' name='adjust' size='large' /></center></Segment>
            }
            </Segment.Group>

            <Segment.Group className='coinchecksbox'>
             <Segment className='coinchecksboxtop' inverted color='teal'><center>Unique Tech</center></Segment>
            {this.props.coinInfo.info.tech.unique && this.props.coinInfo.info.tech.unique === 2 ? <Segment className='coinchecksboxbottom'><center><Icon color='green' name='checkmark' size='large' /></center></Segment>: this.props.coinInfo.info.tech.unique === 0 ?
             <Segment className='coinchecksboxbottom'><center><Icon color='red' name='times' size='large' /></center></Segment> : <Segment className='coinchecksboxbottom'><center><Icon color='yellow' name='adjust' size='large' /></center></Segment>
            }
            </Segment.Group>

            <Segment.Group className='coinchecksbox'>
             <Segment className='coinchecksboxtop' inverted color='teal'><center>Decentralized</center></Segment>
            {this.props.coinInfo.info.tech.decentralized && this.props.coinInfo.info.tech.decentralized === 2 ? <Segment className='coinchecksboxbottom'><center><Icon color='green' name='checkmark' size='large' /></center></Segment>: this.props.coinInfo.info.tech.decentralized === 0 ?
             <Segment className='coinchecksboxbottom'><center><Icon color='red' name='times' size='large' /></center></Segment> : <Segment className='coinchecksboxbottom'><center><Icon color='yellow' name='adjust' size='large' /></center></Segment>
            }
            </Segment.Group>

            <Segment.Group className='coinchecksbox'>
             <Segment className='coinchecksboxtop' inverted color='teal'><center>Secure</center></Segment>
            {this.props.coinInfo.info.tech.secure && this.props.coinInfo.info.tech.secure === 2 ? <Segment className='coinchecksboxbottom'><center><Icon color='green' name='checkmark' size='large' /></center></Segment>: this.props.coinInfo.info.tech.secure === 0 ?
             <Segment className='coinchecksboxbottom'><center><Icon color='red' name='times' size='large' /></center></Segment> : <Segment className='coinchecksboxbottom'><center><Icon color='yellow' name='adjust' size='large' /></center></Segment>
            }
            </Segment.Group>

            <Segment.Group className='coinchecksbox'>
             <Segment className='coinchecksboxtop' inverted color='teal'><center>Scaling</center></Segment>
            {this.props.coinInfo.info.tech.scaling && this.props.coinInfo.info.tech.scaling === 2 ? <Segment className='coinchecksboxbottom'><center><Icon color='green' name='checkmark' size='large' /></center></Segment>: this.props.coinInfo.info.tech.scaling === 0 ?
             <Segment className='coinchecksboxbottom'><center><Icon color='red' name='times' size='large' /></center></Segment> : <Segment className='coinchecksboxbottom'><center><Icon color='yellow' name='adjust' size='large' /></center></Segment>
            }
            </Segment.Group>

            <Segment.Group className='coinchecksbox'>
             <Segment className='coinchecksboxtop' inverted color='teal'><center>Fully Functional</center></Segment>
            {this.props.coinInfo.info.tech.functional && this.props.coinInfo.info.tech.functional === 3 ? <Segment className='coinchecksboxbottom'><center><Icon color='green' name='checkmark' size='large' /></center></Segment>: this.props.coinInfo.info.tech.functional === 0 ?
             <Segment className='coinchecksboxbottom'><center><Icon color='red' name='times' size='large' /></center></Segment> : <Segment className='coinchecksboxbottom'><center><Icon color='yellow' name='adjust' size='large' /></center></Segment>
            }
            </Segment.Group>
      
    </div>
    </Segment>
      :''}
    </div>
    );
  }
}
