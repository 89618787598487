import React from 'react'
import {Container, Loader, Popup, } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import CoinHexagon from './CoinHexagon';
import CoinFilters from './CoinFilters';
import ScrollUpButton from '../../app/layout/ScrollUpButton'

const CoinListTable = (props) => {
  let dbData = props?.dbData;
  let apiData = props?.apiData;
    return (
        <Container>
<div className='table'>
    <div className='tablerheaderouter'>
        <div className='tableheaderinner'>
            <div className='tableheader'>
              
                <div className='tablecolumn-rank'><Popup inverted position='top center' trigger={<span style={{color:'#fff'}}>TFs™ #</span>}><Popup.Content>The project's overall TFscore™ from combining the Adoption, Team, Social, Financials, Tech, and Market scores.</Popup.Content></Popup></div>
                <div className='tablecolumn-rank'><Popup inverted position='top center' trigger={<span style={{color:'#fff'}}>MC #</span>}><Popup.Content>The project's market cap rank.</Popup.Content></Popup></div>
                <div className='tablecolumn-name'><span></span></div>
                <div className='tablecolumn-brief'><Popup inverted position='top center' trigger={<span>Brief</span>}><Popup.Content>The project described in 5 words or less.</Popup.Content></Popup></div>
                <div className='tablecolumn-category'><Popup inverted position='top center' trigger={<span>TFs™</span>}><Popup.Content>The total TFscore™ taking into account Adoption, Financials, Social, Tech, Team and Market scores.</Popup.Content></Popup></div>
                <div className='tablecolumn-marketcap'><Popup inverted position='top center' trigger={<span>MC</span>}><Popup.Content>The market capitalization based on circulating supply, resulting in the overall valuation of the project.</Popup.Content></Popup></div>
                <div className='tablecolumn-price'><Popup inverted position='top center' trigger={<span>Price</span>}><Popup.Content>The current market price of the project for a single coin or token.</Popup.Content></Popup></div>
                <div className='tablecolumn-volume'><Popup inverted position='top center' trigger={<span>Vol</span>}><Popup.Content>The 24 hour trading volume on exchanges.</Popup.Content></Popup></div>
                <div className='tablecolumn-type'><Popup inverted position='top center' trigger={<span className='scoreheader'>Adoption</span>}><Popup.Content>The Adoption score calculated based on partnerships/integrations with entities in the real world.</Popup.Content></Popup></div>
                <div className='tablecolumn-type'><Popup inverted position='top center' trigger={<span className='scoreheader'>Financials</span>}><Popup.Content>The Financials score calculated by looking at things like token inflation, distribution, liquidity.</Popup.Content></Popup></div>
                <div className='tablecolumn-type'><Popup inverted position='top center' trigger={<span className='scoreheader'>Social</span>}><Popup.Content>The Social score calculated by looking at things like the project's reputation, community activity, press coverage. </Popup.Content></Popup></div>
                <div className='tablecolumn-type'><Popup inverted position='top center' trigger={<span className='scoreheader'>Tech</span>}><Popup.Content>The Tech score calculated by looking at things like the project's progress on core features and its scaling, decentralization, and security. </Popup.Content></Popup></div>
                <div className='tablecolumn-type'><Popup inverted position='top center' trigger={<span className='scoreheader'>Team</span>}><Popup.Content>The Social score calculated by looking at things like the project's leader, industry backing, and dev ecosystem. </Popup.Content></Popup></div>
                <div className='tablecolumn-type'><Popup inverted position='top center' trigger={<span className='scoreheader'>Market</span>}><Popup.Content>The Market score calculated by looking at things like the project's market position, utility, and scope. </Popup.Content></Popup></div>
                {/* <div className='tablecolumn-type'><span>Category</span></div> */}
               
            </div>
        </div>
    </div>
    {props?.dbData?.map((coin, index) => {
       let theCoin = apiData.filter(function(data) {return data.coinSymbol === coin.coinSymbol});
        return(
            <a key={index} href={`/coin/${coin.coinSymbol.toLowerCase()}`} className='tablerow'>
                <div className='tablerowinner'>
                    <div className='tablecolumn tablecolumn-rank'><p className='tsrankbox'>{coin.rank}</p></div>
                    <div className='tablecolumn tablecolumn-rank'>{theCoin[0] ? theCoin[0].marketcaprank: ''}</div>
                    <div className='tablecolumn tablecolumn-name'>
                      <div>
                        <img className='tablecolumn-name-image' alt="coinlogo" src={`/assets/logos/${coin.coinSymbol.toLowerCase()}.png`}/>
                        <span className='tablecolumn-name-title'>{theCoin[0]?.coinName}</span>
                      </div>
                    </div>
                    <div className='tablecolumn tablecolumn-brief'><p style={{fontSize:'10px'}}>{coin.brief}</p></div>
                    <div className='tablecolumn tablecolumn-category'><span className={(coin.score.total) > 70 ? 'homescorecirclecontainer-green-total' : (coin.score.total) > 40 ? 'homescorecirclecontainer-yellow-total' :'homescorecirclecontainer-red-total'}><p className='homescorecircle'>{(coin.score.total).toLocaleString(undefined, {maximumFractionDigits:1})}%</p></span></div>
                    <div className='tablecolumn tablecolumn-marketcap'>{theCoin[0].marketcap >= 1.0e+11 ?  `$${(theCoin[0].marketcap/1.0e+9).toFixed(0)}B`:theCoin[0].marketcap >= 1.0e+9 ?  `$${(theCoin[0].marketcap/1.0e+9).toFixed(1)}B`: theCoin[0].marketcap >= 1.0e+6 ? '$' + (theCoin[0].marketcap/1.0e+6).toFixed(0) + "M": theCoin[0].marketcap >= 1.0e+3 ? '$' + (theCoin[0].marketcap/1.0e+3).toFixed(1) + "K":<Loader active inline size='mini'/>}</div>
                    <div className='tablecolumn tablecolumn-price'> ${(theCoin[0].price < 1 ? theCoin[0].price.toLocaleString(undefined, {maximumFractionDigits:3}): theCoin[0].price < 10 ? theCoin[0].price.toLocaleString(undefined, {maximumFractionDigits:2}): theCoin[0].price < 1000 ? theCoin[0].price.toLocaleString(undefined, {maximumFractionDigits:1}): theCoin[0].price > 1000 ? theCoin[0].price.toLocaleString(undefined, {maximumFractionDigits:0}):'N/A')}</div>
                    <div className='tablecolumn tablecolumn-volume'>${theCoin[0].volume >= 1.0e+9 ? (theCoin[0].volume/1.0e+9).toFixed(1) + "B":theCoin[0].volume >= 1.0e+6 ? (theCoin[0].volume/1.0e+6).toFixed(0) + "M": theCoin[0].volume >= 1.0e+3 ? (theCoin[0].volume/1.0e+3).toFixed(1) + "K":'N/A'}</div>
                    <div className='tablecolumn tablecolumn-type'><span className='neumocircle' ><p className={(coin.score.adoption / 20 * 100) > 70 ? 'homescore-green' : (coin.score.adoption / 20 * 100) > 40 ? 'homescore-yellow' :'homescore-red'}>{(coin.score.adoption / 20 * 100).toLocaleString(undefined, {maximumFractionDigits:0})}</p></span></div>
                    <div className='tablecolumn tablecolumn-type'><span className='neumocircle'><p className={(coin.score.financials / 20 * 100) > 70 ? 'homescore-green' : (coin.score.financials / 20 * 100) > 40 ? 'homescore-yellow' :'homescore-red'}>{(coin.score.financials / 20 * 100).toLocaleString(undefined, {maximumFractionDigits:0})}</p></span></div>
                    <div className='tablecolumn tablecolumn-type'><span className='neumocircle'><p className={(coin.score.social / 15 * 100) > 70 ? 'homescore-green' : (coin.score.social / 15 * 100) > 40 ? 'homescore-yellow' :'homescore-red'}>{(coin.score.social / 15 * 100).toLocaleString(undefined, {maximumFractionDigits:0})}</p></span></div>
                    <div className='tablecolumn tablecolumn-type'><span className='neumocircle'><p className={(coin.score.tech / 20 * 100) > 70 ? 'homescore-green' : (coin.score.tech / 20 * 100) > 40 ? 'homescore-yellow' :'homescore-red'}>{(coin.score.tech / 20 * 100).toLocaleString(undefined, {maximumFractionDigits:0})}</p></span></div>
                    <div className='tablecolumn tablecolumn-type'><span className='neumocircle'><p className={(coin.score.team / 15 * 100) > 70 ? 'homescore-green' : (coin.score.team / 15 * 100) > 40 ? 'homescore-yellow' :'homescore-red'}>{(coin.score.team / 15 * 100).toLocaleString(undefined, {maximumFractionDigits:0})}</p></span></div>
                    <div className='tablecolumn tablecolumn-type'><span className='neumocircle'><p className={(coin.score.market / 10 * 100) > 70 ? 'homescore-green' : (coin.score.market / 10 * 100) > 40 ? 'homescore-yellow' :'homescore-red'}>{(coin.score.market / 10 * 100).toLocaleString(undefined, {maximumFractionDigits:0})}</p></span></div>
                    {/* <div className='tablecolumn tablecolumn-type'>{coin.type}</div> */}
                </div>
            </a>
    

        )
      }
        )
     }     
      </div>     
       </Container>
    )
}

export default CoinListTable