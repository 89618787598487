import React, { Component } from 'react'

import ReactEcharts from 'echarts-for-react';


class SocialPie extends Component {

  getOption(){  
    let twitter = this.props.theState.twitterfans || 0;
    let reddit = this.props.theState.redditsub || 0;
    let github = this.props.theState.githubstars || 0;
    let facebook = this.props.theState.facebookfans || 0;
    let telegram = this.props.theState.telegramfans || 0;


    let data = [
        {
            value: telegram,
            name: 'Telegram'
        },
        {
            value: facebook,
            name: 'Facebook'
        },
        {
            value: twitter,
            name: 'Twitter'
        },
        {
            value: reddit,
            name: 'Reddit'
        },
        {
            value: github,
            name: 'Github'
        }
    
     
 
    ];
    let color = [ '#ececec','#4867AA','#1DA1F2','#FF4500','black' ];
    let data1 = (data[0].value + data[1].value + data[2].value + data[3].value + data[4].value).toLocaleString();
    let baseData = [];
    for (var i = 0; i < data.length; i++) {
        baseData.push({
            value: data[i].value,
            name: data[i].name,
            itemStyle: {
                normal: {
                    borderWidth: 22,
                    shadowBlur: 10,
                    borderColor: color[i],
                    shadowColor: 'rgba(0, 0, 0, 0.8)'
                }
            }
        });
    }
    

    const option = {
        title: {
            text: 'Social Reach',
            left: 'center',
            padding: [0,0,0,30]
        },
        backgroundColor: '#fff',
        color: color,
        tooltip: {
            show:true,
            trigger: 'item',
            formatter: "{b}：{d}%"
        },
        series: [{
                zlevel: 1,
                name: 'Followers',
                type: 'pie',
                selectedMode: 'single',
                radius: ['70%', '80%'],
                startAngle: '135',
                hoverAnimation: false,
                label: {
                    normal: {
                        show: false,
                        formatter: ['{c|{c}}', '{b|{b}}'].join('\n'),
                        rich: {
                            b: {
                                fontSize: 16,
                                lineHeight: 30
                            }
    
                        }
                    }
                },
                itemStyle: {
                    normal: {
                        shadowColor: 'rgba(0, 0, 0, 0.8)',
                        shadowBlur: 50,
                    }
                },
                data: baseData
            },
            {
                name: 'Followers',
                type: 'pie',
                selectedMode: 'single',
                radius: [190, 210],
                startAngle: '135',
                data: [{
                    "value": data1,
                    "name": "",
                    "label": {
                        "normal": {
                            "show": true,
                            "formatter": '{c|  {c}} \n{a|Followers}',
                            rich: {
                                c: {
                                    align: 'center',
                                    color: 'black',
                                    fontSize: 20,
                                    fontWeight: 'bold',
                                    lineHeight: 5
                                },
                                a: {
                                    align: 'center',
                                    color: 'black',
                                    fontSize: 15,
                                    height: 20
                                },
                            },
                            "textStyle": {
                                "fontSize": 28,
                                "fontWeight": "bold"
                            },
                            "position": "center"
    
                        }
                    }
                }, ]
            }
        ]
    };
  
    
    return option
  }

  render() {
 
      
    return (
       <div>
           <center>
           {this.props.theState ? 
        <ReactEcharts
  option={this.getOption()}
  notMerge={true}
  style={{width:'200px',height:'230px'}}
  lazyUpdate={true}
  theme={"theme_name"} />
       : ''} 
       </center></div>
    )
  }
}

export default SocialPie;


