import { combineReducers } from 'redux'
import { reducer as FormReducer } from 'redux-form';
import {reducer as toastrReducer} from 'react-redux-toastr';
import modalReducer from '../../features/modals/modalReducer';
import authReducer from '../../features/auth/authReducer';
import asyncReducer from '../../features/async/asyncReducer';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import newsReducer from '../../features/newsCreator/newsReducer';
import coinReducer from '../../features/coinList/coinReducer';

const rootReducer = combineReducers({
    firebase:firebaseReducer,
    firestore:firestoreReducer,
    auth: authReducer,
    form: FormReducer,
    async: asyncReducer,
    modals: modalReducer,
    toastr: toastrReducer,
    news: newsReducer,
    coins: coinReducer
})

export default rootReducer;