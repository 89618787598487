import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Segment, Rating, Button, Grid, Loader} from 'semantic-ui-react'
import { firestoreConnect } from 'react-redux-firebase';
import { openModal } from '../modals/modalActions'
import { addToFavorites, removeFromFavorites } from '../../app/actions/favoritedActions'
import { getNewsForDashboard } from '../newsCreator/newsActions'
// import LoadingComponent from '../../app/layout/LoadingComponent';
import InfiniteScroll from 'react-infinite-scroller';
import NewsFilters from './NewsFilters';

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  dbItems: state.firestore.data.news,
  // dbItems: state.firestore.ordered.tactics,
  news: state.news,
  loading: state.async.loading
})

const actions = {
  openModal, addToFavorites, removeFromFavorites, getNewsForDashboard
}

class NewsList extends Component {

  state = {
    moreTactics: false,
    loadingInitial: true,
    loadedTactics:[],
    category:'',
    cost:'',
    coinName:''
  }

  async componentDidMount() {
      let next = await this.props.getNewsForDashboard();
      if (next && next.docs && next.docs.length > 0) {
        this.setState({
          moreTactics: true,
          loadingInitial: false
        })
      }
 
  }

  componentWillReceiveProps(nextProps) {
    console.log('nextprops', nextProps)
    if (this.props.news !== nextProps.news) {
      this.setState({
        loadedTactics: [...this.state.loadedTactics, ...nextProps.news]
      })
    }
  }

  getNextTactics = async () => {
    const {news} = this.props;
    let lastTactic = news && news[news.length -1];
    console.log('lasttactic',lastTactic)

    let next = await this.props.getNewsForDashboard(lastTactic, this.state.category, this.state.coinName);

    if (next && next.docs && next.docs.length <= 0) {
      this.setState({
        moreTactics: false
      })
    }
  }



  handleRating = (item) => {
    if(this.props.auth.isLoaded && !this.props.auth.isEmpty){
        if(this.props.dbItems[item.id].favoritedUID.includes(this.props.auth.uid)) {
            this.props.removeFromFavorites(item);
         
        } else {

         this.props.addToFavorites(item);
        }

    } else {
        alert('Must be logged in')
    } 
 }

  filterChange = async (theValue, secondthing) => {
    this.setState({
      loadedTactics: []
    })
    this.setState({[secondthing]: theValue},() => this.props.getNewsForDashboard(null, this.state.category, this.state.coinName));
    this.setState({
      moreTactics: true
    })
  }
    
  render() {
    const {loading} = this.props;
    // if (this.state.loadingInitial) return <LoadingComponent inverted/>
    const {dbItems, auth} = this.props; 
    const authenticated = auth.isLoaded && !auth.isEmpty;
    const {loadedTactics} = this.state;
    
    return (
      <React.Fragment>
        {loadedTactics && loadedTactics !== 0 &&
       
        <InfiniteScroll
        pageStart={0}
        loadMore={this.getNextTactics}
        hasMore={!loading && this.state.moreTactics}
        initialLoad={false}
        >
      <Grid centered>
        <NewsFilters filterChange={this.filterChange}/>
        </Grid>
        
           <div style={{marginTop: "0em" ,display:'flex',flexWrap:'wrap',alignItems: 'stretch'}}>
        {this.state.loadedTactics && this.state.loadedTactics.map((item, index) => {
          let activeRating = '';
          if(dbItems[item.id].favoritedUID) {
          if(dbItems[item.id].favoritedUID.includes(this.props.auth.uid)) {activeRating = 1}else{activeRating = 0}};

          let properDate = item.newsDate.toDate().toLocaleString('us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          });
          return(
            <div key={index} className='columnbox'>
            <Segment attached='top' basic style={{padding:'0px', margin: '0px','borderWidth':'0px'}}>
            <div className='imagebox' style={{ backgroundImage: `url(${item.info.coinPic}),linear-gradient(#C6426E, #642B73)`}}>
        
            <div className='topleft'>{item.category}</div>
            <div className='bottomright'>{item.time && <span style={{marginLeft:'1.5px'}}>~</span>}{item.time}{item.time && <span style={{marginLeft:'1.5px'}}>h</span>}
            {/* <Icon name='clock outline' /> */}
            </div>
            <div className='topright'>
            
             {properDate}
            
            
            </div>
            <div className='bottomleft'>
            {' '}
            {item.authorName}
            {/* <img style={{marginBottom:'-7px',marginLeft:'0.2em',width:'2em',borderRadius:'500rem'}} alt='profilepic' src={item.authorPic}/> */}
            </div>
          
            
            <div className='textbox'><span className='textboxtitle'> {item.coinName}</span>
            <h3 className='textboxsub'> {item.subtitle}</h3>
            </div>
        </div>
        </Segment>
        <Segment style={{zIndex:'-1'}} attached>
        <center>
        <div style={{fontSize:'1.4em', lineHeight:'1.3',fontWeight:'500'}}>{item.headline}</div>
        </center>
        </Segment>
        <Segment attached='bottom' style={{backgroundColor:'#d0d0d0', borderWidth:'0px'}}>
        <span style={{fontSize:'23px'}}>{dbItems[item.id].favoritedUID && dbItems[item.id].favoritedUID.length > 0 ? dbItems[item.id].favoritedUID.length : ''}</span> {' '}
        {authenticated ? (
        <Rating onRate={()=> this.handleRating(item)} style={{ "marginTop": "-10px" }} icon='heart' size='huge' rating={activeRating} maxRating={1} /> 
        ):(<Rating onClick={()=> alert('Must be logged in')} disabled style={{ "marginTop": "-10px" }} icon='heart' size='huge' rating={activeRating} maxRating={1} /> )
        }
        

        <Grid >
          <Grid.Row centered style={{margin:'0px', padding:'4px'}}>
            <Button onClick={() => this.props.openModal("StrategyDetailModal", { item })} size="small" style={{ backgroundColor:'#800080',color: "#FFFFFF", marginTop: "-20px" }}>
              Details
            </Button>
          </Grid.Row>{" "}
        </Grid>
        </Segment>
        </div>
        
        )}
        )}
        
        </div>
        </InfiniteScroll>
         
        }
        <Grid.Column><Loader active={this.props.loading} /></Grid.Column>
       </React.Fragment>
        
    )
  }
}

export default connect(mapStateToProps, actions)(firestoreConnect([{collection:'news'}])(NewsList));

