import React, { Component } from 'react';
import { Icon, Divider,Segment,Progress, Popup} from 'semantic-ui-react';


class CoinAdoption extends Component {

  
  render() {
    let pointIcon = <Icon style={{verticalAlign: 'middle',marginTop:'0px',marginRight:'-1px'}} name='star' color='yellow' size='small' />;
    let businessPartnerships = this.props.coinInfo.businessPartnerships || [];

    // let partnersArray = [];
    // businessPartnerships.map((partnership,index) => {
    //   let points = partnership.strength * partnership.size;
    //   return (
    //     partnersArray.push(points)
    //   )
    // })
    // let arrSum = arr => arr.reduce((a,b) => a + b, 0);
    // let adoptionPointsTotal = arrSum(partnersArray);
    // let adoptionPoints = 0;
    // if(adoptionPointsTotal >20){
    //    adoptionPoints = 20;
    // }else{
    //  adoptionPoints = adoptionPointsTotal;
    // }

    let sortedBusinessPartnerships =   businessPartnerships.sort(function(a,b){
        var c = new Date(a.date);
        var d = new Date(b.date);
        return d-c;
        });

    // let blockchainPartnerships = this.props.coinInfo.blockchainPartnerships || [];
    // let sortedBlockchainPartnerships =   blockchainPartnerships.sort(function(a,b){
    //   var c = new Date(a.date);
    //   var d = new Date(b.date);
    //   return d-c;
    //   });

    return (
          
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Segment className="coincheckbox">
          <div className='ribbon'><p style={{marginLeft:'-13px'}}>Adoption</p></div>
          {this.props.coinInfo && this.props.coinInfo.info ? 
                  <div className='toprighttagratings'> 
                 
                      <span className='toprighttagtextscoring' style={{borderRadius: '.28571429rem',display:'inline',marginRight:'5px',marginTop:'0px'}}>Score
                        <Popup position='bottom left' trigger={<Icon style={{zIndex:10,marginLeft:'1px',marginRight:'-4px',verticalAlign: 'text-top'}} name='question circle outline'  size='large' />}>
                        <Popup.Header><p className='toprighttagtext'>Score Breakdown</p></Popup.Header>
    <Popup.Content>
        Max 20 points awarded for real-world adoption / integration / usage / partnership. Each adoption receives from 1 to 9 pts based on size of the entity adopting (1x/2x/3x multiplier based on small/medium/large entity) and the strength of the adoption (1x/2x/3x multiplier based on weak/medium/strong adoption). So a weak adoption by a big entity would net 3 points (1x * 3x).
    </Popup.Content>
                         </Popup>
                       </span>
          
                  <span style={{display:'inline'}}>
                   <Progress indicating className='sectionscorebar' value={this.props.coinInfo.info.adoption} total={20} color='purple' size='medium' progress='ratio' />
                  </span>
                 
                  </div>
                  : ''}
   
      
{/* Business partnerships (top half) */}
          <div className='partnershipscontainer' style={{marginLeft:'20px',marginTop:'-35px',marginBottom:'50px'}}>  
            {/* <div className="partnershiplogosheader">
                <center><p style={{fontWeight:'600',fontSize:'15px',paddingTop: '30px'}}>Business / Government</p></center>
            </div> */}

            {this.props.coinInfo && sortedBusinessPartnerships.length > 0 ?
            sortedBusinessPartnerships.map((partnership,index) => (
              <div key={index} className="partnershiplogos">
              <img className='squarelogo' src={`/assets/${partnership.name}logo.png`} alt="logo" />
              <div className='partnershipinfo'>
                  <p className='partnershipinfodate'>{partnership.date}</p>
                  <Divider style={{ marginBottom: '-15px' }} hidden />
                   <div style={{display:'inline'}}>
                 <span style={{float:'left'}}><span style={{padding: '1px 2px 1px 1px',fontSize:'5px',color: 'white', backgroundColor: '#800080'}}>Size  {partnership.size === 3 ? <span>{pointIcon}{pointIcon}{pointIcon}</span>: partnership.size === 2 ? <span>{pointIcon}{pointIcon}</span>: partnership.size === 1 ? <span>{pointIcon}</span> :''}</span></span>
                 <span style={{marginRight:'10px',float:'right'}}><span style={{padding: '1px 2px 1px 1px',fontSize:'5px',color: 'white', backgroundColor: '#800080'}}>Strength  {partnership.strength === 3 ? <span>{pointIcon}{pointIcon}{pointIcon}</span>: partnership.strength === 2 ? <span>{pointIcon}{pointIcon}</span>: partnership.strength === 1 ? <span>{pointIcon}</span> :''}</span></span>
                 </div>
                 <Divider style={{ marginBottom: '-1px' }} hidden />
                  <p style={{fontSize:'6px',marginRight:'10px'}}> {partnership.info}
                 <a href={partnership.link} target="_blank" rel='noopener noreferrer'><Icon style={{color:'grey',marginLeft:'2px'}} name='external' /></a></p>  
                 
                 
             </div>
         
         </div>
            
           )
             ): 
             <div className="partnershiplogos">
             <img className='squarelogo' src={`/assets/Nonelogo.png`} alt="logo" /></div>}



          </div>
        
{/* Blockchain partnerships (bottom half) */}
        {/* <div className='partnershipscontainer' style={{marginLeft:'20px',marginTop:'-50px',marginBottom:'50px'}}> 
         <div className="partnershiplogosheader">
                <center><p style={{verticalAlign:'middle',fontWeight:'600',fontSize:'15px',paddingTop: '38px'}}>Blockchain</p></center>
        </div>

        {this.props.coin && sortedBlockchainPartnerships.map((partnership,index) => (
              <div className="partnershiplogos">
              <img className='squarelogo' src={`/assets/${partnership.name}logo.png`} alt="logo" />
              <div className='partnershipinfo'>
                  <p className='partnershipinfodate'>{partnership.date}</p>
                   <Divider style={{ marginBottom: '-10px' }} hidden />
                  <p> {partnership.info}
                 <a href={partnership.link} target="_blank" rel='noopener noreferrer'><Icon style={{color:'grey',marginLeft:'2px'}} name='external' size='normal' /></a></p>                     <Divider style={{ marginBottom: '-10px' }} hidden />
             </div>
         </div>
            
           )
             )}
        </div> */}

        </Segment>
        </div> 
  
                
    )
  }
}

export default CoinAdoption;