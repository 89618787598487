import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';
import 'firebase/firestore';
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyBmMMSXg0b-X1TggTo2XbpCK9cx_RtnHz8",
    authDomain: "coinrank-87906.firebaseapp.com",
    databaseURL: "https://coinrank-87906.firebaseio.com",
    projectId: "coinrank-87906",
    storageBucket: "coinrank-87906.appspot.com",
    appId: "1:230872110369:web:67ea2a262a43ccd1e25a60",
    measurementId: "G-LQR2367BG9",
    messagingSenderId: "230872110369"
}

firebase.initializeApp(firebaseConfig);

firebase.firestore();

firebase.analytics();
firebase.performance();

export default firebase;
