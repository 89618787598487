import React from 'react'
import { connect } from 'react-redux'
import StrategyDetailModal from './StrategyDetailModal'
import StrategyDetailModalPreview from './StrategyDetailModalPreview'
import LoginModal from './LoginModal'
import RegisterModal from './RegisterModal'

const modalLookup = {
    StrategyDetailModal,
    StrategyDetailModalPreview,
    LoginModal,
    RegisterModal
}

const mapState = (state) =>({
    currentModal: state.modals
})

const ModalManager = ({currentModal}) => {
    let renderedModal;

    if (currentModal) {
        const {modalType, modalProps} = currentModal;
        const ModalComponent = modalLookup[modalType];

        renderedModal = <ModalComponent {...modalProps} />
    }

  return <span>{renderedModal}</span>
}

export default connect(mapState)(ModalManager)
