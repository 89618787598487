import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { withFirestore } from 'react-redux-firebase';
import {
  Rating,
  Segment,
  Form,
  Button,
  Grid,
  Header,
  Divider,
  Container
} from 'semantic-ui-react';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan
} from 'revalidate';
import { createNews, updateNews } from './newsActions';
import TextInput from '../../app/common/form/TextInput';
// import TextArea from '../../app/common/form/TextArea';
import SelectInput from '../../app/common/form/SelectInput';
import { openModal } from '../modals/modalActions';
// import cuid from 'cuid';
// import DateInput from '../../app/common/form/DateInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const mapState = (state, ownProps) => {
  let tactic = {};

  if (state.firestore.ordered.tactics && state.firestore.ordered.tactics[0]) {
    tactic = state.firestore.ordered.tactics[0];
  }

  return {
    initialValues: tactic,
    tactic,
    displayName: state.firebase.auth.displayName
  };
};

const actions = {
  createNews,
  updateNews,
  openModal
};

const category = [
  { key: 'partnerships', text: 'Partnerships', value: 'Partnerships' },
  { key: 'dapps', text: 'Dapps', value: 'Dapps' },
  { key: 'tech', text: 'Tech Breakthrough', value: 'Tech' },
  { key: 'adoption', text: 'Adoption', value: 'Adoption' },
  { key: 'redflags', text: 'Red Flags', value: 'Red Flags' },
  { key: 'investment', text: 'Investment Metrics', value: 'Investment' }
];

const coinSymbol = [
  { key: 'eth', text: 'Ethereum', value: 'eth' },
  { key: 'bch', text: 'Bitcoin Cash', value: 'bch' },
  { key: 'btc', text: 'Bitcoin', value: 'btc' },
  { key: 'eos', text: 'EOS', value: 'eos' },
  { key: 'ltc', text: 'Litecoin', value: 'ltc' },
  { key: 'trx', text: 'Tron', value: 'trx' },
  { key: 'man', text: 'Matrix AI', value: 'man' },
  { key: 'neo', text: 'Neo', value: 'neo' },
  { key: 'icx', text: 'Icon', value: 'icx' }
];

let coinTranslate = {
  eth: 'Ethereum',
  bch: 'Bitcoin Cash',
  neo: 'Neo',
  man: 'Matrix AI',
  btc: 'Bitcoin',
  ltc: 'Litecoin',
  eos: 'EOS',
  trx: 'Tron',
  icx: 'Icon'
};
const validate = combineValidators({
  asfjaf: isRequired({ message: 'The tactic title is required' }),
  category: isRequired({ message: 'Please provide a category' }),
  headline: composeValidators(
    isRequired({ message: 'Please enter a description' }),
    hasLengthGreaterThan(9)({
      message: 'Description needs to be at least 10 characters'
    })
  )(),
  date: isRequired('date')
});

class TacticCreator extends Component {
  state = {
    coinName: '',
    headline: '',
    coinPic: '',
    category: '',
    newsDate: '',
    sourceURL: ''
  };

  async componentDidMount() {
    const { firestore, match } = this.props;
    await firestore.setListener(`tactics/${match.params.id}`);
  }

  async componentWillUnmount() {
    const { firestore, match } = this.props;
    await firestore.unsetListener(`tactics/${match.params.id}`);
  }

  handleTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleDateChange = e => {
    this.setState({
      newsDate: e
    });
  };

  handleDropdownChange = (e, secondthing) => {
    this.setState({ [secondthing]: e });
    if (secondthing === 'coinSymbol') {
      this.setState({
        coinPic: `https://www.livecoinwatch.com/images/icons64/${e}.png`
      });
      this.setState({
        coinName: coinTranslate[e]
      });
    }
  };

  onFormSubmit = (values, state) => {
    // values.venueLatLng = this.state.venueLatLng;
    // if (this.props.initialValues.id) {
    //   if (Object.keys(values.venueLatLng).length === 0) {
    //     values.venueLatLng = this.props.event.venueLatLng;
    //   }
    //   this.props.updateTactic(values);
    //   this.props.history.goBack();
    // } else {
      this.props.createNews(values, state);
      this.props.history.push('/news');
    // }
  };

  render() {
    const { invalid, submitting, pristine } = this.props;
    return (
      <Container className="main">
      <Grid stackable>
        <Grid.Column width={10}>
          <Segment>
            <Header
              style={{ fontSize: '16px', marginBottom: '10px' }}
              sub
              color="teal"
              content="News Details"
            />

            <Form
              autoComplete="off"
              onSubmit={this.props.handleSubmit(values => {
                this.onFormSubmit(values, this.state);
              })}
            >
              <Field
                onChange={this.handleTextChange}
                name="headline"
                type="text"
                component={TextInput}
                placeholder="Your Headline In One Sentence"
              />
              <Field
                onChange={(e, value) =>
                  this.handleDropdownChange(value, 'coinSymbol')
                }
                name="coinSymbol"
                type="text"
                component={SelectInput}
                options={coinSymbol}
                placeholder="What is the symbol of the coin"
                selection
              />
              <Field
                onChange={(e, value) =>
                  this.handleDropdownChange(value, 'category')
                }
                name="category"
                type="text"
                component={SelectInput}
                options={category}
                placeholder="What is the news category"
                selection
              />
               <Field
                onChange={this.handleTextChange}
                name="sourceURL"
                type="text"
                component={TextInput}
                placeholder="The URL of your source"
              />
              <DatePicker
                placeholderText="Date of the news"
                selected={this.state.newsDate}
                onChange={this.handleDateChange}
                dateFormat="YYYY-MM-dd"
              />

              <Divider hidden />
              <Button
                disabled={invalid || submitting || pristine}
                positive
                type="submit"
              >
                Submit News
              </Button>
              <Button onClick={this.props.history.goBack} type="button">
                Cancel
              </Button>
            </Form>
          </Segment>
        </Grid.Column>

        <Grid.Column width={6}>
          <div className="columnboxpreview">
            <Header
              style={{
                fontSize: '22px',
                marginTop: '5px',
                marginBottom: '5px'
              }}
              sub
              color="teal"
              content="News Preview"
            />
            <Segment
              attached="top"
              basic
              style={{ padding: '0px', margin: '0px', borderWidth: '0px' }}
            >
              <div
                className="imagebox"
                style={{
                  backgroundImage: `linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) ), url(${
                    this.state.coinPic
                  })`
                }}
              >
                <div className="topleft">{this.state.category}</div>
                <div className="topright">
                  {this.state.newsDate.toLocaleString('us', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}
                </div>
                <div className="textbox">
                  <span className="textboxtitle"> {this.state.coinName}</span>
                </div>
              </div>
            </Segment>
            <Segment style={{}} attached>
              <center>
                <div
                  style={{
                    fontSize: '1.4em',
                    lineHeight: '1.3',
                    fontWeight: '500'
                  }}
                >
                  {this.state.headline}
                </div>
              </center>
            </Segment>
            <Segment
              attached="bottom"
              style={{ backgroundColor: '#d0d0d0', borderWidth: '0px' }}
            >
              <Rating
                style={{ marginTop: '-10px' }}
                icon="heart"
                size="huge"
                defaultRating={0}
                maxRating={1}
              />

              <Grid>
                <Grid.Row centered style={{ margin: '0px', padding: '4px' }}>
                  <Button
                    onClick={() =>
                      this.props.openModal(
                        'StrategyDetailModalPreview',
                        this.state
                      )
                    }
                    size="small"
                    style={{
                      backgroundColor: '#800080',
                      color: '#FFFFFF',
                      marginTop: '-20px'
                    }}
                  >
                    Details
                  </Button>
                </Grid.Row>{' '}
              </Grid>
            </Segment>
          </div>
          <div style={{ paddingTop: '8px', color: 'grey' }}>
            <center>
              <em>Click the Details button to see more of the preview..</em>
            </center>
          </div>
        </Grid.Column>
      </Grid>
      </Container>
    );
  }
}

export default withFirestore(
  connect(
    mapState,
    actions
  )(
    reduxForm({ form: 'tacticForm', enableReinitialize: true, validate })(
      TacticCreator
    )
  )
);
