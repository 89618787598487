import React, { Component } from 'react';
import { Segment,Container,Divider, Icon, Rating} from 'semantic-ui-react';


export default class About extends Component {

  render() {
    let pointIcon = <Icon style={{verticalAlign: 'middle',marginTop:'-3px',marginRight:'-1px'}} name='star' color='yellow' size='small' />;
    let pointIconBig = <Icon style={{verticalAlign: 'middle',marginTop:'-7px',marginLeft:'-2px',marginRight:'-1px'}} name='star' color='yellow' />;
    return (
<Container  className="main">
      <div className='aboutcombo'>
  
    <div className="aboutbox">
<div className='aboutheader'><center>WHAT?</center></div>
<div className='aboutboxinner'>
<div className='aboutline'>100% transparent crypto ratings that measures things that matter</div>
<Divider style={{ marginTop: '5px', marginRight: '51px',marginLeft: '36px' }} />
<div className='aboutline'>All important data curated into one easily digestable page </div>
<Divider style={{ marginTop: '5px',marginRight: '51px',marginLeft: '36px' }}  />
<div className='aboutline'>Unique analysis tools like viewing how different milestones affect price </div>
</div>
</div>

<Divider style={{ marginRight:'15px',marginTop: '15px' }} hidden />

<div className="aboutbox">
<div className='aboutheader'><center>WHY?</center></div>
<div className='aboutboxinner'>
<div className='aboutline'>Easily differentiate the quality cryptos from the suspicious ones</div>
<Divider style={{ marginTop: '5px',marginRight: '51px',marginLeft: '36px' }}  />
<div className='aboutline'>Quickly get caught up with what a project's about and been up to </div>
<Divider style={{marginTop: '5px',marginRight: '51px',marginLeft: '36px' }}  />
<div className='aboutline'>Discover the opportunity and threat factors facing each project </div>
</div>
</div>


</div>

<Divider style={{ marginTop: '15px' }} hidden />
<div className="aboutbox">
<div className='aboutheader'><center>HOW?</center></div>
<div className='aboutboxinner'>
<div className='aboutline'>Each coin / token gets a score out of <span className='aboutlabelteal'>100 {pointIconBig}</span> based on six categories:
<Divider hidden style={{marginTop: '0px'}}  />

 <span style={{lineHeight:'35px'}}><span className='aboutlabelpurple'>TEAM</span> , <span className='aboutlabelpurple'>SOCIAL STANDING</span> , <span className='aboutlabelpurple'>FINANCIALS</span> , <span className='aboutlabelpurple'>TECH PROGRESS</span> , <span className='aboutlabelpurple'>MARKET</span> and <span className='aboutlabelpurple'>ADOPTION</span>.</span></div>
<Divider style={{ marginTop: '0px', marginRight: '51px',marginLeft: '36px' }} />
<div className='aboutline'><span className='aboutlabelpurple'>TEAM</span> max score is <span className='aboutlabelteal'>15 {pointIconBig}</span>. Based on:<br></br><br></br>


<span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Leader (The Face)</span> <span className='aboutlabelteal'>Max 5 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
      <p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon}{pointIcon}{pointIcon} Legendary leader that is widely respected in general tech/biz space.<br />{pointIcon}{pointIcon}{pointIcon}{pointIcon} Rockstar leader that is widely respected in blockchain space.<br />{pointIcon}{pointIcon}{pointIcon} Leader with proven talent or effectiveness in current position. <br />{pointIcon}{pointIcon}  Leader with proven talent or effectiveness in previous position(s). <br />  {pointIcon} Respectable leader but still unproven.
        </em></p>

        <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Dev Ecosystem</span> <span className='aboutlabelteal'>Max 5 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
      <p style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon}{pointIcon}{pointIcon} Abundant full-time developers as well as thriving developer ecosystem.<br />{pointIcon}{pointIcon}{pointIcon}{pointIcon} Decent full-time developers and decent developer ecosystem.<br />{pointIcon}{pointIcon}{pointIcon} Decent full-time developers or decent developer ecosystem.<br />{pointIcon}{pointIcon} Some full-time developers and some developer ecosystem.<br />  {pointIcon} Some full-time developers or some developer ecosystem.
        </em></p>
        <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Dev Activity</span> <span className='aboutlabelteal'>Max 1 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

      <p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
       {pointIcon} Existent dev activity on project's Github repository.
        </em></p>
        <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Industry Backing</span> <span className='aboutlabelteal'>Max 3 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
 
      <p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon}  Strong backing from known/influential people in the industry. <br />{pointIcon}{pointIcon} Moderate backing from people with experience in industry. <br />  {pointIcon} Some support from entities with more general connections.
        </em></p>
        <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Foundation</span> <span className='aboutlabelteal'>Max 1 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
      <p  style={{marginBottom:'0px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
     {pointIcon} Some foundation, board, or entity that assures a certain level of transparency.
        </em></p>   

</div>
<Divider style={{ marginTop: '5px',marginRight: '51px',marginLeft: '36px' }}  />


<div className='aboutline'><span className='aboutlabelpurple'>SOCIAL STANDING</span> max score is <span className='aboutlabelteal'>15 {pointIconBig}</span>. Based on:<br></br><br></br>


<span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Project Maturity</span> <span className='aboutlabelteal'>Max 3 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon}{pointIcon} Launched &lt;2016. <br />{pointIcon}{pointIcon} Launched &lt;2018.<br />  {pointIcon} Launched &lt;2019.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Cult Following</span> <span className='aboutlabelteal'>Max 1 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon} Has a passionate loyal core support base.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Launch Fairness</span> <span className='aboutlabelteal'>Max 2 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon} No pre-mine or ICO. <br />  {pointIcon} Scandal-free fair launch but with disclosed ICO/pre-mine. 
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Reputation</span> <span className='aboutlabelteal'>Max 3 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon}{pointIcon} Acquired community trust through proven integrity and transparency. <br />{pointIcon}{pointIcon} Clean reputation with no messups/scandals.<br />  {pointIcon} Neutral reputation - too early to tell.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Community Activity</span> <span className='aboutlabelteal'>Max 3 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
Based on hidden algorithm to prevent exploitation/manipulation.
  </em></p> 
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Press Coverage</span> <span className='aboutlabelteal'>Max 3 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

<p  style={{marginBottom:'0px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon}{pointIcon} Received coverage by mainstream news outlet. <br />{pointIcon}{pointIcon} Received coverage by crypto news outlet. <br />  {pointIcon} Received coverage by established crypto blog or YouTuber.
  </em></p>

</div>
<Divider style={{ marginTop: '5px',marginRight: '51px',marginLeft: '36px' }}  />

<div className='aboutline'><span className='aboutlabelpurple'>FINANCIALS</span> max score is <span className='aboutlabelteal'>20 {pointIconBig}</span>. Based on:<br></br><br></br>
<span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Inflation</span> <span className='aboutlabelteal'>Max 5 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon}{pointIcon}{pointIcon}{pointIcon} Deflationary.<br />{pointIcon}{pointIcon}{pointIcon}{pointIcon} No inflation.<br />{pointIcon}{pointIcon}{pointIcon} 0.1-5% inflation. <br />{pointIcon}{pointIcon} 5-10% inflation.<br />  {pointIcon} 10-20% inflation.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Liquidity</span> <span className='aboutlabelteal'>Max 5 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon}{pointIcon}{pointIcon}{pointIcon} > 1B Volume.<br />{pointIcon}{pointIcon}{pointIcon}{pointIcon} 100M - 1B Volume.<br />{pointIcon}{pointIcon}{pointIcon} 10M - 100M Volume. <br />{pointIcon}{pointIcon} 1M - 10M Volume.<br />  {pointIcon} 100K - 1M Volume.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Listings</span> <span className='aboutlabelteal'>Max 2 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon} On > 3 top tier exchange.<br />  {pointIcon} On 1 top tier exchange.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Funding</span> <span className='aboutlabelteal'>Max 1 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon} Has a dedicated source of funding for operations - like a profitable business with customers, ICO warchest, or sustainable source of donations. 
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Token Distribution</span> <span className='aboutlabelteal'>Max 4 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon}{pointIcon}{pointIcon} > 95% of tokens outside founder/team control. <br />{pointIcon}{pointIcon}{pointIcon} 80-95% of tokens outside founders/team control. <br />{pointIcon}{pointIcon} 65-80% of tokens outside founders/team control. <br />  {pointIcon} 50-65% of tokens outside founders/team control.
  </em></p> 
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Is It A Security?</span> <span className='aboutlabelteal'>Max 3 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />

<p  style={{marginBottom:'0px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon}{pointIcon} Cleared by SEC officially or unofficially. <br />{pointIcon}{pointIcon} Small chance. <br />  {pointIcon} Moderate chance.
  </em></p>     
  </div>
  <Divider style={{ marginTop: '5px',marginRight: '51px',marginLeft: '36px' }}  />

<div className='aboutline'><span className='aboutlabelpurple'>TECH PROGRESS</span> max score is <span className='aboutlabelteal'>20 {pointIconBig}</span>. Based on:<br></br><br></br>
<span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Whitepaper</span> <span className='aboutlabelteal'>Max 1 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon} Whitepaper or technical document describing project.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Bug Protection</span> <span className='aboutlabelteal'>Max 1 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon} Had a testnet or audit or has a bug bounty program.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Mainnet</span> <span className='aboutlabelteal'>Max 2 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon} Mainnet is live and operational.<br /> 
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Wallet Support</span> <span className='aboutlabelteal'>Max 2 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon} Both software and hardware wallet support. <br />  {pointIcon} Only software or hardware support.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Bug / Hacks</span> <span className='aboutlabelteal'>Max 3 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon}{pointIcon} No bugs or hacks. <br />{pointIcon}{pointIcon} Minor bug or hack. <br />  {pointIcon} Moderate bug or hack.
  </em></p> 
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Unique Tech</span> <span className='aboutlabelteal'>Max 2 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon} Complete new architecture/technology. <br />  {pointIcon} Fork with considerable changes.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Decentralized</span> <span className='aboutlabelteal'>Max 2 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon} Consensus achieved with > 200 independent nodes. <br />  {pointIcon} Consensus achieved with > 50 independent nodes.
  </em></p> 
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Secure</span> <span className='aboutlabelteal'>Max 2 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon} Consensus method not under threat. <br />  {pointIcon} Possible but unlikely attack vector exists.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Scaling</span> <span className='aboutlabelteal'>Max 2 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon} Currently scalable to meet usecase. <br />  {pointIcon} Scaling solutions in the near term pipeline.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Fully Functional</span> <span className='aboutlabelteal'>Max 3 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'0px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon}{pointIcon} WP promises, roadmap, and necessary development for usecase is finished. <br />{pointIcon}{pointIcon} Very functional but still missing pieces. <br />  {pointIcon} Basic functionality.
  </em></p>

  </div>
  <Divider style={{ marginTop: '5px',marginRight: '51px',marginLeft: '36px' }}  />

<div className='aboutline'><span className='aboutlabelpurple'>MARKET</span> max score is <span className='aboutlabelteal'>10 {pointIconBig}</span>. Based on:<br></br><br></br>

<span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Sector Position</span> <span className='aboutlabelteal'>Max 3 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon}{pointIcon} Clear leader in their sector. <br />{pointIcon}{pointIcon} Considered among the top in the sector.<br />  {pointIcon} Up and comer in the sector with potential.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Project Scope</span> <span className='aboutlabelteal'>Max 2 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon} A blockchain platform that others can build on top of or a cryptocurrency targeting global widespread usage. <br /> {pointIcon} A blockchain agnostic middleware / protocol.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Token Utility</span> <span className='aboutlabelteal'>Max 2 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon}{pointIcon}Token has clear utility and is designed to appreciate proportionally to growth in usage/adoption.<br />{pointIcon} Token has utility but might not appreciate in-line with the network usage/adoption.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Blockchain / Crypto Necessity</span> <span className='aboutlabelteal'>Max 1 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon} The use of blockchain or crypto technology is essential for the desired usecase.
  </em></p>
  <span style={{marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Staking?</span> <span className='aboutlabelteal'>Max 1 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'20px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon} There is some form of staking, proof-of-stake reward, or passive income generating ability.
  </em></p> 
  <span style={{marginBottom:'20px',marginLeft:'10px',marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Interoperability</span> <span className='aboutlabelteal'>Max 1 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'0px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
{pointIcon} Has interoperability with other chains.
  </em></p> 



</div>
  <Divider style={{ marginTop: '5px',marginRight: '51px',marginLeft: '36px' }}  />


  
<div className='aboutline'><span className='aboutlabelpurple'>ADOPTION</span> max score is <span className='aboutlabelteal'>20 {pointIconBig}</span>. Based on:<br></br><br></br>

  <span style={{marginBottom:'20px',marginLeft:'10px',marginLeft:'10px',marginRight:'3px'}} className='aboutlabelmaroon'>Per Adoption</span> <span className='aboutlabelteal'>Max 9 {pointIconBig}</span><Divider hidden style={{marginTop: '-8px'}}  />
<p  style={{marginBottom:'0px',marginLeft:'10px',lineHeight:'1.2',fontSize:'12px'}}><em>
Calculated by multiplying:<br /> Size of adopting entity ({pointIcon} = small entity,{pointIcon}{pointIcon} = medium size entity, {pointIcon}{pointIcon}{pointIcon} = large entity)<br /> x<br />Strength of adoption ({pointIcon} = weak integration, {pointIcon}{pointIcon} = medium integration, {pointIcon}{pointIcon}{pointIcon} = strong integration).
  </em></p> 



</div>
  <Divider style={{ marginTop: '5px',marginRight: '51px',marginLeft: '36px' }}  />


<div className='aboutline'>The algorithm is open to change based on changes in industry trends and new data sources coming to light. Feel free to reach out if you have suggestions.</div>
</div>
</div>
<Divider hidden style={{ marginTop: '5px'}}  />
</Container>
      // <Container  className="main">
      // <div style={{ justifyContent: 'center' }}>
      //   <Segment>
      //     <h2>What?</h2>
      //  <p>rank.TokenFlipper.com aims provide an in-depth, transparent, and digestible crypto analysis platform aimed at both newcomers into the crypto space as well as industry veterans looking to quickly get up to speed with the latest happenings and background behind different altcoin projects.</p>
      //  <h2>Why?</h2>
      //  <p> Our goal is to condense all the relevant information into easy to understand bits whilst simultaneously using that information as data points for a scoring system which allows projects to be compared to one an other in an objective and meritocratic way.</p>
      //   <p>Of course whether we like it or not there is always going to be an element of subjectivity and imperfection in the scoring algorithm, especially considering that crypto projects unlike stocks don’t have nearly the same amount of data. For that reason we’re always open to improving the algorithm and we welcome community feedback on our Telegram group available here.</p>
      //   <p>Ultimately, we hope this serves as a tool to onboard more people into the exciting world of cryptocurrencies and decentralized software.</p>

      //   </Segment>
      //   </div>
      //   </Container>
    )
  }
}
