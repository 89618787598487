import { CREATE_EVENT, DELETE_EVENT, UPDATE_EVENT, FETCH_TACTICS } from './newsConstants';

 const initialState = [];

const newsReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_EVENT:
      return [...state, Object.assign({}, action.payload.event)]
      case UPDATE_EVENT:
      return [
        ...state.filter(event => event.id !== action.payload.event.id),
        Object.assign({}, action.payload.event)
      ]
      case DELETE_EVENT:
        return [...state.filter(event => event.id !== action.payload.eventId)];
      case FETCH_TACTICS:
        return action.payload.news;
      default:
        return state;
    }
  };
  
  export default newsReducer;
  