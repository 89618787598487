import React, { Component } from 'react';
import { Grid,Segment, Icon,Popup, Progress,Rating } from 'semantic-ui-react';
import SocialPie from './SocialPie';




export default class CoinSocial extends Component {

  render() {
    const {twitterfans,facebookfans,telegramfans,redditsub,redditactive,githubstars,alexarank} = this.props.theState;
    let pointIcon = <Icon style={{verticalAlign: 'middle',marginTop:'-3px',marginRight:'-1px'}} name='star' color='yellow' size='small' />;
  
    return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Segment className="coincheckbox">
                <div className='ribbon'><p style={{marginLeft:'-16px'}}>Social Standing</p></div>
                {this.props.coinInfo && this.props.coinInfo.info ? 
                  <div className='toprighttagratings'> 
                 
                      <span className='toprighttagtextscoring' style={{borderRadius: '.28571429rem',display:'inline',marginRight:'5px',marginTop:'0px'}}>Score
                        <Popup position='bottom left' trigger={<Icon style={{marginLeft:'1px',marginRight:'-4px',verticalAlign: 'text-top'}} name='question circle outline' size='large' />}>
                        <Popup.Header><p className='toprighttagtext'>Score Breakdown</p></Popup.Header>
    <Popup.Content>
      <span> <strong>Project Maturity</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.social.age} maxRating={3} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon} Launched &lt;2016. <br />{pointIcon}{pointIcon} Launched &lt;2018.<br />  {pointIcon} Launched &lt;2019.
        </em></p>
      <span> <strong>Cult Following</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.social.cult} maxRating={1} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
     {pointIcon} Has a passionate loyal core support base.
        </em></p>
      <span> <strong>Launch Fairness</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.social.launch} maxRating={2} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon} No pre-mine or ICO. <br />  {pointIcon} Scandal-free fair launch but with disclosed ICO/pre-mine. 
        </em></p>
      <span> <strong>Reputation</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.social.reputation} maxRating={3} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon} Acquired community trust through proven integrity and transparency. <br />{pointIcon}{pointIcon} Clean reputation with no messups/scandals.<br />  {pointIcon} Neutral reputation - too early to tell.
        </em></p>
      <span> <strong>Community Activity</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.social.activity} maxRating={3} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      Based on hidden algorithm to prevent exploitation/manipulation.
        </em></p> 
      <span> <strong>Press Coverage</strong> <Rating icon='star' defaultRating={this.props.coinInfo.info && this.props.coinInfo.info.social.coverage} maxRating={3} /></span>
      <br></br>
      <p  style={{lineHeight:'1.2',fontSize:'12px'}}><em>
      {pointIcon}{pointIcon}{pointIcon} Received coverage by mainstream news outlet. <br />{pointIcon}{pointIcon} Received coverage by crypto news outlet. <br />  {pointIcon} Received coverage by established crypto blog or YouTuber.
        </em></p>         
    </Popup.Content>
                         </Popup>
                       </span>
          
                  <span style={{display:'inline'}}>
                   <Progress indicating className='sectionscorebar' value={this.props.coinInfo.info.social.age + this.props.coinInfo.info.social.cult + this.props.coinInfo.info.social.launch + this.props.coinInfo.info.social.reputation + this.props.coinInfo.info.social.activity + this.props.coinInfo.info.social.coverage} total={15} color='purple' size='medium' progress='ratio' />
                  </span>
                 
                  </div>
                  : ''}
        
                  <Grid columns={3} centered stackable>

<Grid.Column width={5}>


<SocialPie theState={this.props.theState}/>



</Grid.Column>



<Grid.Column width={5}>

{this.props.coinInfo.info ?

<div style={{paddingLeft:'0px'}}>

<Popup inverted content="Facebook Fans" trigger={
<span style={{float:'left'}}><a href={this.props.coinInfo.info.facebookURL} target="_blank" rel='noopener noreferrer'><Icon style={{color:'#4867AA'}} name='facebook' size='large' /></a></span>}/>
<span className='coinpriceboxfigureblack'>{facebookfans ? facebookfans.toLocaleString() : '-'}</span>

<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<Popup inverted content="Twitter Followers" trigger={
 <span style={{float:'left'}}><a href={this.props.coinInfo.info.twitterURL} target="_blank" rel='noopener noreferrer'><Icon style={{color:'#1DA1F2'}} name='twitter' size='large' /></a></span>}/>
<span className='coinpriceboxfigureblack'>{twitterfans ? twitterfans.toLocaleString() : '-'}</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>


<Popup inverted content="Reddit Subscribers (Active)" trigger={
<span style={{float:'left'}}><a href={this.props.coinInfo.info.redditURL} target="_blank" rel='noopener noreferrer'><Icon style={{color:'#FF4500'}} name='reddit' size='large' /></a></span>}/>
<span style={{marginLeft:'3px'}} className='coinpriceboxfigureblack'>{redditactive ? '(' : ''}{redditactive ? redditactive.toLocaleString(): '-'}{redditactive ? ')' : ''}</span><span className='coinpriceboxfigureblack'>{redditsub ? redditsub.toLocaleString() : '-'}</span>
<br></br>
 <hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>



<Popup inverted content="Telegram Members" trigger={
<span style={{float:'left'}}><a href={this.props.coinInfo.info.telegramURL} target="_blank" rel='noopener noreferrer'><Icon style={{color:'#30A8DC'}} name='telegram' size='large' /></a></span>}/>
<span className='coinpriceboxfigureblack'>{telegramfans ? telegramfans.toLocaleString() : '-'}</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>


<Popup inverted content="Github Stars" trigger={
<span style={{float:'left'}}><a href={this.props.coinInfo.info.githubURL} target="_blank" rel='noopener noreferrer'><Icon name='github' color='black' size='large' /></a></span>}/>
<span className='coinpriceboxfigureblack'>{githubstars ? githubstars.toLocaleString() : '-'}</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>

<Popup inverted content="Alexa Website Rank" trigger={
<span style={{float:'left'}}><a href={this.props.coinInfo.info.websiteURL} target="_blank" rel='noopener noreferrer'><Icon name='globe' color='grey' size='large' /></a></span>}/>
<span className='coinpriceboxfigureblack'>{alexarank ? alexarank.toLocaleString() : '-'}</span>


</div>
: ''}
</Grid.Column>


<Grid.Column width={5}>
 {this.props.coinInfo.info ? 
<div style={{paddingLeft:'0px'}}>

<strong><span style={{fontSize:'12px'}} className='homefinancialslabel'>PROJECT MATURITY</span></strong> <span className='homefinancials'>{this.props.coinInfo.info && ((new Date() - new Date(this.props.coinInfo.info.social.agetext))/(86400000*365)).toFixed(1).toString() + ' YEARS'}</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>

<strong><span className='homefinancialslabel'>CULT FOLLOWING</span></strong> <span className='homefinancials'>{this.props.coinInfo.info.social.cult === 1 ? 'YES' : 'NO'}</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>

<strong><span className='homefinancialslabel'>FAIR LAUNCH</span></strong> <span className='homefinancials'>
{this.props.coinInfo.info.social.launch === 2 ? 'YES' : this.props.coinInfo.info.social.launch === 1 ? 'OK' :'NO'}
</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>

<strong><span className='homefinancialslabel'>REPUTATION</span></strong> <span className='homefinancials'> 
{this.props.coinInfo.info.social.reputation === 3 ? 'HIGH' : this.props.coinInfo.info.social.reputation === 2 ? 'CLEAN' :this.props.coinInfo.info.social.reputation === 1 ? 'NEUTRAL' :'SKETCHY'}
</span>


<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>ACTIVITY</span></strong> <span className='homefinancials'>
{this.props.coinInfo.info.social.activity === 3 ? 'HIGH' : this.props.coinInfo.info.social.activity === 2 ? 'MEDIUM' :this.props.coinInfo.info.social.activity === 1 ? 'LOW' :'DEAD'}
</span>
<br></br>
<hr style={{backgroundColor: '#00B5AD',height:'3px',border:'0 none'}}></hr>
<strong><span className='homefinancialslabel'>PRESS</span></strong> <span className='homefinancialspricechange'> 
{this.props.coinInfo.info.social.coverage === 3 ? 'MAINSTREAM' : this.props.coinInfo.info.social.coverage === 2 ? 'CRYPTO NEWS' :this.props.coinInfo.info.social.coverage === 1 ? 'BLOGS / YOUTUBE' :'NONE'}
</span>

</div>  
  : ''} 
  </Grid.Column>
</Grid>

              </Segment>
            </div>
    );
  }
}
