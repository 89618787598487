import React, {useState, useEffect} from 'react'
import firebase from '../../app/config/firebase'
import axios from 'axios';
import {Container, Button, Segment, Icon} from 'semantic-ui-react'
import CoinListTable from './CoinListTable';
import CoinListCard from './CoinListCard';
import GlobalData from './GlobalData';
import CoinFilters from './CoinFilters';
import ScrollUpButton from '../../app/layout/ScrollUpButton';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {isMobileOnly, isMobile,isTablet} from "react-device-detect";

const CoinList = () => {
    const [dbData, setDbData] = useState(false);
    const [apiData, setApiData] = useState(false);
    const [view, setView] = useState(false);
    const [listButtonStyle,setListButtonStyle] = useState('neumo');
    const [cardButtonStyle, setCardButtonStyle] = useState('neumo');

    useEffect(() => {
        const firestore = firebase.firestore();
        if(isMobileOnly) {
          setListButtonStyle('neumobile');
        setCardButtonStyle('neumobileClicked');
      } else if (isTablet) {
        setListButtonStyle('neumobileClicked');
        setCardButtonStyle('neumobile');
      }else{
        setListButtonStyle('neumoClicked');
        setCardButtonStyle('neumo');
      }
        
        // const getDbData = () => {
        //     firestore.collection('homepage').doc('coinlist').get()
        // .then((snapshot) => {
        //   setDbData(snapshot.data());
        // });
        //   }

        const getDbData = async () => {
          const snapshot = await (await firestore.collection('homepage').doc('coinlist').get()).data();
          let formattedData = Object.keys(snapshot).map(key => {
            let data = snapshot[key];
            data.coinSymbol = key;
            return  data;
        })
        let sortedFormattedData = formattedData.sort((a, b) => (a.score.total > b.score.total) ? -1 : 1);
        let sortedFormattedDataWithRank = sortedFormattedData.map((coin,id) => {
          return { ...coin, rank: id + 1 }
        })
        setDbData(sortedFormattedDataWithRank);
      };

        // const getGlobalData = async () => {
        //   const res = await axios.get(
        //     `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=1h%2C24h%2C7d`
        //   );
        // }

        const getApiData = async () => {
            const res = await axios.get(
              `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=1h%2C24h%2C7d`
            );
            try {
        
          let coinsToFetch = ['btc','eth','trx','link','usdt','bsv','xrp','bch','ltc','eos','bnb','xlm','ada','xmr','xtz'];
        
          let accumulatedData = [];
        
          coinsToFetch.map((coin) => {
            let theName = coin.toUpperCase();
            let coinDataFiltered = res.data.filter(obj => {return obj.symbol === coin})[0];
            let theData = {
              coinSymbol: theName,
              coinName: coinDataFiltered.name,
              marketcap: coinDataFiltered.market_cap,
              marketcaprank:coinDataFiltered.market_cap_rank,
              volume: coinDataFiltered.total_volume,
              price: coinDataFiltered.current_price,
              pricechange24h: coinDataFiltered.price_change_percentage_24h_in_currency,
              pricechange1h: coinDataFiltered.price_change_percentage_1h_in_currency,
              pricechange7d: coinDataFiltered.price_change_percentage_7d_in_currency,
              circulatingsupply: coinDataFiltered.circulating_supply,
              totalsupply: coinDataFiltered.total_supply,
              ath: coinDataFiltered.ath,
              athdate: coinDataFiltered.ath_date,
              athpercentage: coinDataFiltered.ath_change_percentage,
                        }
        
             return(
                 accumulatedData.push(theData)
              )
              });
              let sortedAccumulatedData = accumulatedData.sort((a, b) => (a.marketcaprank > b.marketcaprank) ? 1 : -1);
              setApiData(
              sortedAccumulatedData
              );
            } catch (error) {
              console.log(error);
            }
          }

        getApiData();
        getDbData().then(()=>{
            if(isMobileOnly) {
                setView('card');
            }else {
                setView('table');
            }
            
         
        })
   
    }, []);

    return (
      <React.Fragment>
        <div className='homehero'>
          <center><p className='herotagline'>In-depth token analysis. Simplified.</p></center>
      <center>
      <div style={{display: 'flex',justifyContent:'center',marginTop:'30px'}}>

        <span onClick={() => {
          setView('table');

          if(!isMobile){
          setCardButtonStyle('neumo');
          setListButtonStyle('neumoClicked');
        } else if(isTablet){
          setCardButtonStyle('neumobile');
          setListButtonStyle('neumobileClicked');
        }
        
        else{
          setCardButtonStyle('neumobile')
          setListButtonStyle('neumobileClicked');
        }
        }
        } 
        style={{marginLeft:'-20px',display:'flex',alignItems:'center',justifyContent:'center'}} className={listButtonStyle}><p style={{fontFamily:'Montserrat',fontSize:'20px',fontWeight:500,color:'white'}}>List View</p></span>
        
        <span style={{marginLeft:'10px', marginRight:'20px'}}></span>

        <span onClick={() => {
          setView('card');
          if(!isMobile){
          setCardButtonStyle('neumoClicked');
          setListButtonStyle('neumo');
        } else if(isTablet) {
          setCardButtonStyle('neumobileClicked');
          setListButtonStyle('neumobile');
        }
        
        else{
          setCardButtonStyle('neumobileClicked')
          setListButtonStyle('neumobile');
        }
        }
        } 
          style={{display:'flex',alignItems:'center',justifyContent:'center'}}className={cardButtonStyle}><p style={{fontFamily:'Montserrat',fontSize:'20px',fontWeight:500,color:'white'}}>Card View</p></span>

    {/* <Button onClick={() => setView('table')} size='large' icon labelPosition='left'>
      <Icon name='list' />
      List View
    </Button>
    <span style={{marginLeft:'10px', marginRight:'10px'}}></span>
    <Button onClick={() => setView('card')} size='large' icon labelPosition='right'>
      Card View
      <Icon name='columns' />
    </Button> */}
  </div>
  </center>
        {/* <GlobalData /> */}

            {/* <CoinFilters/> */}
        </div>
{view === 'table' && apiData && dbData? <CoinListTable apiData={apiData} dbData={dbData} />: view === 'card' ? <CoinListCard apiData={apiData} dbData={dbData}/> :       
<Container>
<div className='coinlistgroup'>
<div style={{ marginBottom:'20px',justifyContent: 'center', width:'100%'}}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Segment className="coindetailbox" style={{ height:'740px',justifyContent: 'center !important' }}>
        {/* <Loader style={{verticalAlign:'center'}} size='massive' active inline='centered' /> */}

        <SkeletonTheme color="#00B5AD" highlightColor="#7fdad6">
  <p>
    <Skeleton count={1} height={50} />

    
  </p>
</SkeletonTheme>
<Skeleton count={17}height={36}/>
           </Segment> 
           </div>
            </div>
</div>
</Container>
}

      </React.Fragment>
    )
}

export default CoinList