import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './styles/index.scss'
import 'semantic-ui-css/semantic.min.css'
import ReduxToastr from 'react-redux-toastr'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import App from './app/layout/App';
import { configureStore } from './app/store/configureStore';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './app/layout/ScrollToTop';

const store = configureStore();

WebFont.load({
  google: {
    families: ['Roboto Slab', 'Courgette','Merriweather','Archivo Black','Montserrat','Dancing Script']
  }
});

const rootEl = document.getElementById('root');

let render = () => {
  ReactDOM.render(
    <Provider store={store}><BrowserRouter><div><ReduxToastr position='bottom-right' transitionIn='fadeIn' transitionOut='fadeOut'/><ScrollToTop><App /></ScrollToTop></div></BrowserRouter></Provider>,rootEl)
}

if (module.hot) {
  module.hot.accept('./app/layout/App.jsx', () => {
    setTimeout(render)
  })
}

store.firebaseAuthIsReady.then(()=> {
  render();
})



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
