import React, { Component } from 'react';
import NewsList from './NewsList'

class NewsBrowser extends Component {

  render() {

    return (
        
    <div className='wrapper'>
    
     <NewsList/>
    </div>
    );
  }
}

export default NewsBrowser;
